
#visitacao{
    .container-vicitacao{
        max-width: 783px;
        .section-title{
            margin-top: 96px;
            h2{   
                color:  #1E1A34;
                font-family: Antonio;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 62.4px */
                letter-spacing: 0.96px;
                text-transform: uppercase;
                margin-bottom: 2rem;
            }
            .sub-title{
                color:  #5E5C66;
                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 32px */
                margin-bottom: 25px;
            }
            
        }
        .badge-for-ornamento .badge-wrapper{
            display: flex;
            height: 32px;
            transform: rotate(-2deg);
            padding: 26px 8px;
            align-items: center;
            gap: 8px;
            background:  #F2A900;
            margin-bottom: 18px;
            .badge{
                color:  #1E1A34;
                font-family: Antonio;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 26px */
                text-transform: uppercase;
            }
        }

        .margin-top-24 {
            margin-top: 1.5rem;
        }
        
        .conteudo-text{
            display: grid;
            gap: 32px;
            margin-bottom: 24px;
            p{
                color:  #1E1A34;
                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 32px */
            }

            .negrito{
                font-weight:700;
            }
        }
        .button{
    
            @include BotaoMain(#FFF);
            
            border-radius: 24px;
            border: 1px solid  #1E1A34;
            padding: 12px 24px;
            box-shadow: -3px 3px 0px 0px #E60043;
        }
    
        .contact{
            margin-top: 85px;
            margin-bottom: 85px;

            h3{
                color:  #1E1A34;
                font-family: Antonio;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 41.6px */
                text-transform: uppercase;
            }

            .contact-container{
                border-radius: 3px;
                border: 1px solid  rgba(30, 26, 52, 0.10);
                background:  #FFF;
                padding: 16px;

                h4{
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 144.444% */
                    letter-spacing: -0.36px;
                    margin-bottom: 8px;
                }
                

                
                .info-wraper{
                    display: flex;
                    gap: 4px;
                    margin-top: 6px;
                    a{
                        color:  #36363B;

                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 144.444% */
                        letter-spacing: -0.36px;
                    }
                }
            }
            .contac-1{
                margin: 16px 0 16px;
                
            }
            .contac-2{
                margin-bottom: 40px;
                
            }

            .visite-mobile{
                width: 100%;
            }
                
        }
    }


    
}

@media(max-width:750px){
    #visitacao{
        .container-vicitacao{
            
            .section-title{
                h2{   
                    font-size: 36px;
                    margin:  64px 0 12px;
                }
                .sub-title{
                    font-size: 16px;
                    margin-bottom: 25px;
                }
                
            }
            
            .conteudo-text{
                p{
                    //font-size: 1rem;
                    letter-spacing: -0.4px;
                }
            }
     
            .contact{
                margin-top: 85px;
                h3{
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px; /* 144.444% */
                    letter-spacing: -0.36px;
                    text-transform: uppercase;
                }
    
                .contact-container{
                    border-radius: 3px;
                    border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
                    background: var(--white, #FFF);
                    padding: 16px;
    
                    h4{
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 144.444% */
                        letter-spacing: -0.36px;
                        margin-bottom: 8px;
                    }
                    
    
                    
                    .info-wraper{
                        display: flex;
                        gap: 4px;
                        margin-top: 6px;
                        a{
                            color:  #36363B;
    
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px; /* 144.444% */
                            letter-spacing: -0.36px;
                        }
                    }
                }
                .contac-1{
                    margin: 16px 0 16px;
                    
                }
                .contac-2{
                    margin-bottom: 40px;
                    
                }
            }
        }
    
    
        
    }
}