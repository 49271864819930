
.grid-eventos{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
    @include d(lg){
        grid-template-columns:  repeat(3,1fr);;
    }
    @include d(md){
        grid-template-columns:  repeat(2,1fr);;
        gap: 1rem;
    }
    @include d(sm){
        grid-template-columns:  repeat(1,1fr);;
    }
    
}

.card-evento:not(.card-programacao){ 

    position: relative;

    cursor: pointer;


    &:hover{
        img{
            transform: scale(1.08);
            transform-origin: bottom;
        }
    }
    .card-evento-img{
        overflow: hidden;
        height: 100%;
        img{
            border-radius: 3px;
            width: 100%;
            min-height: 250px;
            object-fit: cover;
            transition: transform 0.2s ease-in-out;
            height: 100%;
        }
    }

    .content{
        background-color: #F2A900;
        max-width: calc(100% - 1.25rem);
        padding:  1rem 1.5rem;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include d(lg){
            padding: 10px 12px;
        }


        .icon-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            background-color: #1E1A34;
            padding: .375rem;
            border-radius: 0 0 0 6px;
            svg{
                width: 1.25rem;
                height: 1.3125rem;
                color: #FFFFFF;
            }
        }

        .card-evento-title{
      
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #FFFFFF;


            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @include d(lg){
                font-size: 1.3rem;
            }


        }
        .card-evento-role{
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.4;
         

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(255, 255, 255, 0.6);
            
            @include d(lg){
                font-size: 1rem;
            }
        }
    }
} 