//colocar  as variaveis de cor  do projeto no body como variaveis css
body {
    // Generics
    --white: #FFFFFF;
    --white-70-op: hsla(0, 0%, 100%, 0.7);
    --white-10-op: hsla(0, 0%, 100%, 0.1);
    --black: #000000;

    // Neutral
    --neutral-100: #090A1A;
    --neutral-70: #464651;
    --neutral-60: #666670;
    --neutral-50:rgba(9, 10, 26, 0.5);
    --neutral-40:rgba(9, 10, 26, 0.4);
    --neutral-30:#E5E6EB;
    --neutral-20:#F0F1F5;
    --neutral-10:#F5F6FA;

    // Primary
    --primary-light: #8186F7;
    --primary-light-10-op: hsla(237, 88%, 74%, 0.1);
    --primary-light-30: #A8ABFE;
    --primary-pure-10: #EAEAF8;
    --primary-pure: hsla(var(--hue-primary), 64%, 54%, 1);
    --primary-pure-70: #6A6ED1;

    --hue-primary: 238;

    --alert-error:#E92C2C;
    --alert-error-10:#FDEAEA;
    --alert-warning:#D2AF00;
    --alert-warning-10:#FBF7E6;
    --alert-sucess:#008024;
    --alert-sucess-10:#E6F9F0;

    --bienal-amarelo: #F2A900;
    --bienal-rosa: #A3238E;
    --bienal-laranja: #F2A900;
    --bienal-vermelha: #E60043;
    --bienal-azul: #99D6EA;


}




$shadow-sm-1: 0px 0px 2px rgba(0, 0, 0, 0.06);
$shadow-sm-2: 0px 2px 4px rgba(149, 157, 165, 0.08);
$shadow-sm-2: 0px 0px 2px rgba(104, 110, 117, 0.2);

$shadow-md-1: 0px 4px 8px rgba(0, 0, 0, 0.06);
$shadow-md-2: 2px 4px 16px rgba(0, 0, 0, 0.06);
$shadow-md-3: 2px 8px 32px rgba(0, 0, 0, 0.04);

$shadow-lg-1: 2px 6px 24px -2px rgba(149, 157, 165, 0.06);
$shadow-lg-2: 0px 24px 64px rgba(139, 152, 149, 0.06), 2px 8px 32px rgba(175, 185, 183, 0.06);
$shadow-lg-3: 2px 24px 64px -20px rgba(0, 0, 0, 0.06);



$bienal-amarelo:#{var(--bienal-amarelo)};
$bienal-rosa:#{var(--bienal-rosa)};
$bienal-laranja:#{var(--bienal-laranja)};
$bienal-vermelha:#{var(--bienal-vermelha)};
$bienal-azul:#{var(--bienal-azul)};


// forma de utilizar variaveis css com a syntaxe do sass

// $cor-preto-1:#{var(--cor-preto-1)};




@font-face {
  font-family: "Extatica-new";
  
  font-weight: 400;
  src: url( "../../fonts/Extatica-new/Extatica-Regular.otf") format("opentype");
} 
 
@font-face {
  font-family: "Extatica-new";
 
  font-weight: 500;
  src: url( "../../fonts/Extatica-new/Extatica-Medium.otf") format("opentype");
} 

@font-face {
  font-family: "Extatica-new";
 
  font-weight: 600;
  src: url( "../../fonts/Extatica-new/Extatica-SemiBold.otf") format("opentype");
} 



@font-face {
  font-family: "Extatica-new";
 
  font-weight: 700;
  src: url( "../../fonts/Extatica-new/Extatica-Bold.otf") format("opentype");
} 


@font-face {
  font-family: "Extatica-new";
 
  font-weight: 800;
  src: url( "../../fonts/Extatica-new/Extatica-ExtraBold.otf") format("opentype");
} 
 
 
@font-face {
  font-family: "sweetsans";
  font-style: normal;
  src: url( "../../fonts/SweetSansPro-Bold.woff") format("woff");
  font-weight: 700;
} 


  // $font-1:  "Extatica-new", sans-serif; 
  $font-1:  "Antonio", sans-serif; 
  $font-2:  "Noto Sans", sans-serif; 


 