
#banner-home{
    position: relative;


    &.height-video{
        height: calc(100vh - 123px);

        @media(max-width:1400px){
       
            height: calc(100vh - 123px);
        }
        @media (max-width:1024px){
           
            height: calc(100vh - 113px);
        }
    }
  

    &::after{
        content: '';
        display: none;
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        mix-blend-mode: multiply;

        @include d(md2){
            // display: none;
        }
    }

    //  @media(max-width:1400px){
       
    //      height: calc(100vh - 123px);
    //  }
    //  @media (max-width:1024px){
        
    //      height: calc(100vh - 113px);
    //  }

    .video-wrapper{
        position: absolute;
        inset: 0 !important;
        bottom: 55px;
        overflow: hidden;

        .mobile-banner-img{
            display: none;
            height: 100%;
            @include d(md2){
                display: block;

            }
            img{
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
           
        }

        #video_mobile{
            @include d(md2){
                display: none;
            }
        }

        video{
            height: 100%;
            width: 100%;
            object-fit: cover;

        }
    }

    .container{
        height: 100%;
    }

    .banner-content{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        z-index: 3;

        span{
           
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            
            @media (max-width: 1440px){
                font-size: 1.3rem;
            }
            line-height: 1.33;
            /* identical to box height, or 133% */

            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin-bottom: 1rem;
            display: block;
            /* White */

            color: #FFFFFF;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

            @media (max-width: 1440px){
                font-size: 1.3rem;
            }
            @include d(md2){
                font-size: 1.1rem;
            }

        }

        h1{
            font-weight: 700;
            font-size: 8rem;
            line-height: 1.1;

          

            // text-align: center;
            letter-spacing: -0.03em;
            text-transform: uppercase;

            /* White */

            color: #FFFFFF;

            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);

            @media (max-width: 1440px){
                font-size: 6rem;
            }
            @include d(md2){
                font-size: 4rem;

            }
            @include d(sm){
                max-width: 15ch;
                margin: 0 auto; 
            }

            
        }
        .button-wrapper-banner{
            max-width: max-content;
            margin: 0 auto;
            margin-top: 2rem;

            a{
                @include BotaoMain(#1E1A34);
                box-shadow: 2.5px 2.5px #D6DF21;

                span{
                    margin-bottom: 0;
                    color: #fff !important;
                    font-size: 1.125rem;
                    font-weight: 500;
                }

                svg{
                    width: 32px;
                    height: 32px;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .banner-bottom-info{
        position: absolute;
        //bottom: 0;
        bottom: -3.5rem;
        left: 0;
        right: 0;
        width: 100%;
        background:url('../../src/assets/imagens/junte-se-bg.png') ;
        background-size: cover;
        background-position: center;
        // background:url('../../src/assets/imagens/bg-juntese.png');
        color: #D6DF21;
        z-index: 2;
        overflow: hidden;
        min-height: 56px;
     

     
       
        // display: grid;
        // grid-template-columns: 1fr max-content 1fr;
        // align-items: center;

        display: grid;

        text-align: center;
        font-size: 1.375rem;

        &.video{
            bottom: 0;
        }

        img{
            width: 100%;
            height: 100%;
            // max-height:  56px;
            object-fit: cover;
        }
        @include d(sm){
            font-size: 1rem;

            // grid-template-columns: 10px 1fr 10px ;
        }

        .linha {
            align-self: stretch;
            img{
                width: 100%;
                height: 100%;
                // max-height:  56px;
                object-fit: cover;
            }
            &.linha-left{
               
                img{
                    object-position: right;
                }
            }
           &.linha-right{
               
                img{
                    object-position: left;
                }
            }

            
        }
        p{

        
            font-style: normal;
            font-weight: 500;
        
            line-height: 1.4;
            /* identical to box height, or 145% */

            letter-spacing: -0.02em;
          
            text-transform: uppercase;

            span{
                letter-spacing: -0.02em;
                text-decoration-line: underline;
                font-weight: 800;
            }
        }
    }

    .swiper-pagination-bullet{
        background: #FFFFFF;
        border-radius: 6px;
        width: 24px;
        height: 12px;
    }

    .banner-image{
        width: 100%;
        min-height: 300px;
        object-fit: cover;
    }

   .conteudo-wrapper {
        position: absolute;
        top: 34%;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        //max-width: 71.75rem;
        max-width: 100vw;
        width: 100%;

        @include d(md){
            top:26%;
        }

        @include d(md2){
            top: 35%;
            text-align: center;
            padding: 0 1rem;
        }

        @media(max-width:500px){
            top: 27%;
        }

        h1 {
            font-weight: 700;
            font-size: 5.5rem;
            line-height: 1.1;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
            margin-bottom: 2rem;
            max-width: 68.75rem;
            text-align: center;

            @media (max-width:1750px){
                font-size: 4.5rem;
            }

            @media (max-width:1450px){
                font-size: 3.5rem;
                max-width: 54.75rem;
            }

            @include d(md){
                width: 37rem;
                font-size: 3rem;
            }

            @include d(md2){
                width: 23rem;
                font-size: 3rem;
            }
            
        }

        h3 {
            margin-bottom: 1rem;
            color: var(--white, #FFF);
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133%; /* 133.333% */
            letter-spacing: -0.03rem;
            text-transform: uppercase;

            @include d(md){
                font-size: 1.25rem;
            }
        }

        h2 {
            margin-top: .5rem;
            margin-bottom: 2rem;
            color: #FFF;
            font-size: 2.75rem;
            font-weight: 600;
            line-height: 4rem;
            letter-spacing: -0.055rem;
            text-transform: uppercase;

            @media (max-width:1450px){
                font-size: 2rem;
            }

            @include d(md){
                font-size: 1.25rem;
            }
        }

        .btn{
            a{
                    @include BotaoMain(#A3238E);
                    box-shadow: 2.5px 2.5px #D6DF21;
                    span{
                        margin-bottom: 0;
                        color: #fff !important;
                        font-size: 1.125rem;
                        font-weight: 500;
                    }

                    svg{
                        width: 32px;
                        height: 32px;
                        filter: brightness(0) invert(1);
                    }
                }
        }
    }
}

#banner-ao-vivo {
    position: relative;

    .bg-image-ao-vivo{
        min-height: 200px;
        width: 100%;

        @include d(md){
            object-fit: cover;
        }
    }

    .text-content{
        max-width: 722px;
        width: 100%;
        position: absolute;
        top: 3.85rem;
        left: 24rem;

        @media (max-width:1600px){
            top: 3rem;
            left: 22rem;
        }

        @media (max-width:1400px){
            max-width: 275px;
            top: 4rem;
            left: 18rem;
        }

        @media (max-width:1200px){
            top: 2.75rem;
            left: 16rem;
        }

        @include d(md){
            left: 11rem;
        }

        @include d(md2){
            top:6rem;
            left: 7rem;
            max-width: 180px;
        }

        @media (max-width:500px){
            top: 4rem;
        }

        @media (max-width:450px){
            top: 3rem;
            left: 6rem;
        }

        @media (max-width:400px){
            top: 2rem;
            left: 4rem;
        }

         @media (max-width:350px){
            left: 2.5rem;
        }

        h1{
            font-size: 4.5rem;
            font-style: normal;
            font-weight: 800;
            line-height: 111%; /* 111.111% */
            letter-spacing: -0.09rem;
            text-transform: uppercase;
            color:var(--primary-azul-noite, #2E3192);
            margin-bottom: 1rem; 

             @media (max-width:1400px){
                font-size: 2rem;
            }

            @include d(md){
                left: 14rem;
            }

            // @media (max-width:400px){
            //     font-size: 1.5rem;
            // }
        }

        p{
            color: var(--primary-azul-noite, #2E3192);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133%; /* 133.333% */
            letter-spacing: -0.03rem;
            margin-bottom: 2rem;

            @media (max-width:1400px){
                font-size: 1rem;
            }

            @media (max-width:1200px){
                margin-bottom: 1rem;
            }
           
            @include d(md2){
                margin-bottom: 2rem;
            }
        }

        .button-live{
            max-width: 12.65rem;
            display: flex;
            padding: .75rem 1rem;
            align-items: center;
            gap: 1.5rem;
            background: var(--bienal-amarelo);
            border-radius: .1875rem;
            color: var(--black);
            font-size: .875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 157%; /* 157.143% */
            letter-spacing: -0.0175rem;
            text-transform: uppercase;
            box-shadow: 2.5px 2.5px #a11c64;

            &:hover{
                transform: perspective(1px) translate3d(0, 2.5px, 0); 
            }

            svg{
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}

#swiper-mini{
    overflow: hidden;
    position: relative;
    margin-top: -55px;
    .swiper-mini{
        background: $bienal-vermelha;
        height: 100%;
        overflow: hidden;
        .swiper-wrapper{
            transition-timing-function: linear
        }
        .swiper-slide{
            max-width: max-content;
        }
    }
   
    .mini-item{
        display: flex ;
        align-items: center;
        gap: 32px;
        padding: 21px 0;


        svg{
            width: 17px;
            height: 20px;
        }
    }

}

#o-evento{
    position: relative;
    background-color:#99D6EA ;
    margin-bottom: -100px;
    

    .img-bg-evento{
        width: 100%;
        position: relative;

        .evento-bg-1{
            display: none;
        }
        .evento-bg{
             width: 100%;
        }
        .balanco{
            position: absolute;
            left: 0;
            top: 200px;
            
        }
        .flor{
            position: absolute;
            bottom: 40px;
            left: 15%;
        }
        .menina-voando-azul{
            position: absolute;
            top: 200px;
            left: 20%;
        }
        .casal{
            position: absolute;
            bottom: 26%;
            left: 35%;
        }
        .menina-voando-direita{
            position: absolute;
            bottom: 36%;
            right: 8%;

        }
        .passaro{
            position: absolute;
            bottom: 76%;
            right: 0;

        }
    }

    .container-evento{
        margin-bottom: -140px;

        
    }
    .section-title .section-description{
        text-align: justify;
        
        
    }

    .section-title .titulo-evento{
        max-width: 412px;
        font-family: Antonio;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 83.2px */
        text-transform: uppercase;
    }
    .grid-wrapper{
        display: grid;
        grid-template-columns: 0.5fr 1.2fr;
        margin-bottom: 1.875rem;
        position: relative;
        z-index: 10;
        

        @include d(md){
            grid-template-columns: 1fr; 
            gap: 2rem ;
        }

    }
    .icon-laranja{
        position: absolute;
        right: 0;
        top: 4rem
    }
    .badge-for-ornamento{
        position: relative;
        width: max-content;
        .icon-luneta{
            position: absolute;
            z-index: 2;
            top: -60px;
            right: 0;
        }
    }
    .col-2{
        position: relative;
        display: grid;
        justify-content: end;
        z-index: 2;

        @include d(md2){
            margin-top: 2rem;
        }

        .section-description{
            
            // max-width: 683px;
            max-width: 100%;
            font-family: $font-2;
            color: rgba(0, 0, 0, 0.70);
            // font-size: 1.25rem;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: .875rem ;
        }
    }

    .card-flutuante {
        background: #FFFFFF;

        border: 1px solid rgba(15, 15, 18, 0.05);
        box-shadow: 0px 7.93959px 31.7584px rgba(53, 55, 56, 0.08);
        border-radius: 6px;
        width: max-content;
        padding: 1rem 1.5rem;

        text-align: center;
        max-width: 200px;
        width: 100%;
        position: absolute;
        @include d(dt){
            max-width: 180px;
        }
      

        @include d(md2){
            // max-width: 106px;
            padding: 10px 14px;
            max-width: max-content;
            min-width: 102px;
        }

        &::before{
            content: '';
             position: absolute;
             height: 1px;
          
             bottom: 0;
             z-index: 1;
             left: 50%;
             transform: translateX(-50%);
             width: 63px;

        }

        &-1{
            color: #A3238E;
            top: 5rem;
            left: 0;
            @include d(dt){
                top: 1rem;
            }
            @include d(md2){
                top: -10px;
            }

            &::after{
                background-color: #A3238E;
            }
        }
        &-2{ 
            color: #56C5D0; 
            
            bottom: 3.125rem;
            left: 0;
            @include d(dt){
                bottom: -40px;
            }
            @include d(md2){
                left: 40px;
                bottom: -40px;
            }
                &::after{
                background-color: #56C5D0;
            }
        }
        &-3{ color: #F58220;
            bottom: 3.75rem;
            right: 0;
            @include d(dt){
                bottom: 1rem;
            }
            @include d(md2){
                bottom: 4rem;
            }
            &::after{
                background-color: #F58220;
            }
        }
     

        .number{
        
            font-weight: 800;
            font-size: 3rem;
            line-height: 117%;
            /* identical to box height, or 117% */

           
        
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Primary/Fucsia */
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 24px;
            }
          
        }
        .info{
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            /* identical to box height, or 150% */

         
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/70 */

            color: #36363B;
            @include d(md2){
                font-size: 12px;
            }
        }

    }

    .img-wrapper{
        padding: 0 100px;
        position: relative;
        @include d(lg){
            padding: 0 60px;
        }
        @include d(md2){
            padding: 0 10px;
        }

        // img{
        //     // min-height: 400px
            
        // }
    }
    .button-wrapper{

        // box-shadow: 6px 6px #1d336f !important;
        a{
            @include BotaoMain(#fff);
    
            // @include BotaoTeste(red, blue);
            // 
            
        }
    }


    .alien-orna{
        position: absolute;
        bottom: -70px;
        right: 20vw;
        z-index: 2;

        @include d(lg){
            right: 20vw;
            bottom: -50px;
            max-width: 200px;
        }
        @include d(md){
            max-width: 130px;
            bottom: -100px;
            right: 1.5rem;
        }
    }

    .gatinho-orna{
        margin-bottom: -20px;
        @include d(md2){
            max-width: 123px;
            margin-left: 21px;

        }
    }
    .linear-orna{
        position: relative;

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }

    
}

@media(max-width:1673px){
    #o-evento{
        
        .container-evento {
            margin-bottom: -110px;
        }

    
        .img-bg-evento{
            
            .evento-bg{
                 width: 100%;
            }
            .evento-bg-2{
                width: 100%;
           }
            .balanco{
                img{
                    width: 66%;
                }
            }
            .flor{
                img{
                    width: 66%;
                }
            }
            .menina-voando-azul{
                img{
                    width: 66%;
                }
            }
            .casal{
                img{
                    width: 66%;
                }
            }
            .menina-voando-direita{
                img{
                    width: 66%;
                }
    
            }
            .passaro{
                right: -50px;
                img{
                    width: 66%;
                }
    
            }
        }
    
        
    
        
    }
}
@media(max-width:1180px){
    #o-evento{
        
        .container-evento {
            margin-bottom: -80px;
        }
    
        .img-bg-evento{
            
            .evento-bg{
                 width: 100%;
            }
            .evento-bg-2{
                width: 100%;
           }
            .balanco{
                img{
                    width: 46%;
                }
            }
            .flor{
                img{
                    width: 46%;
                }
            }
            .menina-voando-azul{
                img{
                    width: 46%;
                }
            }
            .casal{
                img{
                    width: 46%;
                }
            }
            .menina-voando-direita{
                img{
                    width: 46%;
                }
    
            }
            .passaro{
                right: -80px;
                img{
                    width: 46%;
                }
    
            }
        }
    
        
    
        
    }
}

@media(max-width:930px){
    #o-evento{
        
        .container-evento {
            margin-bottom: -20px;
        }
    
        .img-bg-evento{
            
            .evento-bg{
                 width: 100%;
            }
            .evento-bg-2{
                width: 100%;
           }
            .balanco{
                top: unset;
                bottom: 35%;
                img{
                    width: 46%;
                }
            }
            .flor{
                img{
                    width: 46%;
                }
            }
            .menina-voando-azul{
                top: unset;
                bottom: 55%;
                img{
                    width: 46%;
                }
            }
            .casal{
                img{
                    width: 46%;
                }
            }
            .menina-voando-direita{
                right: -50px;
                img{
                    width: 46%;
                }
    
            }
            .passaro{
                right: -80px;
                img{
                    width: 46%;
                }
    
            }
        }
    
        
    
        
    }
}
@media(max-width:699px){
    #o-evento{
        margin-bottom: -58px;
        
        .container-evento {
            margin-bottom: -20px;
        }
    
        .img-bg-evento{
            
            .display-2{
                 display: none;
            }
            .evento-bg-1{
                display: block;
            }
        }
    
        
    
        
    }
}

#atracoes{
    background-color:  $bienal-vermelha;
    padding-top: 60px;
    padding-bottom: 60px;

    // background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }

    .minina-esquerda{
        position: absolute;
        left: 0;
        bottom: 65px;

        img{
            @include d(md2){
                width: 70%;
            }   
        }
        
    }
    .passaro-direita{
        position: absolute;
        right: 0;
        bottom: 65px;
        @include d(md2){
            right: -45px;

        }  
        img{
            @include d(md2){
                width: 70%;
            }   
        }
        
    }
    // &::after{
    //     content: '';
    //     position: absolute;
    //     inset: 0;
    //     background: rgba(217, 217, 217, 0.2);
    // }
    .info-sujeita{
        color: #fff;
        letter-spacing: -.02em;
        text-transform: uppercase; 
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
        font-size: 1.8rem;
    }

    .section-title{
        h2{
            color: #fff;

            @include d(sm){
                max-width: 15ch;
            }
        }
      
    }


    .info-atracoes-titulo{
        letter-spacing: -.02em;
        text-transform: uppercase;
        color: #fff;
        font-size: 2rem;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .button-wrapper{
        flex-shrink: 0;
        justify-content: center;
        display: flex;
        padding-bottom: 160px;
        padding-top: 64px;
        a{
            @include BotaoMain(#fff);
        }
    }

     .button-wrapper-bottom{
        @media (min-width: 768px){
            display: none; 
        }
        .button{
            max-width: 100%;
            justify-content: center;
        }
     }


     .linear-orna{

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }
} 

#programacao{
    position: relative;


    .section-title{
        position: relative;
        width: max-content;
        margin: 0 auto;
        margin-bottom: 3.125rem;

      
        .ornamento-peixe{
            position: absolute;
            right: -4rem;
            top: 50%;
            transform: translateY(-57%);
        }
        h2{
            position: relative;
            z-index: 4;
        }
    }



    .swiper-programacao-tabs{
        margin-bottom: 2.5rem;

        .swiper-wrapper{
            justify-content: center;
            @include d(md){
                justify-content: unset;
            }
        }
        .swiper-slide{
            max-width: max-content;
         
        }
    }

    .tab-programacao{

        background-color: #F5872C;
        cursor: pointer;
        /* White
        
        FFFFFF
        */
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        padding: 10px;
        color: rgba(255, 255,255, 0.6);


        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        

        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;


        min-width: 140px;

       
        @include d(lg){
            min-width: 112px;
        }
        // @include d(lg){
        //     min-width: 120px;
        // }

        .programacao-data{
            font-size: 1.5rem;
            font-weight: 800;
            @include d(md2){
                line-height: 1;
            }
        }

        &.active{
            background: #A3238E;;
            color: #fff;
        }
    }
 

    .programacao-tab-content{
        display: none; 
        // opacity: 0;
        // max-height: 0;
        // overflow: hidden;
        // transition: max-height  0.2s ease-in;
        min-height: max-content;
        position: relative;
        &.active{
            display: block;           
            opacity: 1;     
        }

        .gatinho-orna{
            position: absolute;
            top: -100px;
            left: 15px;

            @include d(dt){
                display: none;
            }
            @include d(md2){
                display: block;
                top: unset;
                bottom: 0;
                transform: scale(0.5);
                transform-origin: left;
            }

        }
        

        .grid-wrapper{
            display: grid;
            grid-template-columns: 0.3fr 1fr;
            gap: 1rem;
            color: #fff;
            z-index: 2;
            position: relative;
            @include d(md){
                grid-template-columns: 1fr;
                gap: 4px;
            }
        }
     

        .side-info{
            position: relative;
            border-radius: 4px;

            background-color: $bienal-rosa;           
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include d(md){
                grid-row: 2;
            }
            @include d(md2){
                background: transparent;
            }

            .content-side{
                padding: 2rem;
                margin: auto;
                @include d(lg){
                    padding: 1rem; 
                }
                @include d(md2){
                    background-color: $bienal-vermelha;
                    margin: unset;
                    border-radius: 4px;
                    margin-bottom: 4px;
                }
            }
           
            .img-detail{
                width: max-content;  
                margin: 0 auto;
                margin-bottom: 2rem;
                @include d(md2){
                    position: absolute;
                    right: 0;
                    top: -20px;
                    margin-bottom: 0;
                    img{
                        height: 40px;
                    }
                }
                
            }

            .programacao-horario{
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 3rem;
                line-height: 1.17;
                /* identical to box height, or 117% */

                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
                @include d(lg){
                    font-size: 2.5rem;
                }
                @include d(md2){
                    text-align: left;
                }
            }
            .programacao-local{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.4;
             
                
                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                @include d(md2){
                    text-align: left;
                }
            }
            .bottom-info{
                background: #56C5D0;
                padding: 12px 0;
               
                @include d(md2){
                    border-radius: 4px;
                    padding:14px ;
                    margin-top: 2rem;
                    
                }
                .button-bottom-info{
                    display: flex;
                    // align-items: center;
                    align-items: center;
                    justify-content: center;

                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    text-align: center;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #000000;

                   
                    

                    svg{
                        width: 1.25rem;
                        height: 1.3125rem;
                        margin-left: 4px;
                        flex-shrink: 0;
                        margin-top: -3px;
                    }
                }
            }
        }
        .programacao-dia-wrapper{
            background-color: $bienal-rosa;
            display: grid;
            grid-template-columns: 0.4fr 0.6fr;
            border-radius: 4px;

            @include d(md2){
                grid-template-columns: 1fr;
            }
          


            .swiper-programacao-atracoes{
                display: grid;
                width: 100%;

                @include d(md2){
                    // overflow: scroll;
                }

             
            }
            .swiper-controls-header{

                .buttons{
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    .button{
                        background-color: $bienal-amarelo;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        display: grid;
                        place-content: center;
                        svg{
                            path{

                                stroke: #000
                            }
                            
                        }
                        &.swiper-button-disabled{
                            background-color: transparent;
                            border: 1px solid white;
                            svg{
                                path{
    
                                    stroke: #fff
                                }
                                
                            }
                        }
                    }
                }

                .swiper-button-lock{
                    display: none !important;
                }
            }
            .dia-info-wrapper{
                padding: 3.5rem;
                border-right: 1px solid rgba(255, 255, 255, 0.2);

                @include d(lg){
                    padding: 2rem; 
               
                } 
                @include d(md2){
                    padding: 1.5rem; 

                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
               
                } 

                .title{
                    font-weight: 800;
                    font-size: 1.5rem;
                    line-height: 1.3;
                    margin-bottom: 1rem;
                    /* identical to box height, or 133% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* White */

                    color: #FFFFFF;
                }
                .descricao{
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    /* or 150% */

                    letter-spacing: -0.02em;

                    @include d(md2){

                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }
                .content-for-p{
                    &.open{
                        
                        .descricao{
                            display: block;
                        }
                    }
                }

                
                .continuar-lendo{
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: -0.02em;
                    color: #D6DF21;
                    display: none;
                    @include d(md2){
                        display: flex;
                    }
                    svg{
                        flex-shrink: 0;
                        margin-left: 5px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .dia-atracoes-wrapper{
                padding: 3.5rem;
                display: grid;
                align-items: flex-start;
                width: 100%;
                @include d(lg){
                    padding: 2rem;
                }
            }

            .header-atracoes {
                align-self: flex-end;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 11px;

                .header-atracoes-titulo{
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.3;

                    
                }
            }

            .card-programacao{
                cursor: pointer;
                
                .card-evento-img{ 
                    position: relative;
                    padding-top: 100%;
                    overflow: hidden;
                    height: 100%;
                    img{
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 3px;
                        border-radius: 3px;
                        height: 100%;

                    } 
                }
                .card-evento-title{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.4;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    color: #D6DF21;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .card-evento-role{
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: 157%;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        }
    }
}
#atracoes .card-evento:not(.card-programacao) .card-evento-img{
    height: 100%;
    img{
    height: 100%;

    }
}

#galeria {
    background-color:  $bienal-laranja;
    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    height: 100%;

    .galeria-wrapper {
        padding: 7.5rem 0 3.5rem 0;

        .text-button {
            display: flex;
            justify-content: space-between;
            
            @include d(md){
                flex-direction: column;
            }

            h2{
                color: #fff;
            }

            .button-live{
                max-width: 12.65rem;
                display: flex;
                padding: .75rem 1rem;
                align-items: center;
                gap: 1.5rem;
                background: var(--bienal-amarelo);
                border-radius: .1875rem;
                color: var(--black);
                font-size: .875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 157%; /* 157.143% */
                letter-spacing: -0.0175rem;
                text-transform: uppercase;
                box-shadow: 2.5px 2.5px #a11c64;
    
                &:hover{
                    transform: perspective(1px) translate3d(0, 2.5px, 0); 
                }
            }
        }
    }

    .cards-wrapper {
        padding-bottom: 7.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
        .col-1,.col-2 {
            // display: grid;
            // gap: 1rem;
            // grid-template-columns: repeat(4,1fr);
            display: flex;
            gap: 1rem;
        }

        img {
            width: 23.75rem;
            height: 19.5rem;
            object-fit: cover;
        }
    }

}

#instagram{

    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    } 
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;     
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }
    .instagram-wrapper-new{

        #instafeed {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @include d(md2){
                grid-template-columns: 1fr 1fr ;
            }
    
         
        
            a {
                width: 100%;
                overflow: hidden;
                position: relative;

                &:hover .content-hover{
                 transform: translateY(0);
                }
            
                .content-hover{
                    position: absolute;
                    pointer-events: none;
                    background-color: rgba(230, 0, 67, 1);
                    text-align: center;
                    padding: 2rem;
                    display: grid;
                    place-content: center;
                    gap: 10px;

                    inset: 0;
                    height: 100%;

                    overflow: hidden;
                    transform: translateY(100%);
                    transition: 0.2s ease;

                    .see-insta{
                        color:  #FFF;
                        text-align: center;
                        font-family: Antonio;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130%; /* 31.2px */
                        text-transform: uppercase;
                        @include d(md2){
                            font-size: 1rem;
                        }
                    }
                    .click-icon{
                        margin-right: 2rem;

                    }
                    svg{
                        margin: 0 auto;
                        width: 2.0625rem;
                        height: 2rem;
                        color: #fff;
                    }
                }
            }
        }


    }
    .banner-embaixo{
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 1rem;

        @include d(sm){
            flex-direction: column;
        }
    }
    
    .instagram-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;

        @include d(md2){
            grid-template-columns: repeat(2, 1fr);
        }
    }

 

    .img-wrapper{
        border-radius: 3px;
        overflow: hidden;
        position: relative;
       

        a{
            height: 100%;
            display: block;
            img{
                object-fit: cover;
                height: 100%;
            }
        }

        
        &:hover .content-hover{
            transform: translateY(0);
        }
    

        .content-hover{
            position: absolute;
            pointer-events: none;
            background-color: rgba(230, 0, 67, 1);
            text-align: center;
            padding: 2rem;
            display: grid;
            place-content: center;
            gap: 10px;

            inset: 0;
            height: 100%;

            overflow: hidden;
            transform: translateY(100%);
            transition: 0.2s ease;

            .see-insta{
                color:  #FFF;
                text-align: center;

                /* Heading/H4 - 24 */
                font-family: Antonio;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
                text-transform: uppercase;
                @include d(md2){
                    font-size: 1rem;
                }
            }
            .click-icon{
                margin-right: 2rem;

            }
            svg{
                margin: 0 auto;
                width: 2.0625rem;
                height: 2rem;
                color: #fff;

            
            }
        }

       
    }

   
}

#midia{


    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    } 
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;     
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }

} 


.bottom-info-blog{

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg{
        width: 24px;
        height: 24px;
       
        filter: brightness(0) invert(1);
    }
}

.blog-card {
    border-radius: 3px;
    overflow: hidden;
    
}
.blog-card.blog-destaque{
    // display: flex;


    &.half{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-template-rows: 60% 40%;
            display: flex;
             flex-direction: column;
        }
    }
    &.half-bottom{
        display: grid;
        grid-template-rows: 60% 40%;
        @include d(dt){
            display: flex;
            flex-direction: column;
        }
    }

    .img-wrapper{
        @include d(dt){
            flex: 1;

            position: relative;
            padding-top: 75%;
            overflow: hidden;
        }

        img{ 
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include d(dt){
                position: absolute;
                inset: 0;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }


    
    
    .content-blog{
        background: #9B2743;
        padding: 2rem;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include d(dt){
            padding: 1rem;
            flex: 1;
        }

        h2{
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.1;
            /* or 106% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            flex: 1;
            margin-bottom: 1rem;
            

            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include d(lg){
                font-size: 1.5rem;
            }
            @include d(dt){
                font-size: 1.2rem;
            }
        }

        .info-blog{
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(255, 255, 255, 0.8);
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
        }
    }
}


@media (min-width:768px){
    .swiper-midia {
        display: none;
    
    }
    
}

.swiper-midia{
    overflow: visible;
    .swiper-slide{
        height: auto;

        .blog-card{
            height: 100%;
            display: flex;
            flex-direction: column;

            .img-wrapper{
                flex: 1;
            }

            .content-blog{
                flex: unset;
            }
        }
    }

    .swiper-slide:nth-child(1) {
        .content-blog{
            background-color:  #9B2743;
        }
    }
    .swiper-slide:nth-child(2) {
        .content-blog{
            background-color:  #FF6A39;
        }
    }
    .swiper-slide:nth-child(3) { 
        .content-blog{
            background-color:  #5CBDE5;
        }
    }
    .swiper-slide:nth-child(4) {
        .content-blog{
            background-color:  #F2A900;
        }
    }

    .todas-noticias{
       
        
        border: 1px solid #0F0F12;
        border-radius: 3px;
   
        display: block;
        padding: 10px;
        margin-top: 1rem;
      
        p{

            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
    
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
    
            /* Black */
    
            color: #000000;
        }

        svg{
            width: 20px;
            height: 20px;
        }
    }
}

.blog-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 27.8125rem 27.8125rem;
    gap: 2rem;

    @media( max-width: 768px){
        display: none;
    }
    @include d(lg){
        gap: 1rem;
        grid-template-rows: 25rem 25rem;
    }

    @include d(dt){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
       
    }
    @include d(md2){
        grid-template-columns: 1fr;
    }

    .blog-card:nth-child(1){
        grid-template-columns: 1.6fr 1.4fr;
        grid-column: 1/ 4;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-column: unset;
            // display: block;
        }

       


        .content-blog{
            background-color: #9B2743;
        }
        img{
            max-height: 445px;
        }
    }
    .blog-card:nth-child(2){
        grid-column: 4 / 6;

        @include d(dt){
            grid-column: unset;
        }

        .content-blog{
            background-color:  #FF6A39;
        }
    }
    .blog-card:nth-child(3){
        grid-row: 2;
        grid-column: 1 / 3;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
            background-color:  #5CBDE5;
        }
    }
    .blog-card:nth-child(4){
        grid-row: 2;
        grid-column: 3 / 5;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
        background-color:  #F2A900;
         }
    }

    .todas-noticias{
        border: 1px solid #0F0F12;
        background: #FFFFFF;
        border-radius: 3px;
        padding: 1rem;
        display: grid;
        place-content: center;
        @include d(dt){
            grid-column:  span 2;
        }
        @include d(md2){
            grid-column: unset;
        }
        p{
            display: flex;
            align-items: center;
          
            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
        }
        svg{
            width: 20px;
            margin-bottom: 5px;
            height: 21px;
        }
    }
}



#youtube{
    background-color:  $bienal-laranja;

    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }

    @media (min-width:1024px){
        
        .swiper-pagination{
            position: unset;
            margin-right: 2rem;
            max-width: 80px !important;
        }
    }
    .swiper-pagination{
        max-width: 80px !important;
    }
    .controls{
        display: flex;
        align-items: center;
        margin-bottom: 10px;


        
            .buttons{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                gap: 1rem;
                @include d(md){
                    
                  display: none;
                   
                }
    
                .button{
                    padding: 8px 16px;
                    gap: 8px;
    
                    width: 56px;
                    height: 56px;
                    display: grid;
                    place-content: center;
                    /* Primary/Verde
    
                    D6DF21
                    */
                    background: #D6DF21;
                    border-radius: 3px;
    
                    svg{
                        width: 24px;
                        height: 24px;
                    }
                    &.button-left{
    
                        svg{
                            transform: rotate(180deg); 
                        }
                    }
                } 
    
                .swiper-button-disabled{
                    background: #E3E3E6;
                }
                
            }
    }


    .swiper-youtube{
        @include d(md){
            overflow: visible;
        }

        .swiper-wrapper{
            @include d(md){
    
                padding-bottom: 4rem;
            }
        }
        .swiper-slide{
            height: auto;
            > a{
                height: 100%;
                display: grid;
            }
        }

    }
 
   
  
    .swiper-pagination{

        .swiper-pagination-bullet {
            opacity: 1;
            background: rgba(255, 255, 255, 0.36);;
        }
        .swiper-pagination-bullet-active{
            background-color: $bienal-amarelo;
            width: 16px;
            border-radius: 8px;
        }
    }


    .section-title{
        h2{
            color: #fff;
        }
    
    }
   
   
    .card-youtube{
        color: #fff;
        // max-width: 512px;
        display: flex;
     flex-direction: column;

        .img-wrapper{
            margin-bottom: 1.5rem;
            // flex: 1;
            padding-top: 56.25%;
            position: relative;
            
            @include d(md){
                margin-bottom: 1rem;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;            
                border-radius: 6px;

                position: absolute;
                inset: 0;                
            }
        }

        .data{
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5;           
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $bienal-rosa;
            margin-bottom: 8px;
        }
        .video-title{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #FFFFFF;

            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
            overflow: hidden;
            text-overflow: ellipsis;

            height: 3.5625rem;
            

          
        }
    }
}


#contato-footer{

    background: $bienal-azul;
    
    scroll-margin-top: 130px;
    position: relative;


    
 
    @include d(md2){
        
        padding-bottom: 0;
    }

    .contato-grid-wide{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: start;
        align-items: center;
        padding-bottom: 1.5rem;
        position: unset;
        padding-top: 32px;
        align-items: start;
        @include d(md2){
             padding-bottom: 0 ;
            grid-template-columns: 1fr ;

        }
        @media(max-width:1450px){
            grid-template-columns: 1fr ;

        }
    }

    .col-1{
        position: relative;
        align-self: stretch;
        margin: 0 auto;


       .contato-wraper{
            max-width: 648px;
            margin: 0 auto;
            width: 100%;
            #hbspt-form-ac112768-8fc5-4825-aea0-ef1aa95442f7 .hs-form-6924d1d1-6bd5-4ec3-b71c-f3f6c3dac6a4_2244d383-1604-4f10-ad7e-fb0c3a06b8a1 legend.hs-field-desc{
                display: none !important;
            }
            @media(max-width:1450px){
                margin: unset;
                margin-bottom: 30px;
            }
            
       }
        .badge-wrapper {
            color: #000;
            background-color: #f2a900;
            width: max-content;
            margin-bottom: 10px;
            padding: 8px;
            position: relative;
            transform: rotate(-2deg);
        }


    }

    .col-2{

        .badge-wrapper {
            background-color: #f2a900;
            width: max-content;
            margin-bottom: 10px;
            padding: 8px;
            position: relative;
            transform: rotate(-2deg);
            color:  #1E1A34;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 31.2px */
            text-transform: uppercase;
            padding-bottom: 12px;
        }

        .email-contato-wrapper{
            padding-top: 16px;
            position: relative;
            z-index: 4;
        }

        .links-wrapper{
            margin-bottom: 20px;
            p{
                transform: rotate(-2deg);
                padding: 0px 8px;
                background-color: #F3D5AE;
                margin-bottom: 8px;
                max-width: fit-content;
                
            }
            a{
                color:  #1E1A34;
                font-family: Antonio;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%; /* 20.8px */
                text-transform: uppercase;
            }

            .grid-links{
                display: grid;
                gap: 9px;
            }
        }
        @media(max-width:1450px){
            margin-left: 20px;
        }
    }

        .orna{
            position: absolute;
            z-index: 2;

        

            &-1{
                top: 9%;
                right: -2rem;
                @media (max-width: 1400px){
                    max-width: 60px;
    
                }

                @include d(md2){
                    top: -24px;
                    left: 3.875rem;
                    max-width: 47px;
                }

            }
            &-2{
                left: 160px;
                bottom: 119px;
                @media (max-width: 1400px){
                    left: 120px;
                    bottom: 89px;
    
                }
                @include d(md2){
                    left: 100px;
                    bottom: 74px;
                }
            }
            &-3{
                bottom: 0;
                left: 0;

                @media (max-width: 1400px){
               
                    max-width: 120px;
                    
                }
                @include d(md2){
                    max-width: 100px;
                }

            }
            &-4{
                bottom: -40px;
                right: 20%;

                @media (max-width: 1400px){
                    max-width: 120px;
    
                }
                @include d(md2){
                    display: none;
                }

            }

        }
        .img-wrapper{
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        
        
    

    
        // padding-top: 7.5rem;
        // padding-left: 10rem;
        // padding-bottom: 0rem;
        // padding-right:  10rem;
        
        
            .menina-conato{
                position: absolute;
                right: 14%;
                bottom: 28%;
                z-index: 1;
                @include d(sm){
                    display: none;
               }
               @media (max-width: 945px){
                display: none;
            }
            }
            .detalhes-contato{
                position: absolute;
                right: 0;
                bottom: 34%;

            }
            .folha-contato{
                position: absolute;
                right: 9.5%;
                bottom: 48%;
                z-index: 1;
                @media (max-width: 945px){
                    display: none;
                }

                              
            }
            .nuvem-contato{
                position: absolute;
                right: 35%;
                bottom: 66.5%;
                z-index: 1;
                @media (max-width: 945px){
                    display: none;
                }
              
            }
            .bg-contato{
                position: absolute;
                right: 0px;
                bottom: -5px;
                @media (max-width: 945px){
                    display: none;
                }
              
            }
            
            
        

        

        @media (max-width: 1600px){
            
            padding-left: 6rem;
            padding-top: 5rem;
        }
        @include d(md){
            padding-right:  3rem;
            padding-left: 3rem;
        }
        @include d(md2){
            grid-row: 1;
            padding-right:  1rem;
            padding-left: 1rem;
            padding-bottom: 4.5rem;
        }

        .contato-girl-orna{
         
            position: absolute;
            right: 7.5rem;
            bottom: -5rem;

            @media (max-width: 1760px){
                right: 0;
            }
            @include d(md){
                display: none;
               
            }
        }
        
        .limit-width{
            max-width: 635px;
            padding-right: 50px;
            position: relative;
            z-index: 1;

            @include d(md){
                padding-right: 0;
            }
            @include d(md2){
                max-width: 540px;
                margin: 0 auto;
            }
        }

       
    


    .contato-wrapper{
        margin-bottom: 3.5rem;

        .label-texto{
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 144%;
            /* identical to box height, or 144% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $bienal-rosa;
            margin-bottom: .75rem;
        }

        .contato-texto{
            font-weight: 800;
            font-size: 3rem;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #000000;

            margin-bottom: 1rem;
            @media (max-width: 1360px){
                font-size: 2.3rem;
            }
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 2.2rem;
            }
        }

        .contato-descricao{ 
            color:  #5E5C66;

            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        } 

      
    }

    .form-wrapper{
        max-width: 100% ;
        .badge-for-ornamento-contato{
            margin-top: 32px;
        }
        .email-contato-wrapper{
            margin-top: 2.25rem;
            p{
                color:  rgba(0, 0, 0, 0.70);
                font-family: Antonio;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%; /* 20.8px */
                text-transform: uppercase;
                margin-bottom: 1rem;
                .link{
                    color:  #1E1A34 !important;
                    
                }
            }
            .link{
                color:  #1E1A34 !important;
                
            }
        }
        .button-wrapper{
            transition: 0.2s ease-in;
            // &:hover{
            //     // transform: scale(1.02);
               
            // }
            // .button{
            //     background-color: $bienal-amarelo;

            //     display: flex;
            //     align-items: center;
            //     gap: 8px;
            //     padding: 13px 16px;
            //     font-weight: 500;
            //     font-size: 16px;
            //     line-height: 1.5;
            //     /* identical to box height, or 157% */

            //     letter-spacing: -0.02em;
            //     text-transform: uppercase;

            //     /* Black */

            //     color: #000000;
            //     @include d(md2){
            //         width: 100%;
            //         justify-content: center;
            //     }
            // }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
            
        }
        .button-wrapper{

            // box-shadow: 6px 6px #1d336f !important;
            button{
                @include BotaoMain(#fff);
        
                // @include BotaoTeste(red, blue);
                // 
                
            }
            
        }
        
    }
    .info-contato-container{
        margin-top: 2rem;
        .block-wrapper-contato{
            margin-bottom: 1.125rem;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .block-title{
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */            
                letter-spacing: -0.02em;
                text-transform: uppercase;           
                /* Primary/Fucsia */            
                color: #A3238E;
    
                
                
              
                
            }
    
            .block-info{
                display: block;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.4;
                
                /* identical to box height, or 144% */
    
                letter-spacing: -0.02em;
    
                /* Neutral/70 */
    
                color: #36363B;
            }
        }
    }

}


@media(max-width:1650px){
    #contato-footer .contato-grid-wide{
         
        
        .folha-contato{
            img{
                width: 80%;
            }
        }
        .detalhes-contato{
            right: -160px;
            img{
                width: 80%;
            }
        }
        .nuvem-contato{
            img{
                width: 80%;
            }
        }
        .menina-conato{
            img{
                width: 80%;
            }
        }
         .bg-contato{
            right: -160px;
              img{
                  width: 80%;
              }
         }
        
    }
}
// @media(max-width:1450px){
//     #contato-footer .contato-grid-wide{
         
        
//         .folha-contato{
//             img{
//                 width: 60%;
//             }
//         }
//         .detalhes-contato{
//             img{
//                 width: 60%;
//             }
//         }
//         .nuvem-contato{
//             right: 30%;
//             img{
//                 width: 60%;
//             }
//         }
//         .menina-conato{
//             right: 0;
//             img{
//                 width: 60%;
//             }
//         }
//         // .bg-contato{
//         //     // img{
//         //     //     width: 80%;
//         //     // }
//         // }
        
//     }
// }

// @media(max-width:1180px){
//     #contato-footer .contato-grid-wide{
         
        
//         .folha-contato{
//             right: -20px;
//             img{
//                 width: 60%;
//             }
//         }
//         .detalhes-contato{
//             img{
//                 width: 60%;
//             }
//         }
//         .nuvem-contato{
//             right: 20%;
//             img{
//                 width: 60%;
//             }
//         }
//         .menina-conato{
//             right: -150px;
//             img{
//                 width: 50%;
//             }
//         }
//         // .bg-contato{
//         //     // img{
//         //     //     width: 80%;
//         //     // }
//         // }
        
//     }
// }

// @media(max-width:1040px){
//     #contato-footer .contato-grid-wide{
         
        
//         .folha-contato{
//             right: -20px;
//             top: 20px;
//             img{
//                 width: 60%;
//             }
//         }
//         .detalhes-contato{
//             img{
//                 width: 60%;
//             }
//         }
//         .nuvem-contato{
//             right: unset;
//             top: 20px;
//             img{
//                 width: 60%;
//             }
//         }
//         .menina-conato{
//             display: none;
//             right: -150px;
//             top: 20px;
//             img{
//                 width: 50%;
//             }
//         }
//         .bg-contato{
//             display: none;
//         }
        
//     }
// }


// @media (max-width: 1400px){

//     #contato-footer .col-2 .img-funto-contato .menina-conato   { 
        
        
//     }
//     #contato-footer .col-2 .img-funto-contato .folha-contato{

//     }
//     #contato-footer .col-2 .img-funto-contato .detalhes-contato{


//     }
//     #contato-footer .col-2 .img-funto-contato .detalhes-contato{

        
//     }
    
// }

// animation: rotacao 8s linear infinite alternate;
// @keyframes rotacao {
//     from {
//       transform: rotateZ(22deg); /* Inicia a rotação em 22 graus */
//       transform: translateX(0);
//     }
//     to {
//       transform: rotateZ(-22deg); /* Termina a rotação em 338 graus */
//       transform: translateX(-50px);

//     }
//   }

#numeros{
    // background: #FEF3E9;
    // border-top: 1px solid  rgba(15, 15, 18, 0.4);;
    // border-bottom: 1px solid  rgba(15, 15, 18, 0.4);;
    margin-bottom: -55px;

    // margin-bottom: 1rem;

    .swiper-wrapper{
        transition-timing-function: linear;
    }
    .swiper-slide{
        // max-width: max-content;
        border-radius: 8px;
        border: 1px solid var(--ds-generics-white-20-op, rgba(255, 255, 255, 0.20));
        background: var(--Generics-White, #FFF);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);
        height: 100%;
    }
    .numero-item{
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border-right: 1px solid  rgba(15, 15, 18, 0.4);
        position: relative;
        height: 100%;
        @include d(md2){
            padding: 2rem 1.5rem;
        }
        .badge-cor::after{
            position: absolute;
            content: "";
            height: 48px;
            width: 4px;
            display: block;
            background-color: currentColor;
            top: 50%;
            transform: translateY(-50%);
            left: -1px;
            
        }
        
        .badge-cor{
            text-align: center;
            width: 100%;
            font-family: Antonio;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 62.4px */
            letter-spacing: 0.96px;
            text-transform: uppercase;
            border-right: 1px solid #1E1A34;
            @include d(md2){
                font-size: 2rem;  
            }

            &-1{
                color: $bienal-vermelha;
                
                
            }
            
            &-2{
                color: rgba(0, 217, 128, 1);
                
            }
            &-3{
                color: rgba(74, 68, 106, 1);
                
            }
            &-4{
                color: rgba(250, 70, 22, 1);
                
            }
            
        }

        p{
            color:  #1E1A34;
                text-align: center;
                font-family: Antonio;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%; /* 20.8px */
                text-transform: uppercase;
                width: 100%;
       
            @include d(md2){
                font-size: 1.5rem;
            }
        }
    }
}


