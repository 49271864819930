#modal-atracoes {
  position: fixed;
  z-index: 9999;
  background-color: #4A446A;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 912px;
  max-width: 100%;

  // max-height: 629px;
  max-height: 529px;
 height: 100%;
  border-radius: 6px;
  overflow: hidden;

  @media (min-width: 1024px){
    // max-height: 98%;
  }

  
  @include d(md2){
    max-height: 100%
  }

  &.closed {
    display: none;
  }

  .gato-modal{
    position: absolute;
    bottom: 0;
    // right: 50px;
    right: 7px;
    @include d(md2){
        display: none;
    }

    img{
        max-width: 70px;
    }
  }

  .modal-header {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;

    // background-image: url("../assets/imagens/atracoes/header-modal-bg.webp");
    background-size: cover;
    margin-top: -1px;
    border-radius: 3px 3px 0 0;
  }
  #close-button {
    margin-right: 15px;
    margin-top: 10px;
    display: grid;
    place-content: center;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #1e1a34;
    border-radius: 32px;
    align-items: center;
    padding: 8px;
    transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
    display: block;
    box-shadow: -3px 3px #f2a900;
    svg{
      width: 24px;
      height: 24px;
      color: rgba(230, 0, 67, 1);
    }
  }
  #close-button:hover{
    transform: perspective(1px)translate3d(0, 2.5px, 0);
    box-shadow: 0 0 #f2a900;
  }

  .modal-guts {
    /* other stuff we already covered */

    /* cover the modal */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* spacing as needed */
    // padding: 45px 50px 20px 0;

    /* let it scroll */
    overflow: auto;
  }
}

#modal-overlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  &.closed {
    display: none;
  }
}


// STYLE 
#modal-atracoes{

    .header-wrapper-modal{
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        @include d(md2){
            flex-direction: column-reverse;
        }



    }

    
    .atracao-img-modal{
        position: relative;
        max-width: 288px;
        width: 100%;
        max-height: 316px;
        height: 100%;

        flex-shrink: 0;


        @include d(md2){
            display: none;
        }
        &-mobile{
            display: none;

            @include d(md2){
                display: block;
                margin-top: 1.5rem;
            }
        }

        @include d(md){
            max-width: 260px;

            
        }
    }
    .card-evento-img{

        img{
            border-radius: 3px;
            width: 100%;
            min-height: 250px;
            object-fit: cover;
            max-width: 288px;
            width: 100%;
            max-height: 316px;
            height: 316px;
        }
    }

    .content{
        background-color: #F2A900;
        max-width: calc(100% - 1.25rem);
        padding:  1rem 1.5rem;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include d(lg){
            padding: 10px 12px;
        }


        .icon-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            background-color: #D6DF21;
            padding: .375rem;
            border-radius: 0 0 0 6px;
            svg{
                width: 1.25rem;
                height: 1.3125rem;
            }
        }

        .card-evento-title{
      
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0F0F12;


            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @include d(lg){
                font-size: 1.3rem;
            }


        }
        .card-evento-role{
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.4;
         

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(0, 0, 0, 0.6);;
            
            @include d(lg){
                font-size: 1rem;
            }
        }
    }
    
   


    .infos-tragetoria{
        padding-top: 2.5rem;
        padding-right: 1.5rem;

        @include d(md2){
            padding-top: 2rem;
           
        }

        h2{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.33;
            /* identical to box height, or 133% */
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        .paragraphs-content{
           
            p{
                margin-bottom: 1rem;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.70);
                font-family: "Noto Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
            }
        }
    }

    .modal-guts{
        @include d(md2){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    .programacao{

        
        padding-bottom: 0;
        padding-right: 0;

        @include d(md2){
            padding-left:0;
        }
        .title-programacao{
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.3;
            text-transform: uppercase;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            background-color: #B3B0C4;
            text-align: center;
            padding: 7px 0;
            margin-bottom: 1.5rem;
            margin-top: 2rem;
            color: rgba(30, 26, 52, 1);
        } 

        
        .wrapper-programacao{
            display: flex;
            align-items: flex-start;
            
            margin-top: 1rem;
            margin: 16px 40;
            border: 1px solid  rgba(255, 255, 255, 0.20);
            align-items: center;

            @include d(md2){
                flex-direction: column;
                gap: 1rem;
            }

            .section-title{
              padding: 16px;
              border-right: 1px solid  rgba(255, 255, 255, 0.20);

            }
            .nome-evento-wrapper{
              padding-left: 24px;
            }
            
        }

        .confirmado{
          padding: 2rem 3rem;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #D6DF21;
          margin-bottom: 8px;
          display: none;
      }

        .nome-evento-wrapper{

            
            .nome-evento{
                font-weight: 800;
                font-size: 1.5rem;
                line-height: 1.33;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;
            }
        }

    }
}




/* Webkit based browsers (Chrome, Safari) */
.scroll-box::-webkit-scrollbar {
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #4A446A;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #6E6988;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Firefox */
.scroll-box {
  scrollbar-width: thin;
  scrollbar-color: #6E6988 #4A446A;
}

.scroll-box::-moz-scrollbar {
  width: 8px;
}

.scroll-box::-moz-scrollbar-track {
  background-color: #B54FA5;
}

.scroll-box::-moz-scrollbar-thumb {
  background-color: #56C5D0;
}

.scroll-box::-moz-scrollbar-thumb:hover {
  background-color: #555;
}


@media(max-width:770px){
 .programacao .wrapper-programacao {
  padding-bottom:16px ;

    .section-title{
      border-right: unset !important;
    }

  }
  .atracao-img-modal{
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}