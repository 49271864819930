&{

    @media (min-width:1024px){
        overflow-x:initial;

        main{
            overflow: initial;
        }
    }
}

#programacao-rolling{

    .swiper-infinite-programacao{
        .swiper-wrapper{
            transition-timing-function: linear;
        }
        .swiper-slide{
            width: max-content;
            max-width: 100%;
        }
    }
    .item-infinite{
        display: flex;
        align-items: center;
      
        h2{
            font-weight: 700;
            font-size: 6rem;
            line-height: 1.2;
            /* identical to box height, or 108% */
            letter-spacing: -0.03em;
            text-transform: uppercase;
            /* Neutral/30 */
            color: #E3E3E6;

            @include d(lg){
                font-size: 5rem;
            }
            @include d(md){
                font-size: 4rem;
            }
        }

        .dot-right{
            width: 1rem;
            height: 1rem;
            background-color: #E3E3E6;;
            border-radius: 50%;
            margin-left: 64px;
            @include d(md){
                margin-left: 40px;
            }
        }
    }

}

#base-banner .detail-bottom{
    background-color: #A3238E;
}
 


#programacao{


    .grid-programacao{
        display: grid;
        grid-template-columns: 23.4375rem 1fr;
        align-items: flex-start;
        gap: 2rem;
        @include d(lg){
            grid-template-columns: 19rem 1fr;
        }
        @include d(md){
            grid-template-columns: 1fr;
        }
       
    }

    .div-to-sticky{
        position: sticky;
        top: 140px;


        .img-menina{
            // position: absolute;
            // bottom: 0;
            margin-top: 100px;
            @include d(md){
                display: none;
            }
        }
        .filtro-title{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: -0.32px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .accordion {
            display: block;
            margin-bottom: 10px;
          }
        
          .accordion-header {
            background-color: #f4f4f4;
            padding: 10px;
            cursor: pointer;
          }
        
          .accordion-content {
            display: none;
            padding: 10px;
          }

        .espacos{
            display: grid;
            border-radius: 3px;
            border: 1px solid var(--l-neutral-10-op, rgba(1, 7, 27, 0.10));
            background: var(--generics-white, #FFF);
            box-shadow: 0px 11px 15px 0px rgba(1, 7, 27, 0.08),
             0px 9px 46px 0px rgba(0, 0, 0, 0.04),
             0px 24px 38px 0px rgba(0, 0, 0, 0.02);
            margin-bottom: 15px;
            transform: translateY(-20px);
            display: none;
            
            .espaco-item{
                padding: 9px;
                border-radius: 3px;
                border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
                background: var(--white, #FFF);
                margin:8px 12px 8px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: 0.3s ease-in-out;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                letter-spacing: -0.32px;
                text-transform: uppercase;
                cursor: pointer;
                svg{
                    width: 24px;
                    height: 24px;
                }
                &:hover{
                    transition: 0.3s ease-in-out;
                   
                    transform: scale(1.01);
                }
            }
        }
        .espacos.open{
            display: block;
            
        }

        .custom-select {
            position: relative;
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            padding: 12px 16px;
            cursor: pointer;
            margin-bottom: 1rem;
            background: #fff;
            
        }
        
          /* Estilo da opção selecionada */
        .custom-select select {
            display: none;
        }
        
          /* Estilo do rótulo da opção selecionada */
        .select-selected {
            display: flex;
            justify-content: space-between;
            
            
            cursor: pointer;
            
            .svg-pesquisa{
                width: 32px;
                height: 32px;
                transform: rotate(180deg);
                transition: 0.2s ease-in-out;
            }
        }
        
          /* Estilo das opções do dropdown */
        .select-items {
            display: none;
            position: absolute;
            left: 0;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            max-height: 150px;
            overflow-y: auto;
            z-index: 1;
            border-radius: 3px;
            border: 1px solid var(--l-neutral-10-op, rgba(1, 7, 27, 0.10));
            background: var(--generics-white, #FFF);
            box-shadow: 0px 11px 15px 0px rgba(1, 7, 27, 0.08), 0px 9px 46px 0px rgba(0, 0, 0, 0.04), 0px 24px 38px 0px rgba(0, 0, 0, 0.02);
            }
        
        .select-items div {
            margin-top: 8px;
            width: 375px;
            padding: 8px 10px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
            background: var(--white, #FFF);
        }
        
          /* Estilo das opções do dropdown quando passa o mouse */
        .select-items div:hover {
            background-color: #ddd;
        }

        //select estilo
        
    }

    .filtrar-por-data{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 12px 16px;
        cursor: pointer;
        display: none;
        margin-bottom: 1rem;
        background: #fff;

        @include d(md){
            display: block;
        }

        .flex-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 1.2;
            /* identical to box height, or 122% */

            letter-spacing: -0.02em;

            /* Neutral/40% OP */

            color: rgba(15, 15, 18, 0.4);
        }
        svg{
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            
           
        }
         .svg-pesquisa.active{
             transform: rotate(0deg);
             transition: 0.2s ease-in-out;

         }
    }

    .filtrar-por-espaco{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 12px 16px;
        cursor: pointer;
        
        margin-bottom: 1rem;
        background: #fff;

        @include d(md){
            display: block;
        }

        .flex-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 1.2;
            /* identical to box height, or 122% */

            letter-spacing: -0.02em;

            /* Neutral/40% OP */

            color: rgba(15, 15, 18, 0.4);
        }
        .svg-pesquisa{
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
           
        }
         .svg-pesquisa.active{
             transform: rotate(0deg);
             transition: 0.2s ease-in-out;

         }
    }


    .info-download-wrapper{
        margin-top: 2rem;
         display: none;
        p{
            color:  #5E5C66;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 27px */
            margin-bottom: 16px
        }
        .button{

            @include BotaoMain(#D6DF21);
            border-radius: 32px;
            border: 1px solid  #1E1A34 !important;
            background:  #FFF !important;
            

            svg{
                width: 20px;
                height: 20px;
            }
        }
    }

    .sidebar{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 1rem;
        background-color: #fff;

        @include d(md){
            overflow: hidden;
            max-height: 0;
            
            border-width: 0;
            padding: 0;
            transition: 0.2s ease-in;
            &.open{

                max-height: 1000px;
                      
                border-width: 1;
            }
        }
    
    
        .dias-wrapper{
    
    
    
            li{
                padding: 12px;
                cursor: pointer;
                .dia-semana{
                    color:  rgba(0, 0, 0, 0.70);   
                    font-family: Antonio;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%; /* 20.8px */
                    text-transform: uppercase;
                }
                .dia-data{
                    color:  #1E1A34;
                    font-family: Antonio;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%; /* 31.2px */
                    text-transform: uppercase;
    
    
                }
                &.active{
                    background-color: #99D6EA;
                    border:  4px solid #FFCD29;
                    padding: 12px;
                    color: #fff;
                    
                    // .dia-semana{
                    //     color: #D6DF21;
                    //     font-weight: 700;
                    // }
                    // .dia-data{
                    //     color: #FFFFFF;
                    // }
                }
             
            }
        }
       
    }

    .programacoes{
        display: grid;
        
        width: 100%;
        max-width: 1050px;
        margin-left: auto;
    }
    .programacao{
        margin-bottom: 2rem;
    }
    .horario-wrapper{
        background: #FFFFFF;
      
        border: 1px solid  #E60043;
        padding: 1.5rem;
        margin-bottom: 2rem;

        display: flex;
        align-items: center;
        @include d(md){
            padding: 1rem;
        }
        span{
            color:  #1E1A34;
            font-size: 24px;
            margin: 0 5px;
        }

        p{
            color:  #1E1A34;
            font-family: Antonio;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; 
            
        }
        .local{
            text-transform: none;
        }
        .horario-h{
            text-transform: none;
            margin-right: 0;
        }
    }
    .swiper-programacao-atracoes{
        display: grid;
        width: 100%;
        
    }

    .swiper-controls-header{



        .mobile-swiper-icon{
            width: 24px;
            height: 24px;
            display: none;
            @include d(md2){
                display: block;
            }
        }
        .buttons{
            display: flex;
            gap: 4px;
            align-items: center;
            .button{
                background-color: $bienal-amarelo;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                display: grid;
                place-content: center;

                @include d(md2){
                    display: none;
                }
                svg{
                    path{

                        stroke: #000
                    }
                    
                }
                &.swiper-button-disabled{
                    background-color: transparent;
                    border: 1px solid white;
                    svg{
                        path{

                            stroke: #fff
                        }
                        
                    }
                }
            }

            .swiper-button-lock{
                display: none;
            }
        }

    }

    .dia-atracoes-wrapper{
        padding: 1.5rem;
        display: grid;
        align-items: flex-start;
        width: 100%;
        background-color: #4A446A;
        color: #fff;
        border-radius: 3px;
        
        

        .jornada-wrapper{
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
        .jornada-title{
            color:  #FFF;
            font-family: Antonio;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 41.6px */
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .content-for-p{

            p:not(.continuar-lendo) {
                color:  rgba(255, 255, 255, 0.70);
            font-family: "Noto Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 27px */
                @include d(md){
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.open{
                p:not(.continuar-lendo){
                    display: block;
                }
            }
          

          
        }

        .continuar-lendo{
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5%;
            /* identical to box height, or 150% */
            cursor: pointer;
            
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Primary/Verde */

            color: #D6DF21;
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: 1rem;

            display: none;
            @include d(md){
                display: flex;
            }
        }
    }

    .header-atracoes {
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 11px;

        .header-atracoes-titulo{
            color:  #FFF;
            font-family: Antonio;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 20.8px */
            text-transform: uppercase;

            
        }
    }

    .card-programacao{
        cursor: pointer;
        
        .card-evento-img{
            position: relative;
            padding-top: 100%;
            overflow: hidden;
            img{
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 3px;
            }
        }
        .card-evento-title{
            color:  #FFF;
            font-family: Antonio;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 26px */
            text-transform: uppercase;
        }
        .card-evento-role{
            color:  rgba(255, 255, 255, 0.60);
            font-family: Antonio;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 20.8px */
            text-transform: uppercase;
        }
    }
}