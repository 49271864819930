body {
  --white: #fff;
  --white-70-op: #ffffffb3;
  --white-10-op: #ffffff1a;
  --black: #000;
  --neutral-100: #090a1a;
  --neutral-70: #464651;
  --neutral-60: #666670;
  --neutral-50: #090a1a80;
  --neutral-40: #090a1a66;
  --neutral-30: #e5e6eb;
  --neutral-20: #f0f1f5;
  --neutral-10: #f5f6fa;
  --primary-light: #8186f7;
  --primary-light-10-op: #8288f71a;
  --primary-light-30: #a8abfe;
  --primary-pure-10: #eaeaf8;
  --primary-pure: hsla(var(--hue-primary), 64%, 54%, 1);
  --primary-pure-70: #6a6ed1;
  --hue-primary: 238;
  --alert-error: #e92c2c;
  --alert-error-10: #fdeaea;
  --alert-warning: #d2af00;
  --alert-warning-10: #fbf7e6;
  --alert-sucess: #008024;
  --alert-sucess-10: #e6f9f0;
  --bienal-amarelo: #f2a900;
  --bienal-rosa: #a3238e;
  --bienal-laranja: #f2a900;
  --bienal-vermelha: #e60043;
  --bienal-azul: #99d6ea;
}

@font-face {
  font-family: Extatica-new;
  font-weight: 400;
  src: url("Extatica-Regular.9792de73.otf") format("opentype");
}

@font-face {
  font-family: Extatica-new;
  font-weight: 500;
  src: url("Extatica-Medium.27ba0ee6.otf") format("opentype");
}

@font-face {
  font-family: Extatica-new;
  font-weight: 600;
  src: url("Extatica-SemiBold.2ee573b7.otf") format("opentype");
}

@font-face {
  font-family: Extatica-new;
  font-weight: 700;
  src: url("Extatica-Bold.7b266732.otf") format("opentype");
}

@font-face {
  font-family: Extatica-new;
  font-weight: 800;
  src: url("Extatica-ExtraBold.56650714.otf") format("opentype");
}

@font-face {
  font-family: sweetsans;
  font-style: normal;
  src: url("SweetSansPro-Bold.8192df3d.woff") format("woff");
  font-weight: 700;
}

body, p, h1, h2, ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ol {
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

:after, :before {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

button {
  background: initial;
  border: initial;
  -webkit-appearance: none;
  font-family: Antonio, sans-serif;
}

button:active {
  border-style: initial;
}

button:focus {
  outline: initial;
}

button[type="button"], button[type="submit"], button[type="reset"] {
  -webkit-appearance: none;
}

button[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

img {
  object-fit: contain;
  max-width: 100%;
  display: block;
}

fieldset {
  -webkit-margin-start: 0px;
  -moz-margin-start: 0px;
  -webkit-margin-end: 0px;
  -moz-margin-end: 0px;
  border: 2px #0000;
  border-image: initial;
  min-inline-size: min-content;
  margin-inline: 0;
}

figure {
  margin-block: 0;
  margin-inline: 0;
  display: block;
}

:focus {
  outline: 0 #0000 !important;
}

input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

input, [type="button"], select {
  -webkit-appearance: none;
}

:root {
  --xl-device: 1920px;
  --lg-device: 1440px;
  --dt-device: 1200px --md-device: 1024px;
  --md2-device: 768px;
  --sm-device: 600px;
}

.seta-btn {
  width: 24px !important;
  height: 24px !important;
}

.button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #fff;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

@keyframes volta_menu {
  to {
    -webkit-clip-path: circle(0% at 80% 0);
    clip-path: circle(0% at 80% 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes opacidade_1 {
  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

body {
  opacity: 0;
  background-image: url("grafismo-body.f7af1409.svg");
  background-repeat: repeat;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  overflow-x: hidden;
}

body.dcl {
  opacity: 1;
}

body a {
  font-family: Antonio, sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: calc(1600px + 3rem);
  }
}

html {
  --font-base: 100%;
  font-size: var(--font-base);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@media (max-width: 1440px) {
  html {
    font-size: calc(var(--font-base)  - 9.25%);
  }
}

@media (max-width: 980px) {
  html {
    font-size: calc(var(--font-base)  - 12.5%);
  }
}

.menu-superior-info {
  text-align: center;
  background-color: #56c5d0;
  padding: 12px 0;
}

@media (max-width: 1600px) {
  .menu-superior-info {
    padding: 8px 0;
  }
}

.menu-superior-info p {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  font-size: .875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .menu-superior-info p {
    white-space: nowrap;
    min-width: max-content;
    animation: 10s linear infinite scroll-left;
    overflow: hidden;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

#logo {
  z-index: 3;
  height: 88px;
  transition: all .3s ease-in-out;
  position: relative;
}

#logo img {
  height: 100%;
}

@media screen and (max-width: 768px) {
  #logo img {
    max-width: 260px;
  }
}

#header {
  z-index: 999;
  background: #fff;
  transition: background-color .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#header .container--menu {
  max-width: calc(1728px + 2rem);
  margin: 0 auto;
  padding: 0 1rem;
}

#header .header-wrapper {
  padding: .75rem 0;
  transition: all .3s;
}

@media (min-width: 1024px) {
  #header .header-wrapper #nav {
    width: 100%;
    margin-right: 75px;
  }
}

.header-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header-container {
    grid-template-columns: auto auto;
    display: grid;
  }
}

#header .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  border: 1px solid var(--primary-fucsia, #1e1a34);
  background: none;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

#header .button-wrapper a span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

#header .button-wrapper a:active, #header .button-wrapper a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

#header .button-wrapper a:active span, #header .button-wrapper a:hover span {
  transform: translateZ(0)perspective(1px);
}

#header .button-wrapper a svg, #header .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

#header .button-wrapper a .seta-btn {
  width: 24px !important;
  height: 24px !important;
}

#header .button-wrapper .button.yellow {
  background: #f2a900;
}

#menu {
  z-index: 3;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  #menu {
    text-align: left;
  }
}

#menu li + li {
  margin-left: 1rem;
}

@media screen and (max-width: 1440px) {
  #menu li + li {
    margin-left: 10px;
  }
}

#menu .button-wrapper.button-mobile {
  display: none;
}

#menu .button {
  margin-left: 2rem;
}

@media screen and (max-width: 1024px) {
  #menu .button {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #menu .button {
    width: 100%;
  }
}

#menu .button svg {
  width: 25px;
  height: 26px;
}

#menu .button:hover {
  transform: scale(1.05);
}

#menu a.link {
  color: #0f0f12;
  letter-spacing: -.02em;
  text-transform: uppercase;
  padding: .5rem;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  transition: transform .2s;
  display: block;
}

#menu a.link:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
  #menu a.link:hover {
    transform: scale(1);
  }
}

#btn-mobile {
  z-index: 4;
  display: none;
  position: relative;
}

.social-media-only-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .social-media-only-mobile {
    margin-top: 2rem;
    display: block;
  }

  .social-media-only-mobile .nossas-redes {
    text-align: center;
    letter-spacing: -.02em;
    text-transform: uppercase;
    color: #ffffffb3;
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  .social-media-only-mobile ul {
    justify-content: center;
    gap: 18px;
    padding-bottom: 5rem;
    display: flex;
  }

  .social-media-only-mobile ul a {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #1e1a34;
    border-radius: 32px;
    align-items: center;
    padding: 8px;
    transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
    display: block;
    box-shadow: -3px 3px #f2a900;
  }

  .social-media-only-mobile ul svg {
    color: #f2a900;
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .social-media-only-mobile ul svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  #header .header-wrapper .button-wrapper {
    display: none;
  }

  #menu .button-wrapper.button-mobile {
    grid-template-columns: 1fr;
    place-content: center;
    padding: 0 1rem;
    display: grid;
  }

  #menu .button-wrapper.button-mobile a {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) and (max-width: 600px) {
  #menu .button-wrapper.button-mobile a {
    justify-content: center;
    max-width: 100%;
    box-shadow: 2.5px 2.5px #ef7d00;
  }
}

@media screen and (max-width: 1024px) {
  #menu .button-wrapper.button-mobile a.yellow {
    background: #f2a900;
  }

  #btn-mobile {
    display: block;
  }

  #menu {
    opacity: 0;
    z-index: 1000;
    width: 100%;
    transition: all .3s ease-in-out;
    display: block;
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    transform: translateX(-100%);
  }

  #nav.active #menu {
    opacity: 1;
    background-color: var(--bienal-vermelha);
    overflow-y: auto;
    transform: translateX(0);
  }

  #menu {
    padding-top: 3rem;
  }

  #menu:before {
    content: "Menu";
    letter-spacing: -.02em;
    text-transform: uppercase;
    color: #f2a900;
    margin: 0 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3;
    display: block;
  }

  #menu .menu-li a {
    letter-spacing: -.02em;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
  }

  #menu li + li {
    margin-left: 0;
  }

  #menu a.link {
    color: #fff;
    margin: 0 1rem;
    padding: 8px 0;
  }

  #btn-mobile {
    cursor: pointer;
    color: #000;
    background: none;
    background-color: var(--bienal-azul);
    border: none;
    border-radius: 3px;
    gap: .5rem;
    padding: 1rem;
    font-size: 1rem;
    display: flex;
    box-shadow: 2.5px 2.5px #f2a900;
  }

  #hamburger {
    border-top: 2px solid;
    width: 20px;
    display: block;
  }

  #hamburger:after, #hamburger:before {
    content: "";
    background-color: currentColor;
    width: 20px;
    height: 2px;
    margin-top: 5px;
    transition: all .3s;
    display: block;
    position: relative;
  }

  #nav.active #hamburger {
    color: #000;
    border-top-color: #0000;
  }

  #nav.active #hamburger:before {
    transform: rotate(135deg);
  }

  #nav.active #hamburger:after {
    top: -7px;
    transform: rotate(-135deg);
  }
}

body.menu--open #header .header-wrapper {
  background-color: #f2a900;
  position: relative;
  top: -34px;
}

body.menu--open #header .header-wrapper #logo {
  opacity: 0;
}

body.menu--open #header .header-wrapper #btn-mobile {
  box-shadow: none;
  background-color: #a11c64;
}

body.menu--open #header .header-wrapper #nav.active #hamburger {
  color: #f2a900;
}

#footer-bienal {
  background-color: #eeeef0;
}

#footer-bienal .sup-footer {
  background-color: var(--bienal-vermelha);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

#footer-bienal .grid-sup-footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

#footer-bienal .grid-sup-footer .logo-wrapper {
  padding: 24px 0;
}

@media screen and (max-width: 1440px) {
  #footer-bienal .grid-sup-footer .logo-wrapper {
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  #footer-bienal .grid-sup-footer .logo-wrapper {
    padding: 52px 0;
  }
}

#footer-bienal .grid-sup-footer .go-top {
  background-color: var(--bienal-amarelo);
  z-index: 2;
  border-radius: 0 0 3px 3px;
  padding: 8px 9px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

#footer-bienal .grid-sup-footer .go-top p {
  letter-spacing: -.02em;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

#footer-bienal .grid-sup-footer .go-top svg {
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  transform: rotate(-90deg);
}

#footer-bienal .grid-sup-footer .right-col {
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

#footer-bienal .grid-sup-footer .right-col .livro-wrapper {
  align-self: flex-end;
  margin-bottom: -22px;
}

@media screen and (max-width: 768px) {
  #footer-bienal .grid-sup-footer .right-col .livro-wrapper {
    max-width: 93px;
    margin-bottom: -10px;
    margin-right: auto;
  }
}

#footer-bienal .grid-sup-footer .socials {
  flex-wrap: wrap;
  justify-content: flex-end;
  justify-self: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

#footer-bienal .grid-sup-footer .socials h3 {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

#footer-bienal .grid-sup-footer .socials ul {
  justify-content: flex-end;
  gap: 9px;
  display: flex;
}

#footer-bienal .grid-sup-footer .socials ul a {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 32px;
  align-items: center;
  padding: 8px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: block;
  box-shadow: -3px 3px #f2a900;
}

#footer-bienal .grid-sup-footer .socials ul a img {
  width: 24px;
}

#footer-bienal .grid-sup-footer .socials ul a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

#footer-bienal .grid-sup-footer .socials ul svg {
  color: #d6df21;
  width: 3.5rem;
  height: 3.5rem;
}

@media screen and (max-width: 768px) {
  #footer-bienal .grid-sup-footer .socials ul svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

#footer-bienal .patrocinadores {
  background-color: #fff;
  padding: 73px 0;
}

#footer-bienal .patrocinadores .group-wrapper {
  max-width: 1527px;
  margin: 0 auto;
}

#footer-bienal .patrocinadores .group-wrapper .group .lei {
  margin-top: 46px;
}

#footer-bienal .patrocinadores .row-1 {
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 4rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  #footer-bienal .patrocinadores .row-1 {
    grid-template-columns: 1fr;
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  #footer-bienal .patrocinadores .row-1 {
    margin-bottom: 4rem;
  }
}

#footer-bienal .patrocinadores .row-1 .group-wrapper:first-child {
  flex-shrink: 0;
  align-self: flex-end;
}

#footer-bienal .patrocinadores .row-1 .group-wrapper:first-child img {
  margin: 0 auto;
}

#footer-bienal .patrocinadores .row-1 .img-wrapper img {
  transform: scale(.93);
}

#footer-bienal .patrocinadores .row-nova {
  margin-bottom: 4rem;
}

#footer-bienal .patrocinadores .row-2 {
  grid-template-columns: repeat(3, 1fr);
  gap: 4.5rem;
  margin-bottom: 4rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .patrocinadores .row-2 {
    grid-template-columns: 1fr;
    display: grid;
  }
}

#footer-bienal .patrocinadores .row-2 .group-wrapper.apoio-group {
  flex: 0 0 20%;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .patrocinadores .row-2 .group-wrapper.apoio-group {
    margin: 0 auto;
  }
}

#footer-bienal .patrocinadores .row-2 .group-wrapper.hotel-group {
  flex: 0 0 20%;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .patrocinadores .row-2 .group-wrapper.hotel-group {
    margin: 0 auto;
  }
}

#footer-bienal .patrocinadores .row-2 .group-wrapper.realizacao-group {
  flex: 1;
  max-width: 1460px;
  margin: 0 auto;
}

#footer-bienal .patrocinadores .group-wrapper p {
  color: #1e1a34;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid var(--grey-200, #cecdd0);
  padding: 8px;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

#footer-bienal .patrocinadores .group-wrapper .group, #footer-bienal .patrocinadores .group-wrapper .group-1, #footer-bienal .patrocinadores .group-wrapper .group-2 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;
  display: flex;
}

@media screen and (max-width: 1440px) {
  #footer-bienal .patrocinadores .group-wrapper .group, #footer-bienal .patrocinadores .group-wrapper .group-1, #footer-bienal .patrocinadores .group-wrapper .group-2 {
    gap: 1.5rem;
  }
}

#footer-bienal .patrocinadores .group-wrapper .group-1 {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .patrocinadores .group-wrapper .group-1, #footer-bienal .patrocinadores .group-wrapper .group-2 {
    display: none;
  }
}

#footer-bienal .patrocinadores .group-wrapper .group-mobile {
  flex-wrap: wrap;
  place-content: center;
  gap: 1.5rem;
  display: flex;
}

@media (min-width: 1024px) {
  #footer-bienal .patrocinadores .group-wrapper .group-mobile {
    display: none;
  }
}

#footer-bienal .patrocinadores .apresenta-group .group {
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  padding: 0 5rem;
}

@media screen and (max-width: 1440px) {
  #footer-bienal .patrocinadores .apresenta-group .group {
    padding: 0 2rem;
  }
}

#footer-bienal .patrocinadores .group-wrapper.acao-social .img-wrapper img {
  margin: 0 auto;
}

#footer-bienal .patrocinadores .patrocinadores-group .group {
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  #footer-bienal .patrocinadores .patrocinadores-group .group {
    flex-wrap: wrap;
    place-content: center;
  }
}

@media screen and (max-width: 600px) {
  #footer-bienal .patrocinadores .realizacao-group .group {
    flex-wrap: wrap;
  }
}

@media (max-width: 1440px) {
  #footer-bienal .patrocinadores .realizacao-group img, #footer-bienal .patrocinadores .apoio-group img, #footer-bienal .patrocinadores .hotel-group img {
    transform-origin: top;
    transform: scale(.7);
  }
}

@media (max-width: 1200px) {
  #footer-bienal .patrocinadores .realizacao-group img, #footer-bienal .patrocinadores .apoio-group img, #footer-bienal .patrocinadores .hotel-group img {
    transform-origin: top;
    transform: scale(.8);
  }
}

#footer-bienal .acao-social p {
  padding: 0 20px;
}

#footer-bienal .bottom-footer {
  color: #fff;
  background: #1e1a34 0 0 / cover no-repeat;
  padding: 1.2rem 0;
}

#footer-bienal .bottom-footer .container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 5px 1.5rem;
  display: flex;
}

#footer-bienal .bottom-footer p {
  color: #fff;
  text-align: right;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

#footer-bienal .bottom-footer .ndt-wrapper {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  align-items: center;
  gap: 10px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  position: relative;
}

#footer-bienal .bottom-footer .ndt-wrapper img {
  padding-bottom: 7px;
}

#footer-bienal .bottom-footer .ndt-wrapper:after {
  content: "";
  background-color: #fff;
  width: 1.5px;
  height: 16px;
  display: block;
  position: absolute;
  right: 0;
}

#footer-bienal .bottom-footer .road-wrapper {
  padding-left: 16px;
}

#login {
  background: linear-gradient(90deg, hsla(var(--hue-primary), 100%, 3%, 1) 0%, hsla(var(--hue-primary), 100%, 3%, 1) 100%);
  height: 100vh;
  padding-top: 3.75rem;
  position: relative;
}

@media (max-width: 1660px) {
  #login {
    height: 120vh;
  }
}

@media screen and (max-width: 1024px) {
  #login {
    height: 100vh;
  }
}

@media screen and (max-width: 600px) {
  #login {
    padding: unset;
  }
}

@media (max-width: 400px) {
  #login {
    height: 120vh;
  }
}

#login .ornamento {
  background: linear-gradient(92.74deg, hsla(var(--hue-primary), 68%, 32%, 1) 0%, hsla(var(--hue-primary), 100%, 73%, 1) 100%);
  border-radius: 11.875rem;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  max-height: 394px;
  margin: 0 auto;
}

@media (max-width: 1660px) {
  #login .ornamento {
    width: 90%;
    padding: 3rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento {
    height: 40%;
  }
}

@media screen and (max-width: 600px) {
  #login .ornamento {
    margin: unset;
    border-radius: 0 0 16px 16px;
    width: 100%;
    height: 349px;
  }
}

#login .ornamento .logo-cliente {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding-top: 4.6875rem;
}

#login .ornamento .logo-cliente img {
  width: 100%;
  max-width: 100px;
}

@media (max-width: 1660px) {
  #login .ornamento .logo-cliente {
    padding-top: 2.75rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento .logo-cliente {
    padding-top: 2rem;
  }
}

#login .orn-sections {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#login .orn-sections img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#login .logo-ndt {
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1440px) {
  #login .logo-ndt {
    bottom: 64px;
  }
}

#login .form-wrapper {
  border: 1px solid var(--white-10-op);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background: #00000080;
  border-radius: 8px;
  width: 100%;
  max-width: 428px;
  height: -moz-fit-content;
  height: fit-content;
  margin: -210px auto 0;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  #login .form-wrapper {
    margin-top: -150px;
  }
}

@media screen and (max-width: 600px) {
  #login .form-wrapper {
    width: 327px;
  }
}

#login .form-wrapper .content .title {
  letter-spacing: -.02em;
  color: var(--white);
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#login .form-wrapper .content .description {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#login .form-wrapper .inputs-wrapper {
  margin-top: 2rem;
}

#login .form-wrapper .inputs-wrapper .input-item:first-child {
  margin-bottom: 1.5rem;
}

#login .form-wrapper .inputs-wrapper .input-item .input {
  border: unset;
  letter-spacing: -.02em;
  color: var(--white);
  background: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  height: 56px;
  padding: .5rem;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
  letter-spacing: -.02em;
  color: var(--white);
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .show-password {
  display: flex;
  position: relative;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password svg {
  width: 24px;
  height: 24px;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password .hidden {
  display: none;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .hidden {
  display: block;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .show {
  display: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha {
  margin-top: .5rem;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .75rem;
  font-weight: 300;
  line-height: 167%;
  text-decoration: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .btn-wrapper {
  margin-top: 3.5rem;
}

#login .form-wrapper .btn-wrapper .btn {
  letter-spacing: -.02em;
  background-color: var(--primary-light-10-op);
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
  cursor: pointer;
  border-radius: 1.75rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
  display: flex;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .btn-wrapper .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#login .form-wrapper .btn-wrapper .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--neutral-10);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#login .form-wrapper .voltar {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto 0;
}

#login .form-wrapper .voltar a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .875rem;
  font-weight: 300;
  line-height: 157%;
}

#login .form-wrapper .voltar a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .checkbox-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container {
  align-items: center;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: -.02em;
  color: var(--white-70-op);
  align-items: center;
  gap: .375rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 150%;
  display: inline-flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label .checkbox {
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover {
  color: var(--white);
  font-weight: 400;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover .radio-circle {
  border-color: var(--primary-pure);
  border-width: 2px;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle {
  border: 1px solid #fff3;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: relative;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle-inner {
  background: var(--primary-pure);
  transition: all .2s ease-out;
  transition-property: width height;
  border-radius: 50%;
  flex-shrink: 0;
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span {
  border-color: var(--primary-pure);
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span i {
  width: 12px;
  height: 12px;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle {
  border-radius: .25rem;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle-inner {
  transition: initial;
  border-radius: initial !important;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round input:checked ~ span i {
  border-radius: initial;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDJMNy42MjY4MSAxMkwyIDUuOTk2OTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled {
  -webkit-user-select: none;
  user-select: none;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .check-label {
  pointer-events: none;
  color: var(--neutral-60);
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .radio-circle {
  pointer-events: none;
  opacity: .2;
}

.grid-eventos {
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .grid-eventos {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid-eventos {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .grid-eventos {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card-evento:not(.card-programacao) {
  cursor: pointer;
  position: relative;
}

.card-evento:not(.card-programacao):hover img {
  transform-origin: bottom;
  transform: scale(1.08);
}

.card-evento:not(.card-programacao) .card-evento-img {
  height: 100%;
  overflow: hidden;
}

.card-evento:not(.card-programacao) .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  transition: transform .2s ease-in-out;
}

.card-evento:not(.card-programacao) .content {
  background-color: #f2a900;
  max-width: calc(100% - 1.25rem);
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content {
    padding: 10px 12px;
  }
}

.card-evento:not(.card-programacao) .content .icon-wrapper {
  background-color: #1e1a34;
  border-radius: 0 0 0 6px;
  padding: .375rem;
  position: absolute;
  top: 0;
  right: 0;
}

.card-evento:not(.card-programacao) .content .icon-wrapper svg {
  color: #fff;
  width: 1.25rem;
  height: 1.3125rem;
}

.card-evento:not(.card-programacao) .content .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content .card-evento-title {
    font-size: 1.3rem;
  }
}

.card-evento:not(.card-programacao) .content .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff9;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content .card-evento-role {
    font-size: 1rem;
  }
}

#base-banner .banner-img {
  object-fit: cover;
  width: 100%;
}

#base-banner .detail-bottom {
  z-index: 2;
  background: #d6df21;
  height: 16px;
  margin-top: -16px;
  position: relative;
}

#banner-patrocinio {
  background-color: #fa4616;
  padding: 4rem 0;
}

#banner-patrocinio .container {
  position: relative;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .container {
    padding-top: 96px;
  }
}

#banner-patrocinio .container-banner-patrocinio {
  max-width: 1792px !important;
}

#banner-patrocinio .balao-wrapper {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 1.5rem;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .balao-wrapper {
    max-width: 72px;
  }
}

#banner-patrocinio .robo-wrapper {
  z-index: 9;
  position: absolute;
  top: -48px;
  left: 0;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .robo-wrapper {
    width: 130px;
    top: 0;
    left: 1rem;
  }
}

#banner-patrocinio .origami {
  z-index: 2;
  position: absolute;
  bottom: -10px;
  right: 1.5rem;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .origami {
    width: 80px;
  }
}

#banner-patrocinio .alienigena-wrapper {
  z-index: 2;
  position: absolute;
  top: 55px;
  left: -11px;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .alienigena-wrapper {
    max-width: 106px;
  }
}

#banner-patrocinio .img-wrapper-banner {
  border: 8px solid #f2a900;
  border-radius: 6px;
  height: 100%;
}

#banner-patrocinio .img-wrapper-banner img {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

#banner-patrocinio .swiper-banner-patrocinadores .swiper-slide {
  height: auto;
}

#banner-patrocinio .swiper-pagination-bullet {
  opacity: 1;
  background-color: #bec5cc;
  width: .75rem;
  height: .75rem;
}

#banner-patrocinio .swiper-pagination-bullet-active {
  background: #868e96;
  border-radius: 8px;
  width: 24px;
}

.alienigena-wrapper, body:not(.body_home) .robo-wrapper, body:not(.body_home) .origami, .body_single_blog .robo-wrapper, .body_single_blog .origami, .body_single_blog .balao-wrapper {
  display: none;
}

.body_eventos .alienigena-wrapper, .body_quemsomos .alienigena-wrapper, .body_credenciamento .alienigena-wrapper {
  display: block;
}

.body_eventos .balao-wrapper, .body_quemsomos .balao-wrapper, .body_credenciamento .balao-wrapper, .body_home #banner-patrocinio .balao-wrapper {
  display: none;
}

.instagram-wrapper-plugin .instagram-gallery-medium {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.instagram-wrapper-plugin .instagram-gallery-medium > a {
  cursor: pointer;
  border-radius: 3px;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.instagram-wrapper-plugin .instagram-gallery-medium > a > video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.instagram-wrapper-plugin .instagram-gallery-medium video {
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
  inset: 0;
}

#modal-atracoes {
  z-index: 9999;
  color: #fff;
  background-color: #4a446a;
  border-radius: 6px;
  width: 912px;
  max-width: 100%;
  height: 100%;
  max-height: 529px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  #modal-atracoes {
    max-height: 100%;
  }
}

#modal-atracoes.closed {
  display: none;
}

#modal-atracoes .gato-modal {
  position: absolute;
  bottom: 0;
  right: 7px;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .gato-modal {
    display: none;
  }
}

#modal-atracoes .gato-modal img {
  max-width: 70px;
}

#modal-atracoes .modal-header {
  z-index: 3;
  background-size: cover;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  margin-top: -1px;
  display: flex;
  position: relative;
}

#modal-atracoes #close-button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 32px;
  place-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 15px;
  padding: 8px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: block;
  box-shadow: -3px 3px #f2a900;
}

#modal-atracoes #close-button svg {
  color: #e60043;
  width: 24px;
  height: 24px;
}

#modal-atracoes #close-button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

#modal-atracoes .modal-guts {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

#modal-overlay {
  z-index: 1000;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#modal-overlay.closed {
  display: none;
}

#modal-atracoes .header-wrapper-modal {
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .header-wrapper-modal {
    flex-direction: column-reverse;
  }
}

#modal-atracoes .atracao-img-modal {
  flex-shrink: 0;
  width: 100%;
  max-width: 288px;
  height: 100%;
  max-height: 316px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .atracao-img-modal {
    display: none;
  }
}

#modal-atracoes .atracao-img-modal-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .atracao-img-modal-mobile {
    margin-top: 1.5rem;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  #modal-atracoes .atracao-img-modal {
    max-width: 260px;
  }
}

#modal-atracoes .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  max-width: 288px;
  height: 316px;
  min-height: 250px;
  max-height: 316px;
}

#modal-atracoes .content {
  background-color: #f2a900;
  max-width: calc(100% - 1.25rem);
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content {
    padding: 10px 12px;
  }
}

#modal-atracoes .content .icon-wrapper {
  background-color: #d6df21;
  border-radius: 0 0 0 6px;
  padding: .375rem;
  position: absolute;
  top: 0;
  right: 0;
}

#modal-atracoes .content .icon-wrapper svg {
  width: 1.25rem;
  height: 1.3125rem;
}

#modal-atracoes .content .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content .card-evento-title {
    font-size: 1.3rem;
  }
}

#modal-atracoes .content .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0009;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content .card-evento-role {
    font-size: 1rem;
  }
}

#modal-atracoes .infos-tragetoria {
  padding-top: 2.5rem;
  padding-right: 1.5rem;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .infos-tragetoria {
    padding-top: 2rem;
  }
}

#modal-atracoes .infos-tragetoria h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

#modal-atracoes .infos-tragetoria .paragraphs-content p {
  color: #ffffffb3;
  margin-bottom: 1rem;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .modal-guts {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#modal-atracoes .programacao {
  padding-bottom: 0;
  padding-right: 0;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .programacao {
    padding-left: 0;
  }
}

#modal-atracoes .programacao .title-programacao {
  text-transform: uppercase;
  text-align: center;
  color: #1e1a34;
  background-color: #b3b0c4;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 7px 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.3;
}

#modal-atracoes .programacao .wrapper-programacao {
  border: 1px solid #fff3;
  align-items: center;
  margin: 16px 40px;
  display: flex;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .programacao .wrapper-programacao {
    flex-direction: column;
    gap: 1rem;
  }
}

#modal-atracoes .programacao .wrapper-programacao .section-title {
  border-right: 1px solid #fff3;
  padding: 16px;
}

#modal-atracoes .programacao .wrapper-programacao .nome-evento-wrapper {
  padding-left: 24px;
}

#modal-atracoes .programacao .confirmado {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  margin-bottom: 8px;
  padding: 2rem 3rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  display: none;
}

#modal-atracoes .programacao .nome-evento-wrapper .nome-evento {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

.scroll-box::-webkit-scrollbar {
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #4a446a;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #6e6988;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.scroll-box {
  scrollbar-width: thin;
  scrollbar-color: #6e6988 #4a446a;
}

.scroll-box::-moz-scrollbar {
  width: 8px;
}

.scroll-box::-moz-scrollbar-track {
  background-color: #b54fa5;
}

.scroll-box::-moz-scrollbar-thumb {
  background-color: #56c5d0;
}

.scroll-box::-moz-scrollbar-thumb:hover {
  background-color: #555;
}

@media (max-width: 770px) {
  .programacao .wrapper-programacao {
    padding-bottom: 16px;
  }

  .programacao .wrapper-programacao .section-title {
    border-right: unset !important;
  }

  .atracao-img-modal {
    margin: 32px auto;
  }
}

.richTextEditorJs {
  color: #36363b;
}

.richTextEditorJs h1, .richTextEditorJs h2, .richTextEditorJs h3 {
  margin-bottom: 1rem;
}

.richTextEditorJs h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
}

.richTextEditorJs h3 {
  font-size: 18px;
}

.richTextEditorJs p {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.44;
}

.richTextEditorJs ul, .richTextEditorJs ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.richTextEditorJs input[type="checkbox"] {
  -webkit-appearance: auto;
  appearance: auto;
}

.richTextEditorJs input[type="checkbox"] + label {
  margin-left: 5px;
}

.richTextEditorJs a {
  color: #007bff;
  text-decoration: underline;
}

.richTextEditorJs a:hover {
  color: #0056b3;
}

.richTextEditorJs img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.richTextEditorJs blockquote {
  background-color: #f9f9f9;
  border-left: 4px solid #ccc;
  margin: 10px 0;
  padding: 10px;
}

.richTextEditorJs pre {
  white-space: pre-wrap;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  font-family: Consolas, Monaco, Courier New, Courier, monospace;
}

.richTextEditorJs hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.richTextEditorJs table {
  border-collapse: collapse;
  width: 100%;
}

.richTextEditorJs th, .richTextEditorJs td {
  border-bottom: 1px solid #ccc;
  padding: 8px;
}

.richTextEditorJs input[type="text"], .richTextEditorJs textarea {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px;
}

.richTextEditorJs button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  padding: 8px 12px;
}

.richTextEditorJs button:hover {
  background-color: #0056b3;
}

.richTextEditorJs .list li {
  margin-left: 16px;
  list-style: auto;
}

.richTextEditorJs li {
  color: #36363b;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  list-style: disc;
}

.richTextEditorJs .warning {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 16px 16px 16px 30px;
  position: relative;
}

.richTextEditorJs .warning:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 320 294' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%237B7E89' d='M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z'/%3E%3C/svg%3E");
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 17px;
  left: 5px;
}

#banner {
  background: #0a0b21;
  height: 100vh;
  overflow: hidden;
}

#banner:after {
  content: "";
  background: radial-gradient(17.97% 24.82% at 72.05% 42.15%, #754a981a 0%, #3244961a 100%);
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .video-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

#banner .video-banner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#banner .video-banner:after {
  content: "";
  background: linear-gradient(#00000040 0%, #00020d00 52.08%, #02093f80 77.6%, #26004580 100%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .content-banner {
  z-index: 2;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  bottom: 9rem;
}

@media screen and (max-width: 600px) {
  #banner .content-banner {
    width: 244px;
  }
}

#banner .content-banner .subtitle {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
}

@media (max-width: 767px) {
  #banner .content-banner .subtitle {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #banner .content-banner .subtitle {
    font-size: 1.5rem;
  }
}

#banner .content-banner .title {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (max-width: 767px) {
  #banner .content-banner .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  #banner .content-banner .title {
    font-size: 2rem;
  }
}

#portfolio {
  background: #0a0b21;
  margin-top: -5rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  #portfolio {
    background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  }
}

#portfolio .orn-sections {
  display: none;
}

@media screen and (max-width: 600px) {
  #portfolio .orn-sections {
    display: block;
  }
}

#portfolio .swiper-portfolio {
  width: 100%;
  display: grid;
}

@media screen and (max-width: 768px) {
  #portfolio .swiper-portfolio {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .swiper-portfolio {
    margin-top: 9rem;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper {
  transition-timing-function: linear;
}

@media (max-width: 1661px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 1024px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
    display: grid;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1367px) {
  #portfolio .swiper-portfolio .swiper-wrapper .swiper-slide {
    width: 100%;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper .swiper-slide:first-child {
  grid-column-start: 2;
}

#portfolio .nav-wrapper {
  display: none;
}

@media screen and (max-width: 600px) {
  #portfolio .nav-wrapper {
    display: block;
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }

  #portfolio .cards {
    padding: .5rem 0 4rem 1rem;
  }
}

#portfolio .cards .card-principal {
  background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  width: 465.5px;
  height: 448px;
  position: absolute;
}

@media (max-width: 1661px) {
  #portfolio .cards .card-principal {
    width: 537px;
  }
}

@media screen and (max-width: 1440px) {
  #portfolio .cards .card-principal {
    width: 467px;
  }
}

@media (max-width: 1367px) {
  #portfolio .cards .card-principal {
    width: 439px;
  }
}

@media (max-width: 1280px) {
  #portfolio .cards .card-principal {
    width: 414px;
  }
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal {
    width: 427px;
    height: 182px;
    top: -12rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal {
    width: 327px;
    height: 152px;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#portfolio .cards .card-principal .content {
  position: absolute;
  top: 8rem;
  left: 4rem;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content {
    top: 1.5rem;
    left: 1.5rem;
  }
}

#portfolio .cards .card-principal .content .icon {
  width: 64px;
  height: 64px;
  display: block;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .icon {
    width: 32px;
    height: 32px;
  }
}

#portfolio .cards .card-principal .content .subtitle {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.125rem;
    line-height: 144%;
  }
}

#portfolio .cards .card-principal .content .title {
  letter-spacing: -.02em;
  background: linear-gradient(90deg, #5574ff 0%, #c47cff 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 114%;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 1.5rem;
    line-height: 133%;
  }
}

#portfolio .cards .card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in;
  position: relative;
}

#portfolio .cards .card .img-card {
  width: 100%;
  height: 448px;
  position: relative;
}

@media (max-width: 1661px) {
  #portfolio .cards .card .img-card {
    width: 100%;
  }
}

@media (max-width: 512px) {
  #portfolio .cards .card .img-card {
    width: 95%;
    height: 350px;
  }
}

@media (max-width: 440px) {
  #portfolio .cards .card .img-card {
    width: 312px;
    height: 312px;
  }
}

#portfolio .cards .card .img-card img, #portfolio .cards .card .img-card video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#portfolio .cards .card .img-card:after {
  content: "";
  background: linear-gradient(0deg, #00000080, #00000080);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

#portfolio .cards .card .content {
  border-left: 2px solid var(--primary-pure-10);
  background: linear-gradient(270deg, #ffffff01 0%, #ffffff1a 100%);
  width: 100%;
  height: 5.8125rem;
  padding: 1rem;
  transition: all .3s ease-in;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

#portfolio .cards .card .content .tag {
  color: #fff;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #ffffff1a;
  border: 1px solid #fff;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card .content .tag {
    font-size: 10px;
  }
}

#portfolio .cards .card .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#portfolio .cards .card .content .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  max-width: 412px;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (max-width: 1661px) {
  #portfolio .cards .card .content .description {
    width: 85%;
    max-width: 100%;
  }
}

@media (max-width: 899px) {
  #portfolio .cards .card.active .content {
    height: 100%;
  }
}

@media (min-width: 900px) {
  #portfolio .cards .card:hover .content {
    height: 100%;
  }
}

#sobre {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  #sobre {
    padding: 6rem 0 .875rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre {
    padding: 3rem 0 .875rem;
  }
}

#sobre .content {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 768px) {
  #sobre .content {
    flex-direction: column;
  }
}

#sobre .content .col-1 {
  width: 470px;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 {
    width: 306px;
  }
}

#sobre .content .col-1 .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 .tag {
    font-size: 10px;
  }
}

#sobre .content .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

@media (max-width: 1190px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

#sobre .content .col-2 {
  width: 488px;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  #sobre .content .col-2 {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre .content .col-2 {
    width: 327px;
    margin-top: 1rem;
  }
}

#sobre .content .col-2 .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (max-width: 1190px) {
  #sobre .content .col-2 .description {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos {
  margin-top: 13.8125rem;
}

@media (max-width: 1190px) {
  #sobre .servicos {
    margin-top: 7.5rem;
  }
}

@media screen and (max-width: 768px) {
  #sobre .servicos {
    margin-top: 3.5rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre .servicos {
    margin-top: 4rem;
  }
}

#sobre .servicos .swiper-servicos-sobre {
  border: 1px solid;
  border-width: 0 0 1px;
  border-top-style: none;
  border-top-color: currentColor;
  border-left-style: none;
  border-left-color: currentColor;
  border-right-style: none;
  border-right-color: currentColor;
  border-image-source: linear-gradient(90deg, #324496 0%, #754a98 100%);
  border-image-slice: 1;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    padding-bottom: 1rem;
  }
}

#sobre .servicos .swiper-servicos-sobre .swiper-wrapper {
  transition-timing-function: linear;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    border: unset;
  }
}

#sobre .servicos .nav-wrapper {
  display: none;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .nav-wrapper {
    display: block;
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }
}

#sobre .servicos .title {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .title {
    text-align: start;
    font-size: 1rem;
  }
}

#sobre .servicos .items-wrapper .item {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 174px;
  padding: 1.5rem 0;
  display: flex;
}

#sobre .servicos .items-wrapper .item p {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .items-wrapper .item p {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos .items-wrapper .item .icon {
  width: 24px;
  height: 24px;
}

#clientes {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  #clientes {
    padding: 7.5rem 0;
  }
}

@media screen and (max-width: 600px) {
  #clientes {
    padding: 4.5rem 0 2rem;
  }
}

#clientes .swiper-clientes {
  width: 100%;
  display: grid;
}

#clientes .swiper-clientes .swiper-wrapper {
  transition-timing-function: linear;
}

#clientes .swiper-clientes.linha-2 {
  max-width: 1216px;
}

#clientes .content {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

#clientes .content:after {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  bottom: 18.25rem;
  right: 3.875rem;
}

@media screen and (max-width: 600px) {
  #clientes .content:after {
    bottom: 0;
    right: unset;
  }
}

#clientes .content:before {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  top: -7.1875rem;
}

@media screen and (max-width: 600px) {
  #clientes .content:before {
    content: "";
    filter: blur(103.5px);
    z-index: -1;
    background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
    border-radius: 50%;
    width: 231px;
    height: 231px;
    position: absolute;
    left: -7.1875rem;
  }
}

#clientes .content .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1rem;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #clientes .content .tag {
    font-size: 10px;
  }
}

#clientes .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  text-align: center;
  width: 376px;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #clientes .content .title {
    width: 70%;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

#clientes .logo-clientes {
  gap: 1rem;
  max-width: 1524px;
  margin: 0 auto;
  display: grid;
}

#clientes .logo-clientes .item {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 123px;
  display: flex;
}

@media (max-width: 1661px) {
  #clientes .logo-clientes .item {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  #clientes .logo-clientes .item {
    height: 80px;
    padding: .5rem 1.2188rem;
  }
}

#contato {
  background: #0a0b21;
  padding: 13.25rem 0;
  position: relative;
}

@media screen and (max-width: 1440px) {
  #contato {
    padding: 7.5rem 0;
  }
}

@media screen and (max-width: 600px) {
  #contato {
    padding: 3rem 0 3.5rem;
  }
}

#contato .content-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 768px) {
  #contato .content-wrapper {
    flex-direction: column;
  }
}

#contato .content-wrapper .col-1 {
  max-width: 591px;
}

@media screen and (max-width: 1440px) {
  #contato .content-wrapper .col-1 {
    width: 80%;
  }
}

#contato .content-wrapper .col-1 .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .tag {
    font-size: 10px;
  }
}

#contato .content-wrapper .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .title {
    font-size: 2rem;
  }
}

@media (max-width: 1367px) {
  #contato .content-wrapper .col-1 .title {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .title {
    width: 235px;
    margin-top: 1rem;
  }
}

#contato .content-wrapper .col-1 .content {
  margin-top: 4rem;
}

#contato .content-wrapper .col-1 .content .contato-item {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .icon-contato {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .icon-contato {
    width: 24px;
    height: 24px;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .info span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info span {
    font-size: .625rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
  display: block;
  position: relative;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info a {
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a:after {
  content: "";
  background-color: #0000;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(.1, #0000), color-stop(.8, #4557e2), color-stop(.98, #4557e2));
  background-image: linear-gradient(left, transparent 10%, #4557e2 80%, #4557e2 98%);
  border-radius: .5rem;
  width: 0;
  height: 2.5px;
  transition: width .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

#contato .content-wrapper .col-1 .content .contato-item .info a:hover:after {
  width: 100%;
}

#contato .content-wrapper .col-2 {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: .25rem;
  width: 528px;
  padding: 2rem 1.5rem;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 {
    align-self: center;
    width: 100%;
    padding: 1.5rem 1rem;
  }
}

#contato .content-wrapper .col-2 h2 {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 h2 {
    font-size: 1rem;
  }
}

#contato .content-wrapper .col-2 .content-form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

#contato .content-wrapper .col-2 .content-form input {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  height: 56px;
  padding: 1rem .5rem;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form input::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form input::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form textarea {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  padding: .5rem;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form textarea::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Antonio, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form textarea::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form .btn {
  border: 1px solid var(--primary-pure);
  letter-spacing: -.02em;
  color: var(--primary-pure);
  cursor: pointer;
  background-color: #9b45e21a;
  border-radius: 28px;
  align-self: flex-start;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-2 .content-form .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--primary-light);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#contato .content-wrapper .col-2 .content-form .btn.disabled {
  color: var(--primary-pure-10);
  opacity: .5;
  pointer-events: none;
  background: #333;
  border-color: #0000;
}

#footer {
  background: #0a0b21;
  padding-bottom: 2.25rem;
  position: relative;
}

#footer .footer-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 600px) {
  #footer .footer-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  #footer .footer-wrapper .copy {
    align-self: center;
    width: 206px;
  }
}

#footer .footer-wrapper .copy h3 {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #footer .footer-wrapper .copy h3 {
    text-align: center;
    font-size: .625rem;
  }

  #footer .footer-wrapper .logo {
    align-self: center;
  }
}

.section-title {
  position: relative;
}

.section-title .badge-wrapper {
  color: #000;
  background-color: #f2a900;
  width: max-content;
  margin-bottom: 10px;
  padding: 8px;
  position: relative;
  transform: rotate(-2deg);
}

.section-title .badge-wrapper.bg-amarelo {
  background-color: var(--bienal-amarelo);
}

.section-title .badge-wrapper.bg-rosa {
  background-color: var(--bienal-rosa);
  color: #fff;
}

.section-title .badge-wrapper.bg-laranja {
  background-color: var(--bienal-laranja);
}

.section-title .badge-wrapper.bg-azul {
  color: #000;
  background-color: #f2a900;
}

.section-title .badge-wrapper .badge {
  letter-spacing: .04em;
  text-transform: uppercase;
  width: max-content;
  padding: 5px 15px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  position: relative;
  transform: skew(-3deg);
}

.section-title h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

@media screen and (max-width: 1440px) {
  .section-title h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .section-title h2 {
    font-size: 2.5rem;
  }
}

.section-title .section-description {
  letter-spacing: -.02em;
  color: #36363b;
  margin-top: 2rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input, .input-group textarea {
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 32px;
  width: 100%;
  padding: 16px 32px;
}

.input-group input:focus, .input-group textarea:focus {
  border-color: #000;
}

.input-group input::placeholder, .input-group textarea::placeholder {
  color: #525c64;
}

.input-group textarea {
  resize: none;
}

.ingresos-fixed {
  z-index: 10;
  background: url("footer-linear.f1b78cf0.webp") 100% / cover no-repeat;
  border-top: 1px solid #fff;
  min-height: 82px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.ingresos-fixed p {
  color: #fff;
  letter-spacing: -.44px;
  text-transform: uppercase;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .ingresos-fixed p {
    font-size: 1.2rem;
  }
}

.ingresos-fixed p span {
  color: #d6df21;
}

.ingresos-fixed .button-wrapper {
  transition: all .2s ease-in;
}

.ingresos-fixed .button-wrapper:hover {
  transform: scale(1.02);
}

.ingresos-fixed .button-wrapper .button {
  background-color: var(--bienal-amarelo);
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  box-shadow: 2.5px 2.5px #a11c64;
}

@media screen and (max-width: 768px) {
  .ingresos-fixed .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.ingresos-fixed .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_home .ingresos-fixed {
  transform: translateY(190px);
}

.coming-soon {
  background-color: #000;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
}

.coming-soon .logo {
  width: 160px;
  height: 160px;
  margin-top: 32px;
  margin-left: 32px;
}

.coming-soon video {
  width: 100%;
  height: 80%;
}

.coming-soon footer {
  background-color: #a3238e;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

main {
  overflow: hidden;
}

.body_home #banner-home {
  position: relative;
}

.body_home #banner-home.height-video {
  height: calc(100vh - 123px);
}

@media (max-width: 1400px) {
  .body_home #banner-home.height-video {
    height: calc(100vh - 123px);
  }
}

@media (max-width: 1024px) {
  .body_home #banner-home.height-video {
    height: calc(100vh - 113px);
  }
}

.body_home #banner-home:after {
  content: "";
  mix-blend-mode: multiply;
  background: #00000080;
  display: none;
  position: absolute;
  inset: 0;
}

.body_home #banner-home .video-wrapper {
  position: absolute;
  bottom: 55px;
  overflow: hidden;
  inset: 0 !important;
}

.body_home #banner-home .video-wrapper .mobile-banner-img {
  height: 100%;
  display: none;
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .video-wrapper .mobile-banner-img {
    display: block;
  }
}

.body_home #banner-home .video-wrapper .mobile-banner-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .video-wrapper #video_mobile {
    display: none;
  }
}

.body_home #banner-home .video-wrapper video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_home #banner-home .container {
  height: 100%;
}

.body_home #banner-home .banner-content {
  text-align: center;
  z-index: 3;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body_home #banner-home .banner-content span {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 4px 4px #00000014;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  display: block;
}

@media (max-width: 1440px) {
  .body_home #banner-home .banner-content span {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .banner-content span {
    font-size: 1.1rem;
  }
}

.body_home #banner-home .banner-content h1 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 2px 2px #00000029;
  font-size: 8rem;
  font-weight: 700;
  line-height: 1.1;
}

@media (max-width: 1440px) {
  .body_home #banner-home .banner-content h1 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .banner-content h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .body_home #banner-home .banner-content h1 {
    max-width: 15ch;
    margin: 0 auto;
  }
}

.body_home #banner-home .banner-content .button-wrapper-banner {
  max-width: max-content;
  margin: 2rem auto 0;
}

.body_home #banner-home .banner-content .button-wrapper-banner a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #1e1a34;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 2.5px 2.5px #d6df21;
}

.body_home #banner-home .banner-content .button-wrapper-banner a span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #banner-home .banner-content .button-wrapper-banner a:active, .body_home #banner-home .banner-content .button-wrapper-banner a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_home #banner-home .banner-content .button-wrapper-banner a:active span, .body_home #banner-home .banner-content .button-wrapper-banner a:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_home #banner-home .banner-content .button-wrapper-banner a svg, .body_home #banner-home .banner-content .button-wrapper-banner a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_home #banner-home .banner-content .button-wrapper-banner a span {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff !important;
}

.body_home #banner-home .banner-content .button-wrapper-banner a svg {
  filter: brightness(0) invert();
  width: 32px;
  height: 32px;
}

.body_home #banner-home .banner-bottom-info {
  color: #d6df21;
  z-index: 2;
  text-align: center;
  background: url("junte-se-bg.075bbc59.png") center / cover;
  width: 100%;
  min-height: 56px;
  font-size: 1.375rem;
  display: grid;
  position: absolute;
  bottom: -3.5rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

.body_home #banner-home .banner-bottom-info.video {
  bottom: 0;
}

.body_home #banner-home .banner-bottom-info img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .body_home #banner-home .banner-bottom-info {
    font-size: 1rem;
  }
}

.body_home #banner-home .banner-bottom-info .linha {
  align-self: stretch;
}

.body_home #banner-home .banner-bottom-info .linha img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_home #banner-home .banner-bottom-info .linha.linha-left img {
  object-position: right;
}

.body_home #banner-home .banner-bottom-info .linha.linha-right img {
  object-position: left;
}

.body_home #banner-home .banner-bottom-info p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

.body_home #banner-home .banner-bottom-info p span {
  letter-spacing: -.02em;
  font-weight: 800;
  text-decoration-line: underline;
}

.body_home #banner-home .swiper-pagination-bullet {
  background: #fff;
  border-radius: 6px;
  width: 24px;
  height: 12px;
}

.body_home #banner-home .banner-image {
  object-fit: cover;
  width: 100%;
  min-height: 300px;
}

.body_home #banner-home .conteudo-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  display: flex;
  position: absolute;
  top: 34%;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .conteudo-wrapper {
    top: 26%;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .conteudo-wrapper {
    text-align: center;
    padding: 0 1rem;
    top: 35%;
  }
}

@media (max-width: 500px) {
  .body_home #banner-home .conteudo-wrapper {
    top: 27%;
  }
}

.body_home #banner-home .conteudo-wrapper h1 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 2px 2px #00000029;
  text-align: center;
  max-width: 68.75rem;
  margin-bottom: 2rem;
  font-size: 5.5rem;
  font-weight: 700;
  line-height: 1.1;
}

@media (max-width: 1750px) {
  .body_home #banner-home .conteudo-wrapper h1 {
    font-size: 4.5rem;
  }
}

@media (max-width: 1450px) {
  .body_home #banner-home .conteudo-wrapper h1 {
    max-width: 54.75rem;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .conteudo-wrapper h1 {
    width: 37rem;
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .conteudo-wrapper h1 {
    width: 23rem;
    font-size: 3rem;
  }
}

.body_home #banner-home .conteudo-wrapper h3 {
  color: var(--white, #fff);
  text-align: center;
  text-shadow: 0 4px 4px #00000014;
  letter-spacing: -.03rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .conteudo-wrapper h3 {
    font-size: 1.25rem;
  }
}

.body_home #banner-home .conteudo-wrapper h2 {
  color: #fff;
  letter-spacing: -.055rem;
  text-transform: uppercase;
  margin-top: .5rem;
  margin-bottom: 2rem;
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 4rem;
}

@media (max-width: 1450px) {
  .body_home #banner-home .conteudo-wrapper h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .conteudo-wrapper h2 {
    font-size: 1.25rem;
  }
}

.body_home #banner-home .conteudo-wrapper .btn a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #a3238e;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 2.5px 2.5px #d6df21;
}

.body_home #banner-home .conteudo-wrapper .btn a span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #banner-home .conteudo-wrapper .btn a:active, .body_home #banner-home .conteudo-wrapper .btn a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_home #banner-home .conteudo-wrapper .btn a:active span, .body_home #banner-home .conteudo-wrapper .btn a:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_home #banner-home .conteudo-wrapper .btn a svg, .body_home #banner-home .conteudo-wrapper .btn a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_home #banner-home .conteudo-wrapper .btn a span {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff !important;
}

.body_home #banner-home .conteudo-wrapper .btn a svg {
  filter: brightness(0) invert();
  width: 32px;
  height: 32px;
}

.body_home #banner-ao-vivo {
  position: relative;
}

.body_home #banner-ao-vivo .bg-image-ao-vivo {
  width: 100%;
  min-height: 200px;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-ao-vivo .bg-image-ao-vivo {
    object-fit: cover;
  }
}

.body_home #banner-ao-vivo .text-content {
  width: 100%;
  max-width: 722px;
  position: absolute;
  top: 3.85rem;
  left: 24rem;
}

@media (max-width: 1600px) {
  .body_home #banner-ao-vivo .text-content {
    top: 3rem;
    left: 22rem;
  }
}

@media (max-width: 1400px) {
  .body_home #banner-ao-vivo .text-content {
    max-width: 275px;
    top: 4rem;
    left: 18rem;
  }
}

@media (max-width: 1200px) {
  .body_home #banner-ao-vivo .text-content {
    top: 2.75rem;
    left: 16rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-ao-vivo .text-content {
    left: 11rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-ao-vivo .text-content {
    max-width: 180px;
    top: 6rem;
    left: 7rem;
  }
}

@media (max-width: 500px) {
  .body_home #banner-ao-vivo .text-content {
    top: 4rem;
  }
}

@media (max-width: 450px) {
  .body_home #banner-ao-vivo .text-content {
    top: 3rem;
    left: 6rem;
  }
}

@media (max-width: 400px) {
  .body_home #banner-ao-vivo .text-content {
    top: 2rem;
    left: 4rem;
  }
}

@media (max-width: 350px) {
  .body_home #banner-ao-vivo .text-content {
    left: 2.5rem;
  }
}

.body_home #banner-ao-vivo .text-content h1 {
  letter-spacing: -.09rem;
  text-transform: uppercase;
  color: var(--primary-azul-noite, #2e3192);
  margin-bottom: 1rem;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 111%;
}

@media (max-width: 1400px) {
  .body_home #banner-ao-vivo .text-content h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-ao-vivo .text-content h1 {
    left: 14rem;
  }
}

.body_home #banner-ao-vivo .text-content p {
  color: var(--primary-azul-noite, #2e3192);
  letter-spacing: -.03rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
}

@media (max-width: 1400px) {
  .body_home #banner-ao-vivo .text-content p {
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  .body_home #banner-ao-vivo .text-content p {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-ao-vivo .text-content p {
    margin-bottom: 2rem;
  }
}

.body_home #banner-ao-vivo .text-content .button-live {
  background: var(--bienal-amarelo);
  color: var(--black);
  letter-spacing: -.0175rem;
  text-transform: uppercase;
  border-radius: .1875rem;
  align-items: center;
  gap: 1.5rem;
  max-width: 12.65rem;
  padding: .75rem 1rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157%;
  display: flex;
  box-shadow: 2.5px 2.5px #a11c64;
}

.body_home #banner-ao-vivo .text-content .button-live:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
}

.body_home #banner-ao-vivo .text-content .button-live svg {
  width: 1.5rem;
  height: 1.5rem;
}

.body_home #swiper-mini {
  margin-top: -55px;
  position: relative;
  overflow: hidden;
}

.body_home #swiper-mini .swiper-mini {
  background: var(--bienal-vermelha);
  height: 100%;
  overflow: hidden;
}

.body_home #swiper-mini .swiper-mini .swiper-wrapper {
  transition-timing-function: linear;
}

.body_home #swiper-mini .swiper-mini .swiper-slide {
  max-width: max-content;
}

.body_home #swiper-mini .mini-item {
  align-items: center;
  gap: 32px;
  padding: 21px 0;
  display: flex;
}

.body_home #swiper-mini .mini-item svg {
  width: 17px;
  height: 20px;
}

.body_home #o-evento {
  background-color: #99d6ea;
  margin-bottom: -100px;
  position: relative;
}

.body_home #o-evento .img-bg-evento {
  width: 100%;
  position: relative;
}

.body_home #o-evento .img-bg-evento .evento-bg-1 {
  display: none;
}

.body_home #o-evento .img-bg-evento .evento-bg {
  width: 100%;
}

.body_home #o-evento .img-bg-evento .balanco {
  position: absolute;
  top: 200px;
  left: 0;
}

.body_home #o-evento .img-bg-evento .flor {
  position: absolute;
  bottom: 40px;
  left: 15%;
}

.body_home #o-evento .img-bg-evento .menina-voando-azul {
  position: absolute;
  top: 200px;
  left: 20%;
}

.body_home #o-evento .img-bg-evento .casal {
  position: absolute;
  bottom: 26%;
  left: 35%;
}

.body_home #o-evento .img-bg-evento .menina-voando-direita {
  position: absolute;
  bottom: 36%;
  right: 8%;
}

.body_home #o-evento .img-bg-evento .passaro {
  position: absolute;
  bottom: 76%;
  right: 0;
}

.body_home #o-evento .container-evento {
  margin-bottom: -140px;
}

.body_home #o-evento .section-title .section-description {
  text-align: justify;
}

.body_home #o-evento .section-title .titulo-evento {
  text-transform: uppercase;
  max-width: 412px;
  font-family: Antonio;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_home #o-evento .grid-wrapper {
  z-index: 10;
  grid-template-columns: .5fr 1.2fr;
  margin-bottom: 1.875rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #o-evento .grid-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.body_home #o-evento .icon-laranja {
  position: absolute;
  top: 4rem;
  right: 0;
}

.body_home #o-evento .badge-for-ornamento {
  width: max-content;
  position: relative;
}

.body_home #o-evento .badge-for-ornamento .icon-luneta {
  z-index: 2;
  position: absolute;
  top: -60px;
  right: 0;
}

.body_home #o-evento .col-2 {
  z-index: 2;
  justify-content: end;
  display: grid;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .col-2 {
    margin-top: 2rem;
  }
}

.body_home #o-evento .col-2 .section-description {
  color: #000000b3;
  max-width: 100%;
  margin-bottom: .875rem;
  font-family: Noto Sans, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 140%;
}

.body_home #o-evento .card-flutuante {
  text-align: center;
  background: #fff;
  border: 1px solid #0f0f120d;
  border-radius: 6px;
  width: 100%;
  max-width: 200px;
  padding: 1rem 1.5rem;
  position: absolute;
  box-shadow: 0 7.93959px 31.7584px #35373814;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante {
    max-width: 180px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante {
    min-width: 102px;
    max-width: max-content;
    padding: 10px 14px;
  }
}

.body_home #o-evento .card-flutuante:before {
  content: "";
  z-index: 1;
  width: 63px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.body_home #o-evento .card-flutuante-1 {
  color: #a3238e;
  top: 5rem;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-1 {
    top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-1 {
    top: -10px;
  }
}

.body_home #o-evento .card-flutuante-1:after {
  background-color: #a3238e;
}

.body_home #o-evento .card-flutuante-2 {
  color: #56c5d0;
  bottom: 3.125rem;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-2 {
    bottom: -40px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-2 {
    bottom: -40px;
    left: 40px;
  }
}

.body_home #o-evento .card-flutuante-2:after {
  background-color: #56c5d0;
}

.body_home #o-evento .card-flutuante-3 {
  color: #f58220;
  bottom: 3.75rem;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-3 {
    bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-3 {
    bottom: 4rem;
  }
}

.body_home #o-evento .card-flutuante-3:after {
  background-color: #f58220;
}

.body_home #o-evento .card-flutuante .number {
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 800;
  line-height: 117%;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante .number {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante .number {
    font-size: 24px;
  }
}

.body_home #o-evento .card-flutuante .info {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #36363b;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante .info {
    font-size: 12px;
  }
}

.body_home #o-evento .img-wrapper {
  padding: 0 100px;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .body_home #o-evento .img-wrapper {
    padding: 0 60px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .img-wrapper {
    padding: 0 10px;
  }
}

.body_home #o-evento .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #fff;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

.body_home #o-evento .button-wrapper a span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #o-evento .button-wrapper a:active, .body_home #o-evento .button-wrapper a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_home #o-evento .button-wrapper a:active span, .body_home #o-evento .button-wrapper a:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_home #o-evento .button-wrapper a svg, .body_home #o-evento .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_home #o-evento .alien-orna {
  z-index: 2;
  position: absolute;
  bottom: -70px;
  right: 20vw;
}

@media screen and (max-width: 1440px) {
  .body_home #o-evento .alien-orna {
    max-width: 200px;
    bottom: -50px;
    right: 20vw;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #o-evento .alien-orna {
    max-width: 130px;
    bottom: -100px;
    right: 1.5rem;
  }
}

.body_home #o-evento .gatinho-orna {
  margin-bottom: -20px;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .gatinho-orna {
    max-width: 123px;
    margin-left: 21px;
  }
}

.body_home #o-evento .linear-orna {
  position: relative;
}

.body_home #o-evento .linear-orna img {
  object-fit: cover;
  width: 100%;
  min-height: 18px;
}

@media (max-width: 1673px) {
  .body_home #o-evento .container-evento {
    margin-bottom: -110px;
  }

  .body_home #o-evento .img-bg-evento .evento-bg, .body_home #o-evento .img-bg-evento .evento-bg-2 {
    width: 100%;
  }

  .body_home #o-evento .img-bg-evento .balanco img, .body_home #o-evento .img-bg-evento .flor img, .body_home #o-evento .img-bg-evento .menina-voando-azul img, .body_home #o-evento .img-bg-evento .casal img, .body_home #o-evento .img-bg-evento .menina-voando-direita img {
    width: 66%;
  }

  .body_home #o-evento .img-bg-evento .passaro {
    right: -50px;
  }

  .body_home #o-evento .img-bg-evento .passaro img {
    width: 66%;
  }
}

@media (max-width: 1180px) {
  .body_home #o-evento .container-evento {
    margin-bottom: -80px;
  }

  .body_home #o-evento .img-bg-evento .evento-bg, .body_home #o-evento .img-bg-evento .evento-bg-2 {
    width: 100%;
  }

  .body_home #o-evento .img-bg-evento .balanco img, .body_home #o-evento .img-bg-evento .flor img, .body_home #o-evento .img-bg-evento .menina-voando-azul img, .body_home #o-evento .img-bg-evento .casal img, .body_home #o-evento .img-bg-evento .menina-voando-direita img {
    width: 46%;
  }

  .body_home #o-evento .img-bg-evento .passaro {
    right: -80px;
  }

  .body_home #o-evento .img-bg-evento .passaro img {
    width: 46%;
  }
}

@media (max-width: 930px) {
  .body_home #o-evento .container-evento {
    margin-bottom: -20px;
  }

  .body_home #o-evento .img-bg-evento .evento-bg, .body_home #o-evento .img-bg-evento .evento-bg-2 {
    width: 100%;
  }

  .body_home #o-evento .img-bg-evento .balanco {
    top: unset;
    bottom: 35%;
  }

  .body_home #o-evento .img-bg-evento .balanco img, .body_home #o-evento .img-bg-evento .flor img {
    width: 46%;
  }

  .body_home #o-evento .img-bg-evento .menina-voando-azul {
    top: unset;
    bottom: 55%;
  }

  .body_home #o-evento .img-bg-evento .menina-voando-azul img, .body_home #o-evento .img-bg-evento .casal img {
    width: 46%;
  }

  .body_home #o-evento .img-bg-evento .menina-voando-direita {
    right: -50px;
  }

  .body_home #o-evento .img-bg-evento .menina-voando-direita img {
    width: 46%;
  }

  .body_home #o-evento .img-bg-evento .passaro {
    right: -80px;
  }

  .body_home #o-evento .img-bg-evento .passaro img {
    width: 46%;
  }
}

@media (max-width: 699px) {
  .body_home #o-evento {
    margin-bottom: -58px;
  }

  .body_home #o-evento .container-evento {
    margin-bottom: -20px;
  }

  .body_home #o-evento .img-bg-evento .display-2 {
    display: none;
  }

  .body_home #o-evento .img-bg-evento .evento-bg-1 {
    display: block;
  }
}

.body_home #atracoes {
  background-color: var(--bienal-vermelha);
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #atracoes {
    background-size: 80px 80px;
  }
}

.body_home #atracoes .minina-esquerda {
  position: absolute;
  bottom: 65px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .body_home #atracoes .minina-esquerda img {
    width: 70%;
  }
}

.body_home #atracoes .passaro-direita {
  position: absolute;
  bottom: 65px;
  right: 0;
}

@media screen and (max-width: 768px) {
  .body_home #atracoes .passaro-direita {
    right: -45px;
  }

  .body_home #atracoes .passaro-direita img {
    width: 70%;
  }
}

.body_home #atracoes .info-sujeita {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

.body_home #atracoes .section-title h2 {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .body_home #atracoes .section-title h2 {
    max-width: 15ch;
  }
}

.body_home #atracoes .info-atracoes-titulo {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

.body_home #atracoes .button-wrapper {
  flex-shrink: 0;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 160px;
  display: flex;
}

.body_home #atracoes .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #fff;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

.body_home #atracoes .button-wrapper a span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #atracoes .button-wrapper a:active, .body_home #atracoes .button-wrapper a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_home #atracoes .button-wrapper a:active span, .body_home #atracoes .button-wrapper a:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_home #atracoes .button-wrapper a svg, .body_home #atracoes .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

@media (min-width: 768px) {
  .body_home #atracoes .button-wrapper-bottom {
    display: none;
  }
}

.body_home #atracoes .button-wrapper-bottom .button {
  justify-content: center;
  max-width: 100%;
}

.body_home #atracoes .linear-orna img {
  object-fit: cover;
  width: 100%;
  min-height: 18px;
}

.body_home #programacao {
  position: relative;
}

.body_home #programacao .section-title {
  width: max-content;
  margin: 0 auto 3.125rem;
  position: relative;
}

.body_home #programacao .section-title .ornamento-peixe {
  position: absolute;
  top: 50%;
  right: -4rem;
  transform: translateY(-57%);
}

.body_home #programacao .section-title h2 {
  z-index: 4;
  position: relative;
}

.body_home #programacao .swiper-programacao-tabs {
  margin-bottom: 2.5rem;
}

.body_home #programacao .swiper-programacao-tabs .swiper-wrapper {
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .swiper-programacao-tabs .swiper-wrapper {
    justify-content: unset;
  }
}

.body_home #programacao .swiper-programacao-tabs .swiper-slide {
  max-width: max-content;
}

.body_home #programacao .tab-programacao {
  cursor: pointer;
  color: #fff9;
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: #f5872c;
  border: 1px solid #fff;
  border-radius: 4px;
  min-width: 140px;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .tab-programacao {
    min-width: 112px;
  }
}

.body_home #programacao .tab-programacao .programacao-data {
  font-size: 1.5rem;
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .tab-programacao .programacao-data {
    line-height: 1;
  }
}

.body_home #programacao .tab-programacao.active {
  color: #fff;
  background: #a3238e;
}

.body_home #programacao .programacao-tab-content {
  min-height: max-content;
  display: none;
  position: relative;
}

.body_home #programacao .programacao-tab-content.active {
  opacity: 1;
  display: block;
}

.body_home #programacao .programacao-tab-content .gatinho-orna {
  position: absolute;
  top: -100px;
  left: 15px;
}

@media screen and (max-width: 1200px) {
  .body_home #programacao .programacao-tab-content .gatinho-orna {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .gatinho-orna {
    top: unset;
    transform-origin: 0;
    display: block;
    bottom: 0;
    transform: scale(.5);
  }
}

.body_home #programacao .programacao-tab-content .grid-wrapper {
  color: #fff;
  z-index: 2;
  grid-template-columns: .3fr 1fr;
  gap: 1rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .programacao-tab-content .grid-wrapper {
    grid-template-columns: 1fr;
    gap: 4px;
  }
}

.body_home #programacao .programacao-tab-content .side-info {
  background-color: var(--bienal-rosa);
  text-align: center;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .programacao-tab-content .side-info {
    grid-row: 2;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info {
    background: none;
  }
}

.body_home #programacao .programacao-tab-content .side-info .content-side {
  margin: auto;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .side-info .content-side {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .content-side {
    background-color: var(--bienal-vermelha);
    margin: unset;
    border-radius: 4px;
    margin-bottom: 4px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .img-detail {
  width: max-content;
  margin: 0 auto 2rem;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .img-detail {
    margin-bottom: 0;
    position: absolute;
    top: -20px;
    right: 0;
  }

  .body_home #programacao .programacao-tab-content .side-info .img-detail img {
    height: 40px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .programacao-horario {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.17;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-horario {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-horario {
    text-align: left;
  }
}

.body_home #programacao .programacao-tab-content .side-info .programacao-local {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-local {
    text-align: left;
  }
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info {
  background: #56c5d0;
  padding: 12px 0;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .bottom-info {
    border-radius: 4px;
    margin-top: 2rem;
    padding: 14px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: flex;
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info svg {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.3125rem;
  margin-top: -3px;
  margin-left: 4px;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper {
  background-color: var(--bienal-rosa);
  border-radius: 4px;
  grid-template-columns: .4fr .6fr;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-programacao-atracoes {
  width: 100%;
  display: grid;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .swiper-button-lock {
  display: none !important;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
  border-right: 1px solid #fff3;
  padding: 3.5rem;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
    border-bottom: 1px solid #fff3;
    padding: 1.5rem;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.3;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao {
  letter-spacing: -.02em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .content-for-p.open .descricao {
  display: block;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo {
  cursor: pointer;
  letter-spacing: -.02em;
  color: #d6df21;
  align-items: center;
  margin-top: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  display: none;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo {
    display: flex;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper {
  align-items: flex-start;
  width: 100%;
  padding: 3.5rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper {
    padding: 2rem;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes {
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 11px;
  display: flex;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes .header-atracoes-titulo {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao {
  cursor: pointer;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img {
  height: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: -webkit-box;
  overflow: hidden;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 157%;
}

.body_home #atracoes .card-evento:not(.card-programacao) .card-evento-img, .body_home #atracoes .card-evento:not(.card-programacao) .card-evento-img img {
  height: 100%;
}

.body_home #galeria {
  background-color: var(--bienal-laranja);
  background-image: url("background-eventos.442df16b.svg");
  height: 100%;
  position: relative;
}

.body_home #galeria .galeria-wrapper {
  padding: 7.5rem 0 3.5rem;
}

.body_home #galeria .galeria-wrapper .text-button {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #galeria .galeria-wrapper .text-button {
    flex-direction: column;
  }
}

.body_home #galeria .galeria-wrapper .text-button h2 {
  color: #fff;
}

.body_home #galeria .galeria-wrapper .text-button .button-live {
  background: var(--bienal-amarelo);
  color: var(--black);
  letter-spacing: -.0175rem;
  text-transform: uppercase;
  border-radius: .1875rem;
  align-items: center;
  gap: 1.5rem;
  max-width: 12.65rem;
  padding: .75rem 1rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157%;
  display: flex;
  box-shadow: 2.5px 2.5px #a11c64;
}

.body_home #galeria .galeria-wrapper .text-button .button-live:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
}

.body_home #galeria .cards-wrapper {
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 7.5rem;
  display: flex;
}

.body_home #galeria .cards-wrapper .col-1, .body_home #galeria .cards-wrapper .col-2 {
  gap: 1rem;
  display: flex;
}

.body_home #galeria .cards-wrapper img {
  object-fit: cover;
  width: 23.75rem;
  height: 19.5rem;
}

.body_home #instagram .badge-for-ornamento {
  max-width: max-content;
  margin: 0 auto;
}

.body_home #instagram .ornamento-camera {
  z-index: -1;
  position: absolute;
  bottom: -23px;
  left: -53px;
}

.body_home #instagram .section-title {
  max-width: max-content;
  margin: 0 auto 3.5rem;
}

.body_home #instagram .instagram-wrapper-new #instafeed {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper-new #instafeed {
    grid-template-columns: 1fr 1fr;
  }
}

.body_home #instagram .instagram-wrapper-new #instafeed a {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.body_home #instagram .instagram-wrapper-new #instafeed a:hover .content-hover {
  transform: translateY(0);
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover {
  pointer-events: none;
  text-align: center;
  background-color: #e60043;
  place-content: center;
  gap: 10px;
  height: 100%;
  padding: 2rem;
  transition: all .2s;
  display: grid;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(100%);
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta {
    font-size: 1rem;
  }
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .click-icon {
  margin-right: 2rem;
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover svg {
  color: #fff;
  width: 2.0625rem;
  height: 2rem;
  margin: 0 auto;
}

.body_home #instagram .banner-embaixo {
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body_home #instagram .banner-embaixo {
    flex-direction: column;
  }
}

.body_home #instagram .instagram-wrapper {
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.body_home #instagram .img-wrapper {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.body_home #instagram .img-wrapper a {
  height: 100%;
  display: block;
}

.body_home #instagram .img-wrapper a img {
  object-fit: cover;
  height: 100%;
}

.body_home #instagram .img-wrapper:hover .content-hover {
  transform: translateY(0);
}

.body_home #instagram .img-wrapper .content-hover {
  pointer-events: none;
  text-align: center;
  background-color: #e60043;
  place-content: center;
  gap: 10px;
  height: 100%;
  padding: 2rem;
  transition: all .2s;
  display: grid;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(100%);
}

.body_home #instagram .img-wrapper .content-hover .see-insta {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .img-wrapper .content-hover .see-insta {
    font-size: 1rem;
  }
}

.body_home #instagram .img-wrapper .content-hover .click-icon {
  margin-right: 2rem;
}

.body_home #instagram .img-wrapper .content-hover svg {
  color: #fff;
  width: 2.0625rem;
  height: 2rem;
  margin: 0 auto;
}

.body_home #midia .badge-for-ornamento {
  max-width: max-content;
  margin: 0 auto;
}

.body_home #midia .ornamento-camera {
  z-index: -1;
  position: absolute;
  bottom: -23px;
  left: -53px;
}

.body_home #midia .section-title {
  max-width: max-content;
  margin: 0 auto 3.5rem;
}

.body_home .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_home .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_home .blog-card {
  border-radius: 3px;
  overflow: hidden;
}

.body_home .blog-card.blog-destaque.half {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque.half {
    flex-direction: column;
    grid-template-rows: 60% 40%;
    grid-template-columns: 1fr;
    display: flex;
  }
}

.body_home .blog-card.blog-destaque.half-bottom {
  grid-template-rows: 60% 40%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque.half-bottom {
    flex-direction: column;
    display: flex;
  }

  .body_home .blog-card.blog-destaque .img-wrapper {
    flex: 1;
    padding-top: 75%;
    position: relative;
    overflow: hidden;
  }
}

.body_home .blog-card.blog-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .img-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.body_home .blog-card.blog-destaque .content-blog {
  color: #fff;
  background: #9b2743;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .content-blog {
    flex: 1;
    padding: 1rem;
  }
}

.body_home .blog-card.blog-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_home .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_home .blog-card.blog-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media (min-width: 768px) {
  .body_home .swiper-midia {
    display: none;
  }
}

.body_home .swiper-midia {
  overflow: visible;
}

.body_home .swiper-midia .swiper-slide {
  height: auto;
}

.body_home .swiper-midia .swiper-slide .blog-card {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.body_home .swiper-midia .swiper-slide .blog-card .img-wrapper {
  flex: 1;
}

.body_home .swiper-midia .swiper-slide .blog-card .content-blog {
  flex: unset;
}

.body_home .swiper-midia .swiper-slide:first-child .content-blog {
  background-color: #9b2743;
}

.body_home .swiper-midia .swiper-slide:nth-child(2) .content-blog {
  background-color: #ff6a39;
}

.body_home .swiper-midia .swiper-slide:nth-child(3) .content-blog {
  background-color: #5cbde5;
}

.body_home .swiper-midia .swiper-slide:nth-child(4) .content-blog {
  background-color: #f2a900;
}

.body_home .swiper-midia .todas-noticias {
  border: 1px solid #0f0f12;
  border-radius: 3px;
  margin-top: 1rem;
  padding: 10px;
  display: block;
}

.body_home .swiper-midia .todas-noticias p {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

.body_home .swiper-midia .todas-noticias svg {
  width: 20px;
  height: 20px;
}

.body_home .blog-grid-wrapper {
  grid-template-rows: 27.8125rem 27.8125rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 768px) {
  .body_home .blog-grid-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body_home .blog-grid-wrapper {
    grid-template-rows: 25rem 25rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
}

@media screen and (max-width: 768px) {
  .body_home .blog-grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home .blog-grid-wrapper .blog-card:first-child {
  grid-column: 1 / 4;
  grid-template-columns: 1.6fr 1.4fr;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:first-child {
    grid-template-columns: 1fr;
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:first-child .content-blog {
  background-color: #9b2743;
}

.body_home .blog-grid-wrapper .blog-card:first-child img {
  max-height: 445px;
}

.body_home .blog-grid-wrapper .blog-card:nth-child(2) {
  grid-column: 4 / 6;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(2) {
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(2) .content-blog {
  background-color: #ff6a39;
}

.body_home .blog-grid-wrapper .blog-card:nth-child(3) {
  grid-area: 2 / 1 / auto / 3;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(3) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(3) .content-blog {
  background-color: #5cbde5;
}

.body_home .blog-grid-wrapper .blog-card:nth-child(4) {
  grid-area: 2 / 3 / auto / 5;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(4) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(4) .content-blog {
  background-color: #f2a900;
}

.body_home .blog-grid-wrapper .todas-noticias {
  background: #fff;
  border: 1px solid #0f0f12;
  border-radius: 3px;
  place-content: center;
  padding: 1rem;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .todas-noticias {
    grid-column: span 2;
  }
}

@media screen and (max-width: 768px) {
  .body_home .blog-grid-wrapper .todas-noticias {
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .todas-noticias p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_home .blog-grid-wrapper .todas-noticias svg {
  width: 20px;
  height: 21px;
  margin-bottom: 5px;
}

.body_home #youtube {
  background-color: var(--bienal-laranja);
  background-image: url("background-eventos.442df16b.svg");
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #youtube {
    background-size: 80px 80px;
  }
}

@media (min-width: 1024px) {
  .body_home #youtube .swiper-pagination {
    position: unset;
    margin-right: 2rem;
    max-width: 80px !important;
  }
}

.body_home #youtube .swiper-pagination {
  max-width: 80px !important;
}

.body_home #youtube .controls {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.body_home #youtube .controls .buttons {
  flex-shrink: 0;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .controls .buttons {
    display: none;
  }
}

.body_home #youtube .controls .buttons .button {
  background: #d6df21;
  border-radius: 3px;
  place-content: center;
  gap: 8px;
  width: 56px;
  height: 56px;
  padding: 8px 16px;
  display: grid;
}

.body_home #youtube .controls .buttons .button svg {
  width: 24px;
  height: 24px;
}

.body_home #youtube .controls .buttons .button.button-left svg {
  transform: rotate(180deg);
}

.body_home #youtube .controls .buttons .swiper-button-disabled {
  background: #e3e3e6;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .swiper-youtube {
    overflow: visible;
  }

  .body_home #youtube .swiper-youtube .swiper-wrapper {
    padding-bottom: 4rem;
  }
}

.body_home #youtube .swiper-youtube .swiper-slide {
  height: auto;
}

.body_home #youtube .swiper-youtube .swiper-slide > a {
  height: 100%;
  display: grid;
}

.body_home #youtube .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: #ffffff5c;
}

.body_home #youtube .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--bienal-amarelo);
  border-radius: 8px;
  width: 16px;
}

.body_home #youtube .section-title h2 {
  color: #fff;
}

.body_home #youtube .card-youtube {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.body_home #youtube .card-youtube .img-wrapper {
  margin-bottom: 1.5rem;
  padding-top: 56.25%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .card-youtube .img-wrapper {
    margin-bottom: 1rem;
  }
}

.body_home #youtube .card-youtube .img-wrapper img {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_home #youtube .card-youtube .data {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: var(--bienal-rosa);
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.body_home #youtube .card-youtube .video-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 3.5625rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

.body_home #contato-footer {
  background: var(--bienal-azul);
  scroll-margin-top: 130px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer {
    padding-bottom: 0;
  }
}

.body_home #contato-footer .contato-grid-wide {
  position: unset;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  align-items: start;
  padding-top: 32px;
  padding-bottom: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .contato-grid-wide {
    grid-template-columns: 1fr;
    padding-bottom: 0;
  }
}

@media (max-width: 1450px) {
  .body_home #contato-footer .contato-grid-wide {
    grid-template-columns: 1fr;
  }
}

.body_home #contato-footer .col-1 {
  align-self: stretch;
  margin: 0 auto;
  position: relative;
}

.body_home #contato-footer .col-1 .contato-wraper {
  width: 100%;
  max-width: 648px;
  margin: 0 auto;
}

.body_home #contato-footer .col-1 .contato-wraper #hbspt-form-ac112768-8fc5-4825-aea0-ef1aa95442f7 .hs-form-6924d1d1-6bd5-4ec3-b71c-f3f6c3dac6a4_2244d383-1604-4f10-ad7e-fb0c3a06b8a1 legend.hs-field-desc {
  display: none !important;
}

@media (max-width: 1450px) {
  .body_home #contato-footer .col-1 .contato-wraper {
    margin: unset;
    margin-bottom: 30px;
  }
}

.body_home #contato-footer .col-1 .badge-wrapper {
  color: #000;
  background-color: #f2a900;
  width: max-content;
  margin-bottom: 10px;
  padding: 8px;
  position: relative;
  transform: rotate(-2deg);
}

.body_home #contato-footer .col-2 .badge-wrapper {
  color: #1e1a34;
  text-transform: uppercase;
  background-color: #f2a900;
  width: max-content;
  margin-bottom: 10px;
  padding: 8px 8px 12px;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  position: relative;
  transform: rotate(-2deg);
}

.body_home #contato-footer .col-2 .email-contato-wrapper {
  z-index: 4;
  padding-top: 16px;
  position: relative;
}

.body_home #contato-footer .col-2 .links-wrapper {
  margin-bottom: 20px;
}

.body_home #contato-footer .col-2 .links-wrapper p {
  background-color: #f3d5ae;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: 8px;
  padding: 0 8px;
  transform: rotate(-2deg);
}

.body_home #contato-footer .col-2 .links-wrapper a {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_home #contato-footer .col-2 .links-wrapper .grid-links {
  gap: 9px;
  display: grid;
}

@media (max-width: 1450px) {
  .body_home #contato-footer .col-2 {
    margin-left: 20px;
  }
}

.body_home #contato-footer .orna {
  z-index: 2;
  position: absolute;
}

.body_home #contato-footer .orna-1 {
  top: 9%;
  right: -2rem;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .orna-1 {
    max-width: 60px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .orna-1 {
    max-width: 47px;
    top: -24px;
    left: 3.875rem;
  }
}

.body_home #contato-footer .orna-2 {
  bottom: 119px;
  left: 160px;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .orna-2 {
    bottom: 89px;
    left: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .orna-2 {
    bottom: 74px;
    left: 100px;
  }
}

.body_home #contato-footer .orna-3 {
  bottom: 0;
  left: 0;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .orna-3 {
    max-width: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .orna-3 {
    max-width: 100px;
  }
}

.body_home #contato-footer .orna-4 {
  bottom: -40px;
  right: 20%;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .orna-4 {
    max-width: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .orna-4 {
    display: none;
  }
}

.body_home #contato-footer .img-wrapper {
  height: 100%;
}

.body_home #contato-footer .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_home #contato-footer .menina-conato {
  z-index: 1;
  position: absolute;
  bottom: 28%;
  right: 14%;
}

@media screen and (max-width: 600px) {
  .body_home #contato-footer .menina-conato {
    display: none;
  }
}

@media (max-width: 945px) {
  .body_home #contato-footer .menina-conato {
    display: none;
  }
}

.body_home #contato-footer .detalhes-contato {
  position: absolute;
  bottom: 34%;
  right: 0;
}

.body_home #contato-footer .folha-contato {
  z-index: 1;
  position: absolute;
  bottom: 48%;
  right: 9.5%;
}

@media (max-width: 945px) {
  .body_home #contato-footer .folha-contato {
    display: none;
  }
}

.body_home #contato-footer .nuvem-contato {
  z-index: 1;
  position: absolute;
  bottom: 66.5%;
  right: 35%;
}

@media (max-width: 945px) {
  .body_home #contato-footer .nuvem-contato {
    display: none;
  }
}

.body_home #contato-footer .bg-contato {
  position: absolute;
  bottom: -5px;
  right: 0;
}

@media (max-width: 945px) {
  .body_home #contato-footer .bg-contato {
    display: none;
  }
}

@media (max-width: 1600px) {
  .body_home #contato-footer {
    padding-top: 5rem;
    padding-left: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer {
    grid-row: 1;
    padding-bottom: 4.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.body_home #contato-footer .contato-girl-orna {
  position: absolute;
  bottom: -5rem;
  right: 7.5rem;
}

@media (max-width: 1760px) {
  .body_home #contato-footer .contato-girl-orna {
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer .contato-girl-orna {
    display: none;
  }
}

.body_home #contato-footer .limit-width {
  z-index: 1;
  max-width: 635px;
  padding-right: 50px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer .limit-width {
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .limit-width {
    max-width: 540px;
    margin: 0 auto;
  }
}

.body_home #contato-footer .contato-wrapper {
  margin-bottom: 3.5rem;
}

.body_home #contato-footer .contato-wrapper .label-texto {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: var(--bienal-rosa);
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 144%;
}

.body_home #contato-footer .contato-wrapper .contato-texto {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 800;
  line-height: 140%;
}

@media (max-width: 1360px) {
  .body_home #contato-footer .contato-wrapper .contato-texto {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home #contato-footer .contato-wrapper .contato-texto {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .contato-wrapper .contato-texto {
    font-size: 2.2rem;
  }
}

.body_home #contato-footer .contato-wrapper .contato-descricao {
  color: #5e5c66;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body_home #contato-footer .form-wrapper {
  max-width: 100%;
}

.body_home #contato-footer .form-wrapper .badge-for-ornamento-contato {
  margin-top: 32px;
}

.body_home #contato-footer .form-wrapper .email-contato-wrapper {
  margin-top: 2.25rem;
}

.body_home #contato-footer .form-wrapper .email-contato-wrapper p {
  color: #000000b3;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Antonio;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_home #contato-footer .form-wrapper .email-contato-wrapper p .link, .body_home #contato-footer .form-wrapper .email-contato-wrapper .link {
  color: #1e1a34 !important;
}

.body_home #contato-footer .form-wrapper .button-wrapper {
  transition: all .2s ease-in;
}

.body_home #contato-footer .form-wrapper .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_home #contato-footer .form-wrapper .button-wrapper button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #fff;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

.body_home #contato-footer .form-wrapper .button-wrapper button span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #contato-footer .form-wrapper .button-wrapper button:active, .body_home #contato-footer .form-wrapper .button-wrapper button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_home #contato-footer .form-wrapper .button-wrapper button:active span, .body_home #contato-footer .form-wrapper .button-wrapper button:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_home #contato-footer .form-wrapper .button-wrapper button svg, .body_home #contato-footer .form-wrapper .button-wrapper button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_home #contato-footer .info-contato-container {
  margin-top: 2rem;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.125rem;
  display: flex;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato .block-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato .block-info {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

@media (max-width: 1650px) {
  .body_home #contato-footer .contato-grid-wide .folha-contato img {
    width: 80%;
  }

  .body_home #contato-footer .contato-grid-wide .detalhes-contato {
    right: -160px;
  }

  .body_home #contato-footer .contato-grid-wide .detalhes-contato img, .body_home #contato-footer .contato-grid-wide .nuvem-contato img, .body_home #contato-footer .contato-grid-wide .menina-conato img {
    width: 80%;
  }

  .body_home #contato-footer .contato-grid-wide .bg-contato {
    right: -160px;
  }

  .body_home #contato-footer .contato-grid-wide .bg-contato img {
    width: 80%;
  }
}

.body_home #numeros {
  margin-bottom: -55px;
}

.body_home #numeros .swiper-wrapper {
  transition-timing-function: linear;
}

.body_home #numeros .swiper-slide {
  border: 1px solid var(--ds-generics-white-20-op, #fff3);
  background: var(--Generics-White, #fff);
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 8px 24px #0000000f;
}

.body_home #numeros .numero-item {
  border-right: 1px solid #0f0f1266;
  align-items: center;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #numeros .numero-item {
    padding: 2rem 1.5rem;
  }
}

.body_home #numeros .numero-item .badge-cor:after {
  content: "";
  background-color: currentColor;
  width: 4px;
  height: 48px;
  display: block;
  position: absolute;
  top: 50%;
  left: -1px;
  transform: translateY(-50%);
}

.body_home #numeros .numero-item .badge-cor {
  text-align: center;
  letter-spacing: .96px;
  text-transform: uppercase;
  border-right: 1px solid #1e1a34;
  width: 100%;
  font-family: Antonio;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .body_home #numeros .numero-item .badge-cor {
    font-size: 2rem;
  }
}

.body_home #numeros .numero-item .badge-cor-1 {
  color: var(--bienal-vermelha);
}

.body_home #numeros .numero-item .badge-cor-2 {
  color: #00d980;
}

.body_home #numeros .numero-item .badge-cor-3 {
  color: #4a446a;
}

.body_home #numeros .numero-item .badge-cor-4 {
  color: #fa4616;
}

.body_home #numeros .numero-item p {
  color: #1e1a34;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .body_home #numeros .numero-item p {
    font-size: 1.5rem;
  }
}

.body_blog #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_blog #categorias .container {
  overflow: hidden;
}

.body_blog #categorias .tabs-wrapper {
  justify-content: space-between;
  display: flex;
}

.body_blog #categorias .tab {
  flex-basis: 33%;
}

.body_blog #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_blog #categorias .tab.active span {
  color: #000;
  background: #d6df2133;
}

.body_blog #categorias .tab:hover span {
  color: #000;
  background: #d6df2133;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_blog #categorias .tab a {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_blog #destaque {
  padding-top: 6rem;
}

.body_blog #destaque .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

.body_blog #destaque .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_blog #destaque .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_blog #destaque .swiper-destaque {
  margin-top: -104px;
  padding-top: 104px;
}

.body_blog #destaque .swiper-destaque .gatinho {
  z-index: 2;
  position: absolute;
  top: 8px;
  right: 140px;
}

@media screen and (max-width: 768px) {
  .body_blog #destaque .swiper-destaque .gatinho {
    display: none;
  }
}

.body_blog #destaque .swiper-destaque .livro-laranja {
  max-width: 122px;
  position: absolute;
  top: 48px;
  right: 5px;
}

@media screen and (max-width: 768px) {
  .body_blog #destaque .swiper-destaque .livro-laranja {
    display: none;
  }
}

.body_blog #destaque .swiper-destaque .swiper-wrapper {
  z-index: 2;
  position: relative;
}

.body_blog #destaque .swiper-destaque .swiper-slide {
  height: auto;
}

.body_blog #destaque .swiper-destaque .blog-card {
  border-radius: 3px;
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.body_blog #destaque .swiper-destaque .content-blog {
  color: #fff;
  background: #9b2743;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_blog #destaque .swiper-destaque .content-blog {
    flex: 1;
    padding: 1rem;
  }
}

.body_blog #destaque .swiper-destaque .content-blog .title-wrapper {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.body_blog #destaque .swiper-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .body_blog #destaque .swiper-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_blog #destaque .swiper-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_blog #destaque .swiper-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_blog #destaque .swiper-destaque .img-wrapper {
  flex: 1;
  padding-top: 52.15%;
  position: relative;
  overflow: hidden;
}

.body_blog #destaque .swiper-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card {
    display: flex;
  }
}

.body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card .content-blog {
  background-color: #9b2743;
}

.body_blog #destaque .swiper-destaque .swiper-slide:nth-child(2) .content-blog {
  background-color: #ff6a39;
}

.body_blog #todos-posts {
  margin-bottom: 7.5rem;
  padding-top: 4.75rem;
}

.body_blog #todos-posts .header-wrapper {
  background-color: var(--bienal-vermelha);
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

.body_blog #todos-posts .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

.body_blog #todos-posts .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_blog #todos-posts .header-wrapper .ornamente-peixe {
    max-width: 120px;
    top: calc(50% - 10px);
  }
}

.body_blog #todos-posts .blog-wrapper {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_blog #todos-posts .blog-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_blog #todos-posts .blog-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_blog #todos-posts .card-blog {
  border-radius: 3px;
  max-width: 600px;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .cat {
  background-color: var(--bienal-vermelha);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 16px;
  margin-bottom: .75rem;
  padding: 3px 1rem 0;
  font-size: .875rem;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
}

.body_blog #todos-posts .card-blog .cat.rosa {
  background-color: var(--bienal-rosa);
}

.body_blog #todos-posts .card-blog .cat.roxo {
  background-color: #ff6a39;
}

.body_blog #todos-posts .card-blog .img-wrapper {
  padding-top: 52.15%;
  position: relative;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_blog #todos-posts .card-blog .content-blog {
  background-color: #f3f3f5;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  .body_blog #todos-posts .card-blog .content-blog {
    padding: 1.5rem;
  }
}

.body_blog #todos-posts .card-blog .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .bottom-info-blog {
  color: #59595c;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_blog #todos-posts .card-blog .bottom-info-blog span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_blog #todos-posts .card-blog .bottom-info-blog svg {
  width: 24px;
  height: 24px;
}

.body_blog #todos-posts .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_blog #todos-posts .pagination .page-list a {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: .375rem .875rem;
  display: block;
}

.body_blog #todos-posts .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_blog #todos-posts .pagination li.current {
  background-color: #000;
}

.body_blog #todos-posts .pagination li.current a {
  color: #fff;
}

.body_blog #todos-posts .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: var(--bienal-amarelo);
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_blog #todos-posts .pagination .on-edges.left svg {
  align-self: flex-start;
  margin-right: 8px;
  transform: rotate(180deg);
}

.body_blog #todos-posts .pagination .on-edges.right svg {
  align-self: flex-start;
  margin-left: 8px;
}

.body_blog #todos-posts .pagination .on-edges a {
  align-items: center;
  display: flex;
}

.body_blog #todos-posts .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

@media (min-width: 1024px) {
  .body_single_blog, .body_single_blog main {
    overflow: unset;
  }

  .body_single_blog .side-bar {
    position: sticky;
    top: 180px;
  }
}

.body_single_blog #single-post {
  margin-bottom: 10rem;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post {
    margin-bottom: 6rem;
  }
}

.body_single_blog #single-post .container {
  padding-top: 4rem;
}

.body_single_blog #single-post .breadcrumbs {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .breadcrumbs {
    grid-template-columns: 325px 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .breadcrumbs {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .breadcrumbs ul {
  align-items: center;
  display: flex;
}

.body_single_blog #single-post .breadcrumbs ul .info-bread {
  margin-left: 5px;
}

.body_single_blog #single-post .breadcrumbs ul a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #59595c;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .breadcrumbs .icon-mail svg, .body_single_blog #single-post .breadcrumbs .right svg {
  width: 20px;
  height: 20px;
}

.body_single_blog #single-post .breadcrumbs .go-back {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.body_single_blog #single-post .breadcrumbs .go-back svg {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .breadcrumbs .go-back {
    display: none;
  }
}

.body_single_blog #single-post .single-title-wrapper {
  margin-bottom: 3.75rem;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .single-title-wrapper {
    grid-template-columns: 325px 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .single-title-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .single-title-wrapper .cat {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  border-radius: 16px;
  max-width: max-content;
  margin-bottom: 12px;
  padding: 5px 16px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_single_blog #single-post .single-title-wrapper .cat.rosa {
  background-color: var(--bienal-rosa);
}

.body_single_blog #single-post .single-title-wrapper .post-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #59595c;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_single_blog #single-post .div {
  max-width: 1055px;
}

.body_single_blog #single-post .main-img-wrapper {
  margin-bottom: 4rem;
  position: relative;
}

.body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper {
  z-index: 2;
  border-radius: 3px;
  max-width: 1055px;
  position: relative;
}

.body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  max-height: 473px;
}

.body_single_blog #single-post .degrade-bg {
  border-radius: 3px;
  max-width: 60%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .degrade-bg {
    left: 0;
    right: unset;
    max-width: 70%;
  }
}

.body_single_blog #single-post .degrade-bg img {
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .degrade-bg img {
    object-fit: cover;
    height: 140px;
  }
}

.body_single_blog #single-post .blog-content-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_single_blog #single-post .blog-content-wrapper {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .blog-content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .side-bar {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  width: 100%;
  max-width: 376px;
  padding: 16px;
}

@media screen and (max-width: 1024px) {
  .body_single_blog #single-post .side-bar {
    max-width: 100%;
  }
}

.body_single_blog #single-post .side-bar .title-block {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .destaque-side {
  color: #1e1a34;
  background-color: #99d6ea;
  border: 4px solid #ffcd29;
  margin-bottom: 1rem;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .destaque-side .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #1e1a34;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
}

.body_single_blog #single-post .side-bar .destaque-side p {
  letter-spacing: -.02em;
  color: #1e1a34;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .categorias {
  border-top: 1px solid #0f0f121a;
  border-bottom: 1px solid #0f0f121a;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .categorias li {
  margin-bottom: 5px;
}

.body_single_blog #single-post .side-bar .categorias a {
  letter-spacing: -.02em;
  color: #36363b;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .escrito-wrapper {
  padding: 12px;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper {
  align-items: center;
  gap: 8px;
  display: flex;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-img-wrapper {
  border-radius: 50%;
  overflow: hidden;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-img-wrapper img {
  width: 56px;
  height: 56px;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-info .nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-info p {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper {
  border-top: 1px solid #0f0f121a;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons {
  align-items: center;
  gap: 8px;
  display: flex;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li svg {
  width: 1.5rem;
  height: 1.5rem;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li a {
  cursor: pointer;
  border: 1px solid var(--Principais-Azul-Jabu, #1e1a34);
  background-color: #fff;
  border-radius: 32px;
  align-items: center;
  padding: 8px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  box-shadow: -3px 3px #f2a900;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_single_blog #single-post .blog-content {
  width: 100%;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .body_single_blog #single-post .blog-content {
    grid-row: 1;
    padding-right: 0;
  }
}

.body_single_blog #veja-mais {
  margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais {
    margin-bottom: 2rem;
  }
}

.body_single_blog #veja-mais .header-wrapper {
  background-color: var(--bienal-vermelha);
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

.body_single_blog #veja-mais .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

.body_single_blog #veja-mais .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .header-wrapper .ornamente-peixe {
    max-width: 120px;
    display: none;
    top: calc(50% - 10px);
  }
}

.body_single_blog #veja-mais .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_single_blog #veja-mais .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_single_blog #veja-mais .blog-card {
  border-radius: 3px;
  overflow: hidden;
}

.body_single_blog #veja-mais .blog-card.blog-destaque.half {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque.half {
    flex-direction: column;
    grid-template-rows: 60% 40%;
    grid-template-columns: 1fr;
    display: flex;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque.half-bottom {
  grid-template-rows: 60% 40%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque.half-bottom {
    flex-direction: column;
    display: flex;
  }

  .body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper {
    flex: 1;
    padding-top: 75%;
    position: relative;
    overflow: hidden;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog {
  color: #fff;
  background: #9b2743;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog {
    padding: 1rem;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media (min-width: 769px) {
  .body_single_blog #veja-mais .swiper-midia {
    display: none;
  }
}

.body_single_blog #veja-mais .swiper-midia {
  overflow: visible;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide {
  height: auto;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card .img-wrapper {
  flex: 1;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card .content-blog {
  flex: unset;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:first-child .content-blog {
  background-color: #9b2743;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(2) .content-blog {
  background-color: #ff6a39;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(3) .content-blog {
  background-color: #5cbde5;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(4) .content-blog {
  background-color: #f2a900;
}

.body_single_blog #veja-mais .blog-grid-wrapper {
  grid-template-rows: 27.8125rem 27.8125rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-rows: 25rem 25rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child {
  grid-column: 1 / 4;
  grid-template-columns: 1.6fr 1.4fr;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child {
    grid-template-columns: 1fr;
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child .content-blog {
  background-color: #9b2743;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child img {
  max-height: 445px;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) {
  grid-column: 4 / 6;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) {
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) .content-blog {
  background-color: #ff6a39;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) {
  grid-area: 2 / 1 / auto / 3;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) .content-blog {
  background-color: #5cbde5;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) {
  grid-area: 2 / 3 / auto / 5;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) .content-blog {
  background-color: #f2a900;
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
  background: #fff;
  border: 1px solid #0f0f12;
  border-radius: 8px;
  place-content: center;
  padding: 1rem;
  display: grid;
  box-shadow: -3px 3px #f2a900;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
    grid-column: span 2;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias svg {
  width: 20px;
  height: 21px;
  margin-bottom: 5px;
}

.body_eventos #todas-atracoes .ornamento-peixe {
  position: absolute;
  top: -69px;
  left: -51px;
  transform: rotate(130deg);
}

.body_eventos #todas-atracoes .ornamento-peixe img {
  max-width: 120px;
}

.body_eventos #todas-atracoes .title-wrapper {
  margin-bottom: 2rem;
}

.body_eventos #todas-atracoes .title-wrapper h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

.body_eventos #todas-atracoes .lupa-title-wraper {
  justify-content: space-between;
  display: flex;
}

.body_eventos #todas-atracoes .busca {
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  color: #000;
  background: #fff;
  border-radius: 52px;
  justify-content: space-between;
  align-items: center;
  width: 352px;
  padding: 12px;
  display: flex;
}

.body_eventos #todas-atracoes .busca .svg-lupa {
  width: 32px;
  height: 32px;
}

.body_eventos #todas-atracoes .busca ::placeholder {
  color: #000;
  text-transform: uppercase;
}

.body_eventos .pagination {
  margin: 64px 0 180px;
}

.body_eventos .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_eventos .pagination .page-list a {
  padding: .375rem .875rem;
  display: block;
}

.body_eventos .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 32px;
  align-items: center;
  padding: 6px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: block;
  box-shadow: -3px 3px #f2a900;
}

.body_eventos .pagination li:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_eventos .pagination li.current {
  background-color: var(--bienal-vermelha);
}

.body_eventos .pagination li.current a {
  color: #fff;
}

.body_eventos .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: var(--bienal-vermelha);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_eventos .pagination .on-edges.left svg {
  align-self: flex-start;
  margin-right: 8px;
  transform: rotate(180deg);
}

.body_eventos .pagination .on-edges.right svg {
  align-self: flex-start;
  margin-left: 8px;
}

.body_eventos .pagination .on-edges a {
  align-items: center;
  display: flex;
  color: #fff !important;
}

.body_eventos .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 875px) {
  .body_eventos #todas-atracoes .lupa-title-wraper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .body_eventos #todas-atracoes .lupa-title-wraper .busca {
    margin-top: 24px;
  }

  .body_eventos .pagination {
    margin: 34px 0 60px;
  }
}

.body-o-evento #o-evento {
  position: relative;
}

.body-o-evento #o-evento .header-linear-mobile {
  background-size: cover;
  height: 62px;
  display: none;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .header-linear-mobile {
    display: block;
  }
}

.body-o-evento #o-evento .header-linear-mobile .alien-mobile {
  max-width: 69px;
  position: absolute;
  bottom: -10px;
  right: 16px;
}

.body-o-evento #o-evento .header-linear-mobile .livro-rosa {
  max-width: 83px;
  position: absolute;
  bottom: 0;
  right: 95px;
}

.body-o-evento #o-evento .vertical-wrapper {
  width: 100%;
  max-width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .vertical-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .vertical-wrapper {
    left: -13px;
  }
}

.body-o-evento #o-evento .vertical-wrapper .linear {
  height: 100%;
}

.body-o-evento #o-evento .vertical-wrapper .linear img {
  object-fit: cover;
  width: 100%;
  max-width: 130px;
  height: 100%;
  margin: 0 auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper {
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4.375rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .alienigena-orna {
  max-width: 105px;
  margin-left: auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .menina-voando {
  margin-left: auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .peixe-wrapper {
  max-width: 140px;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .menina-livro {
  margin-left: auto;
}

@media (max-width: 1800px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 200px;
  }
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 190px;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 0;
  }
}

.body-o-evento #o-evento .mao-wrapper {
  position: absolute;
  top: 6rem;
  right: 0;
}

@media (max-width: 1800px) {
  .body-o-evento #o-evento .mao-wrapper {
    display: none;
  }
}

.body-o-evento #o-evento .container-o-evento {
  max-width: calc(1300px + 3rem);
  margin: 0 auto;
}

.body-o-evento #o-evento .header-wrapper {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 4.5rem;
  padding-top: 6rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .header-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .header-wrapper {
    margin-top: -9px;
    padding-top: 0;
  }
}

.body-o-evento #o-evento .header-wrapper .info-evento {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body-o-evento #o-evento .header-wrapper .info-evento p {
  text-align: justify;
  margin-bottom: 1rem;
}

.body-o-evento #o-evento .info-list-grid {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-bottom: 8.75rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid {
    grid-template-columns: 1fr;
  }
}

.body-o-evento #o-evento .info-list-grid .col-1 {
  max-width: 512px;
  margin-right: 3rem;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    margin-right: 0;
    max-width: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    max-width: unset !important;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    margin-right: 0;
  }
}

.body-o-evento #o-evento .info-list-grid .col-1 .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.06;
}

.body-o-evento #o-evento .info-list-grid .col-1 .sub-title {
  text-align: justify;
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  line-height: 1.4;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper {
  margin-top: 2rem;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li {
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li p {
  letter-spacing: -.02em;
  color: #0f0f12;
  font-size: 1rem;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .col-2 {
    grid-row-start: 1;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img {
  margin-left: auto;
  margin-right: 25px;
  padding-left: 70px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img {
    margin-right: 0;
    padding-left: 0;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
  position: absolute;
  left: -27px;
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
    transform-origin: top;
    transform: scale(.7);
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
    top: -32px;
    left: 5px;
    transform: rotate(30deg);
  }

  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna img {
    max-width: 47px;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
  z-index: 3;
  position: absolute;
  bottom: 4.375rem;
  right: -26px;
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
    transform-origin: center;
    transform: scale(.7);
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
    transform-origin: 100% 100%;
    bottom: 1rem;
    right: 0;
    transform: scale(.6);
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .img-wrapper {
  z-index: 2;
  background-repeat: no-repeat;
  background-size: calc(100% - 55px);
  padding: 1rem 0 1rem 1rem;
  position: relative;
}

.body-o-evento #o-evento .info-list-grid .col-2 .img-wrapper img {
  width: 100%;
}

.body-o-evento #o-evento .info-list-grid .sub-title-2 {
  text-align: justify;
  letter-spacing: -.02em;
  color: #36363b;
  grid-column: span 2;
  font-size: 1.125rem;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .sub-title-2 {
    grid-column: 1;
  }
}

.body-o-evento #cta-programacao {
  padding: 10rem 0;
}

@media screen and (max-width: 768px) {
  .body-o-evento #cta-programacao {
    padding: 4rem 0;
  }
}

.body-o-evento #cta-programacao .container {
  max-width: calc(1328px + 3rem);
  position: relative;
}

.body-o-evento #cta-programacao .flor-dali {
  position: absolute;
  top: -10px;
  left: -80px;
}

@media screen and (max-width: 768px) {
  .body-o-evento #cta-programacao .flor-dali {
    display: none;
  }
}

.body-o-evento #cta-programacao .flor-dali img {
  max-width: 68px;
}

.body-o-evento #cta-programacao .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 3.5rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.14;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .title {
    font-size: 20px;
  }
}

.body-o-evento #cta-programacao .buttons-cta {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .buttons-cta {
    grid-template-columns: 1fr;
  }
}

.body-o-evento #cta-programacao .buttons-cta .gatinho-orna {
  position: absolute;
  top: -65px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .buttons-cta .gatinho-orna {
    display: none;
  }
}

.body-o-evento #cta-programacao .buttons-cta .gatinho-orna img {
  max-width: 73px;
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper {
  z-index: 2;
  position: relative;
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  background: #fbfce9;
  border: 1px solid #0c1428;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .buttons-cta .button-wrapper .button {
    font-size: 16px;
  }
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper {
  background-color: var(--bienal-rosa);
  border-radius: 50%;
  place-content: center;
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper {
    width: 32px;
    height: 32px;
  }
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body-o-evento #faq {
  color: #fff;
  margin-bottom: 3.25rem;
  position: relative;
}

.body-o-evento #faq .grid-faq {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .grid-faq {
    display: block;
  }
}

.body-o-evento #faq .col-1 {
  z-index: 2;
  min-height: 710px;
  padding-top: 120px;
  padding-left: 6vw;
  position: relative;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 {
    min-height: unset;
    padding-top: 64px;
    padding-bottom: 150px;
    padding-right: 1.5rem;
  }
}

.body-o-evento #faq .col-1 .img-wrapper-mulher {
  width: 100%;
  max-width: 50vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .img-wrapper-mulher {
    max-width: 100%;
  }
}

.body-o-evento #faq .col-1 .img-wrapper-mulher img {
  object-fit: cover;
  object-position: bottom right;
  width: 100%;
  max-width: 50vw;
  height: 100%;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .img-wrapper-mulher img {
    object-position: bottom right;
    max-width: 100%;
  }
}

.body-o-evento #faq .col-1 .section-title {
  max-width: 511px;
  margin-right: 4.375rem;
  position: relative;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .section-title {
    margin-right: 0;
  }
}

.body-o-evento #faq .col-1 .section-title .alien-orna {
  position: absolute;
  top: 0;
  right: -75px;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #faq .col-1 .section-title .alien-orna {
    display: none;
  }
}

.body-o-evento #faq .col-1 .section-title h2 {
  color: #fff;
  max-width: 15ch;
}

.body-o-evento #faq .col-1 .section-title .section-description {
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.body-o-evento #faq .col-1 .section-title a {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

@media screen and (max-width: 768px) {
  .body-o-evento #faq .col-1 .section-title a {
    font-size: 1.25rem;
  }
}

.body-o-evento #faq .col-1 .section-title .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

.body-o-evento #faq .col-1 .section-title .button span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body-o-evento #faq .col-1 .section-title .button:active, .body-o-evento #faq .col-1 .section-title .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body-o-evento #faq .col-1 .section-title .button:active span, .body-o-evento #faq .col-1 .section-title .button:hover span {
  transform: translateZ(0)perspective(1px);
}

.body-o-evento #faq .col-1 .section-title .button svg, .body-o-evento #faq .col-1 .section-title .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body-o-evento #faq .col-1 .section-title .button svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  margin-right: 0;
}

.body-o-evento #faq .col-2 {
  background-color: var(--bienal-rosa);
  padding-top: 120px;
  padding-left: 7.5rem;
  padding-right: 1.5rem;
  position: relative;
}

@media (min-width: 1800px) {
  .body-o-evento #faq .col-2 {
    padding-right: 7.5rem;
  }
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 6vw;
  }
}

.body-o-evento #faq .col-2 .robo-wrapper-faq {
  position: absolute;
  top: 0;
  left: -72px;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 .robo-wrapper-faq {
    z-index: 3;
    max-width: 105px;
    top: -55px;
    left: -15px;
  }
}

.body-o-evento #faq .col-2 .robo-wrapper-faq img {
  transform: rotate(-1deg);
}

.body-o-evento #faq .col-2 .content-faq {
  max-width: 697px;
}

.body-o-evento #faq .col-2 .content-faq .ul-faq {
  max-height: 24rem;
  overflow-y: scroll;
}

@media (max-width: 1440px) {
  .body-o-evento #faq .col-2 .content-faq .ul-faq {
    max-height: 25rem;
  }
}

.body-o-evento #faq .col-2 .section-title {
  margin-bottom: 6.25rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 .section-title {
    margin-bottom: 2rem;
  }
}

.body-o-evento #faq .col-2 .section-title:after {
  content: "";
  background-color: #fff3;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  top: 50%;
  left: 129px;
  right: 0;
}

.body-o-evento #faq .col-2 .section-title .badge-wrapper {
  margin-bottom: 0;
}

.body-o-evento #faq .col-2 .item-faq {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.body-o-evento #faq .col-2 .item-faq:after {
  content: "";
  background-color: var(--bienal-amarelo);
  width: 0;
  height: 4px;
  transition: width .2s ease-out .1s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.body-o-evento #faq .col-2 .item-faq a {
  color: var(--white);
}

.body-o-evento #faq .col-2 .item-faq .header {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.body-o-evento #faq .col-2 .item-faq .header .titulo {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
}

.body-o-evento #faq .col-2 .item-faq .header .icon-wrapper {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  flex-shrink: 0;
  place-content: center;
  width: 40px;
  height: 40px;
  display: grid;
}

.body-o-evento #faq .col-2 .item-faq .header .icon-wrapper svg {
  width: 1.5rem;
  height: 1.5rem;
}

.body-o-evento #faq .col-2 .item-faq .faq-content {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -o-transition: max-height .3s ease-in-out;
  max-height: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.44;
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

.body-o-evento #faq .col-2 .item-faq.active .faq-content {
  max-height: 300px;
  padding-top: 11px;
}

.body-o-evento #faq .col-2 .item-faq.active .icon-wrapper {
  transition: all .2s;
  transform: rotate(180deg);
}

.body-o-evento #faq .col-2 .item-faq.active:after {
  width: 100%;
}

.body-o-evento #banner-patrocinio .balao-wrapper {
  left: 1.5rem;
  right: unset;
}

.body_quemsomos #quem-somos {
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos {
    padding-top: 2rem;
  }
}

.body_quemsomos #quem-somos .container-quem-somos {
  max-width: calc(1300px + 3rem);
  margin: 0 auto;
}

.body_quemsomos #quem-somos .mao-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 1700px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    max-width: 140px;
    display: block;
    top: 140px;
  }
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    top: 100px;
  }
}

.body_quemsomos #quem-somos .header-wrapper {
  grid-template-columns: .6fr 2fr;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 5.75rem;
  padding-top: 6rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .header-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .body_quemsomos #quem-somos .header-wrapper {
    margin-top: -9px;
    padding-top: 3rem;
  }
}

.body_quemsomos #quem-somos .header-wrapper .alien-balao {
  max-width: 230px;
  margin-top: 1.875rem;
  transform: rotate(12.93deg);
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .header-wrapper .alien-balao {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .balao-wrapper {
  max-width: 70px;
  display: block;
  position: absolute;
  top: 50%;
  left: -163px;
  transform: translateY(-50%);
}

@media (max-width: 1800px) {
  .body_quemsomos #quem-somos .header-wrapper .balao-wrapper {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left {
  flex-shrink: 0;
  align-self: stretch;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left img {
  object-fit: fill;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right {
  flex-shrink: 0;
  align-self: stretch;
  position: relative;
  top: 4px;
  transform: rotate(-180deg);
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right img {
  object-fit: fill;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .content-for-sobre {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .content-for-sobre p {
  margin-bottom: 1rem;
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide {
  width: max-content;
  height: 392px;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide {
    height: 320px;
  }
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide .foto-item {
  height: 100%;
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide .foto-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_quemsomos #nossa-historia {
  padding: 10rem 0 5.5rem;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #nossa-historia {
    padding: 4rem 0 2.5rem;
  }
}

.body_quemsomos #nossa-historia .header-wrapper {
  background: url("footer-linear.f1b78cf0.webp") 0 0 / cover no-repeat;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .header-wrapper {
    padding: 16px;
  }
}

.body_quemsomos #nossa-historia .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .header-wrapper h2 {
    max-width: 21ch;
  }
}

.body_quemsomos #nossa-historia .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_quemsomos #nossa-historia .header-wrapper .ornamente-peixe {
    max-width: 120px;
    display: none;
    top: calc(50% - 10px);
  }
}

.body_quemsomos #nossa-historia .swiper-controls-header {
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .swiper-controls-header {
    margin-top: 1rem;
  }
}

.body_quemsomos #nossa-historia .swiper-controls-header .pagination {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #e3e3e6;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #000;
}

.body_quemsomos #nossa-historia .nossa-historia-wrapper .slide-item-wrapper {
  padding-top: 86.72%;
  position: relative;
  overflow: hidden;
}

.body_quemsomos #nossa-historia .nossa-historia-wrapper .slide-item-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (min-width: 1024px) {
  .body_programacao {
    overflow-x: initial;
  }

  .body_programacao main {
    overflow: initial;
  }
}

.body_programacao #programacao-rolling .swiper-infinite-programacao .swiper-wrapper {
  transition-timing-function: linear;
}

.body_programacao #programacao-rolling .swiper-infinite-programacao .swiper-slide {
  width: max-content;
  max-width: 100%;
}

.body_programacao #programacao-rolling .item-infinite {
  align-items: center;
  display: flex;
}

.body_programacao #programacao-rolling .item-infinite h2 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #e3e3e6;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1440px) {
  .body_programacao #programacao-rolling .item-infinite h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao-rolling .item-infinite h2 {
    font-size: 4rem;
  }
}

.body_programacao #programacao-rolling .item-infinite .dot-right {
  background-color: #e3e3e6;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 64px;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao-rolling .item-infinite .dot-right {
    margin-left: 40px;
  }
}

.body_programacao #base-banner .detail-bottom {
  background-color: #a3238e;
}

.body_programacao #programacao .grid-programacao {
  grid-template-columns: 23.4375rem 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_programacao #programacao .grid-programacao {
    grid-template-columns: 19rem 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .grid-programacao {
    grid-template-columns: 1fr;
  }
}

.body_programacao #programacao .div-to-sticky {
  position: sticky;
  top: 140px;
}

.body_programacao #programacao .div-to-sticky .img-menina {
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .div-to-sticky .img-menina {
    display: none;
  }
}

.body_programacao #programacao .div-to-sticky .filtro-title {
  letter-spacing: -.32px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.body_programacao #programacao .div-to-sticky .accordion {
  margin-bottom: 10px;
  display: block;
}

.body_programacao #programacao .div-to-sticky .accordion-header {
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 10px;
}

.body_programacao #programacao .div-to-sticky .accordion-content {
  padding: 10px;
  display: none;
}

.body_programacao #programacao .div-to-sticky .espacos {
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background: var(--generics-white, #fff);
  border-radius: 3px;
  margin-bottom: 15px;
  display: none;
  transform: translateY(-20px);
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_programacao #programacao .div-to-sticky .espacos .espaco-item {
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  letter-spacing: -.32px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 12px;
  padding: 9px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  transition: all .3s ease-in-out;
  display: flex;
}

.body_programacao #programacao .div-to-sticky .espacos .espaco-item svg {
  width: 24px;
  height: 24px;
}

.body_programacao #programacao .div-to-sticky .espacos .espaco-item:hover {
  transition: all .3s ease-in-out;
  transform: scale(1.01);
}

.body_programacao #programacao .div-to-sticky .espacos.open {
  display: block;
}

.body_programacao #programacao .div-to-sticky .custom-select {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  position: relative;
}

.body_programacao #programacao .div-to-sticky .custom-select select {
  display: none;
}

.body_programacao #programacao .div-to-sticky .select-selected {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}

.body_programacao #programacao .div-to-sticky .select-selected .svg-pesquisa {
  width: 32px;
  height: 32px;
  transition: all .2s ease-in-out;
  transform: rotate(180deg);
}

.body_programacao #programacao .div-to-sticky .select-items {
  z-index: 1;
  border: 1px solid #ccc;
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background-color: #f9f9f9;
  background: var(--generics-white, #fff);
  border-radius: 3px;
  max-height: 150px;
  display: none;
  position: absolute;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_programacao #programacao .div-to-sticky .select-items div {
  cursor: pointer;
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  border-radius: 3px;
  width: 375px;
  margin-top: 8px;
  padding: 8px 10px;
}

.body_programacao #programacao .div-to-sticky .select-items div:hover {
  background-color: #ddd;
}

.body_programacao #programacao .filtrar-por-data {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .filtrar-por-data {
    display: block;
  }
}

.body_programacao #programacao .filtrar-por-data .flex-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_programacao #programacao .filtrar-por-data p {
  letter-spacing: -.02em;
  color: #0f0f1266;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.body_programacao #programacao .filtrar-por-data svg {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.body_programacao #programacao .filtrar-por-data .svg-pesquisa.active {
  transition: all .2s ease-in-out;
  transform: rotate(0);
}

.body_programacao #programacao .filtrar-por-espaco {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .filtrar-por-espaco {
    display: block;
  }
}

.body_programacao #programacao .filtrar-por-espaco .flex-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_programacao #programacao .filtrar-por-espaco p {
  letter-spacing: -.02em;
  color: #0f0f1266;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.body_programacao #programacao .filtrar-por-espaco .svg-pesquisa {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  transition: all .2s ease-in-out;
  transform: rotate(180deg);
}

.body_programacao #programacao .filtrar-por-espaco .svg-pesquisa.active {
  transition: all .2s ease-in-out;
  transform: rotate(0);
}

.body_programacao #programacao .info-download-wrapper {
  margin-top: 2rem;
  display: none;
}

.body_programacao #programacao .info-download-wrapper p {
  color: #5e5c66;
  margin-bottom: 16px;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body_programacao #programacao .info-download-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 32px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
  background: #fff !important;
  border: 1px solid #1e1a34 !important;
}

.body_programacao #programacao .info-download-wrapper .button span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_programacao #programacao .info-download-wrapper .button:active, .body_programacao #programacao .info-download-wrapper .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_programacao #programacao .info-download-wrapper .button:active span, .body_programacao #programacao .info-download-wrapper .button:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_programacao #programacao .info-download-wrapper .button svg, .body_programacao #programacao .info-download-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_programacao #programacao .info-download-wrapper .button svg {
  width: 20px;
  height: 20px;
}

.body_programacao #programacao .sidebar {
  background-color: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .sidebar {
    border-width: 0;
    max-height: 0;
    padding: 0;
    transition: all .2s ease-in;
    overflow: hidden;
  }

  .body_programacao #programacao .sidebar.open {
    border-width: 1px;
    max-height: 1000px;
  }
}

.body_programacao #programacao .sidebar .dias-wrapper li {
  cursor: pointer;
  padding: 12px;
}

.body_programacao #programacao .sidebar .dias-wrapper li .dia-semana {
  color: #000000b3;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_programacao #programacao .sidebar .dias-wrapper li .dia-data {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_programacao #programacao .sidebar .dias-wrapper li.active {
  color: #fff;
  background-color: #99d6ea;
  border: 4px solid #ffcd29;
  padding: 12px;
}

.body_programacao #programacao .programacoes {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  display: grid;
}

.body_programacao #programacao .programacao {
  margin-bottom: 2rem;
}

.body_programacao #programacao .horario-wrapper {
  background: #fff;
  border: 1px solid #e60043;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .horario-wrapper {
    padding: 1rem;
  }
}

.body_programacao #programacao .horario-wrapper span {
  color: #1e1a34;
  margin: 0 5px;
  font-size: 24px;
}

.body_programacao #programacao .horario-wrapper p {
  color: #1e1a34;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_programacao #programacao .horario-wrapper .local {
  text-transform: none;
}

.body_programacao #programacao .horario-wrapper .horario-h {
  text-transform: none;
  margin-right: 0;
}

.body_programacao #programacao .swiper-programacao-atracoes {
  width: 100%;
  display: grid;
}

.body_programacao #programacao .swiper-controls-header .mobile-swiper-icon {
  width: 24px;
  height: 24px;
  display: none;
}

@media screen and (max-width: 768px) {
  .body_programacao #programacao .swiper-controls-header .mobile-swiper-icon {
    display: block;
  }
}

.body_programacao #programacao .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_programacao #programacao .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_programacao #programacao .swiper-controls-header .buttons .button {
    display: none;
  }
}

.body_programacao #programacao .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_programacao #programacao .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_programacao #programacao .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_programacao #programacao .swiper-controls-header .buttons .swiper-button-lock {
  display: none;
}

.body_programacao #programacao .dia-atracoes-wrapper {
  color: #fff;
  background-color: #4a446a;
  border-radius: 3px;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem;
  display: grid;
}

.body_programacao #programacao .dia-atracoes-wrapper .jornada-wrapper {
  border-bottom: 1px solid #fff3;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.body_programacao #programacao .dia-atracoes-wrapper .jornada-title {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_programacao #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
  color: #ffffffb3;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.body_programacao #programacao .dia-atracoes-wrapper .content-for-p.open p:not(.continuar-lendo) {
  display: block;
}

.body_programacao #programacao .dia-atracoes-wrapper .continuar-lendo {
  cursor: pointer;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5%;
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .dia-atracoes-wrapper .continuar-lendo {
    display: flex;
  }
}

.body_programacao #programacao .header-atracoes {
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 11px;
  display: flex;
}

.body_programacao #programacao .header-atracoes .header-atracoes-titulo {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_programacao #programacao .card-programacao {
  cursor: pointer;
}

.body_programacao #programacao .card-programacao .card-evento-img {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_programacao #programacao .card-programacao .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_programacao #programacao .card-programacao .card-evento-title {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.body_programacao #programacao .card-programacao .card-evento-role {
  color: #fff9;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

@media (min-width: 1024px) {
  .body_programacao_expositor {
    overflow-x: initial;
  }

  .body_programacao_expositor main {
    overflow: initial;
  }
}

.body_programacao_expositor #programacao-rolling .swiper-infinite-programacao .swiper-wrapper {
  transition-timing-function: linear;
}

.body_programacao_expositor #programacao-rolling .swiper-infinite-programacao .swiper-slide {
  width: max-content;
  max-width: 100%;
}

.body_programacao_expositor #programacao-rolling .item-infinite {
  align-items: center;
  display: flex;
}

.body_programacao_expositor #programacao-rolling .item-infinite h2 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #e3e3e6;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1440px) {
  .body_programacao_expositor #programacao-rolling .item-infinite h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao-rolling .item-infinite h2 {
    font-size: 4rem;
  }
}

.body_programacao_expositor #programacao-rolling .item-infinite .dot-right {
  background-color: #e3e3e6;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 64px;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao-rolling .item-infinite .dot-right {
    margin-left: 40px;
  }
}

.body_programacao_expositor #base-banner .detail-bottom {
  background-color: #a3238e;
}

.body_programacao_expositor #programacao .grid-programacao {
  grid-template-columns: 23.4375rem 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_programacao_expositor #programacao .grid-programacao {
    grid-template-columns: 19rem 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .grid-programacao {
    grid-template-columns: 1fr;
  }
}

.body_programacao_expositor #programacao .div-to-sticky {
  position: sticky;
  top: 140px;
}

.body_programacao_expositor #programacao .div-to-sticky .img-menina {
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .div-to-sticky .img-menina {
    display: none;
  }
}

.body_programacao_expositor #programacao .div-to-sticky .filtro-title {
  letter-spacing: -.32px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.body_programacao_expositor #programacao .div-to-sticky .accordion {
  margin-bottom: 10px;
  display: block;
}

.body_programacao_expositor #programacao .div-to-sticky .accordion-header {
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 10px;
}

.body_programacao_expositor #programacao .div-to-sticky .accordion-content {
  padding: 10px;
  display: none;
}

.body_programacao_expositor #programacao .div-to-sticky .espacos {
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background: var(--generics-white, #fff);
  border-radius: 3px;
  margin-bottom: 15px;
  display: none;
  transform: translateY(-20px);
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_programacao_expositor #programacao .div-to-sticky .espacos .espaco-item {
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  letter-spacing: -.32px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 12px;
  padding: 9px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  transition: all .3s ease-in-out;
  display: flex;
}

.body_programacao_expositor #programacao .div-to-sticky .espacos .espaco-item svg {
  width: 24px;
  height: 24px;
}

.body_programacao_expositor #programacao .div-to-sticky .espacos .espaco-item:hover {
  transition: all .3s ease-in-out;
  transform: scale(1.01);
}

.body_programacao_expositor #programacao .div-to-sticky .espacos.open {
  display: block;
}

.body_programacao_expositor #programacao .div-to-sticky .custom-select {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  position: relative;
}

.body_programacao_expositor #programacao .div-to-sticky .custom-select select {
  display: none;
}

.body_programacao_expositor #programacao .div-to-sticky .select-selected {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}

.body_programacao_expositor #programacao .div-to-sticky .select-selected .svg-pesquisa {
  width: 32px;
  height: 32px;
}

.body_programacao_expositor #programacao .div-to-sticky .select-items {
  z-index: 1;
  border: 1px solid #ccc;
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background-color: #f9f9f9;
  background: var(--generics-white, #fff);
  border-radius: 3px;
  max-height: 150px;
  display: none;
  position: absolute;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_programacao_expositor #programacao .div-to-sticky .select-items div {
  cursor: pointer;
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  border-radius: 3px;
  width: 375px;
  margin-top: 8px;
  padding: 8px 10px;
}

.body_programacao_expositor #programacao .div-to-sticky .select-items div:hover {
  background-color: #ddd;
}

.body_programacao_expositor #programacao .filtrar-por-data {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .filtrar-por-data {
    display: block;
  }
}

.body_programacao_expositor #programacao .filtrar-por-data .flex-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_programacao_expositor #programacao .filtrar-por-data p {
  letter-spacing: -.02em;
  color: #0f0f1266;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.body_programacao_expositor #programacao .filtrar-por-data svg {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
}

.body_programacao_expositor #programacao .filtrar-por-espaco {
  cursor: pointer;
  background: #fff;
  border: 1px solid #1e1a34;
  border-radius: 52px;
  margin-bottom: 1rem;
  padding: 12px 16px;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .filtrar-por-espaco {
    display: block;
  }
}

.body_programacao_expositor #programacao .filtrar-por-espaco .flex-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_programacao_expositor #programacao .filtrar-por-espaco p {
  letter-spacing: -.02em;
  color: #0f0f1266;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.body_programacao_expositor #programacao .filtrar-por-espaco .svg-pesquisa {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.body_programacao_expositor #programacao .info-download-wrapper {
  margin-top: 2rem;
  display: none;
}

.body_programacao_expositor #programacao .info-download-wrapper p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.body_programacao_expositor #programacao .info-download-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 28px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #f2a900;
}

.body_programacao_expositor #programacao .info-download-wrapper .button span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_programacao_expositor #programacao .info-download-wrapper .button:active, .body_programacao_expositor #programacao .info-download-wrapper .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_programacao_expositor #programacao .info-download-wrapper .button:active span, .body_programacao_expositor #programacao .info-download-wrapper .button:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_programacao_expositor #programacao .info-download-wrapper .button svg, .body_programacao_expositor #programacao .info-download-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_programacao_expositor #programacao .info-download-wrapper .button svg {
  width: 20px;
  height: 20px;
}

.body_programacao_expositor #programacao .sidebar {
  background-color: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .sidebar {
    border-width: 0;
    max-height: 0;
    padding: 0;
    transition: all .2s ease-in;
    overflow: hidden;
  }

  .body_programacao_expositor #programacao .sidebar.open {
    border-width: 1px;
    max-height: 1000px;
  }
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li {
  cursor: pointer;
  padding: 12px;
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li .dia-semana {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1280;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li .dia-data {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li.active {
  color: #fff;
  background-color: #99d6ea;
  border: 4px solid #ffcd29;
  padding: 12px;
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li.active .dia-semana {
  color: #1e1a34;
  font-weight: 700;
}

.body_programacao_expositor #programacao .sidebar .dias-wrapper li.active .dia-data {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
}

.body_programacao_expositor #programacao .programacoes {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  display: grid;
}

.body_programacao_expositor #programacao .programacao {
  margin-bottom: 2rem;
}

.body_programacao_expositor #programacao .horario-wrapper {
  background: #fff;
  border: 1px solid #a3238e;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .horario-wrapper {
    padding: 1rem;
  }
}

.body_programacao_expositor #programacao .horario-wrapper span {
  color: #a3238e;
  margin: 0 5px;
  font-size: 24px;
}

.body_programacao_expositor #programacao .horario-wrapper p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 24px;
  font-weight: 600;
  line-height: 132%;
}

.body_programacao_expositor #programacao .horario-wrapper .local {
  text-transform: none;
}

.body_programacao_expositor #programacao .horario-wrapper .horario-h {
  text-transform: none;
  margin-right: 0;
}

.body_programacao_expositor #programacao .swiper-programacao-atracoes {
  width: 100%;
  display: grid;
}

.body_programacao_expositor #programacao .swiper-controls-header .mobile-swiper-icon {
  width: 24px;
  height: 24px;
  display: none;
}

@media screen and (max-width: 768px) {
  .body_programacao_expositor #programacao .swiper-controls-header .mobile-swiper-icon {
    display: block;
  }
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_programacao_expositor #programacao .swiper-controls-header .buttons .button {
    display: none;
  }
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_programacao_expositor #programacao .swiper-controls-header .buttons .swiper-button-lock {
  display: none;
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper {
  color: #fff;
  background-color: #a3238e;
  border-radius: 3px;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem;
  display: grid;
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper .jornada-wrapper {
  border-bottom: 1px solid #fff3;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper .jornada-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 16px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.3;
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
  letter-spacing: -.02em;
  color: #fff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.44;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper .content-for-p.open p:not(.continuar-lendo) {
  display: block;
}

.body_programacao_expositor #programacao .dia-atracoes-wrapper .continuar-lendo {
  cursor: pointer;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5%;
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_programacao_expositor #programacao .dia-atracoes-wrapper .continuar-lendo {
    display: flex;
  }
}

.body_programacao_expositor #programacao .header-atracoes {
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 11px;
  display: flex;
}

.body_programacao_expositor #programacao .header-atracoes .header-atracoes-titulo {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
}

.body_programacao_expositor #programacao .card-programacao {
  cursor: pointer;
}

.body_programacao_expositor #programacao .card-programacao .card-evento-img {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_programacao_expositor #programacao .card-programacao .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_programacao_expositor #programacao .card-programacao .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 5px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: -webkit-box;
  overflow: hidden;
}

.body_programacao_expositor #programacao .card-programacao .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 157%;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper {
  background: #4a446a;
  border: 1px solid #cecdd0;
  border-radius: 4px;
  padding: 24px 32px 24px 24px;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .expositor-wrapper {
  gap: 1rem;
  display: grid;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo-wrapper {
  justify-content: space-between;
  display: flex;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo-wrapper .horario-quadrado {
  background: #f2a900;
  align-items: center;
  gap: 4px;
  padding: 8px 6px;
  display: flex;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo-wrapper .horario-quadrado svg {
  width: 20px;
  height: 20px;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo-wrapper .horario-quadrado p, .body_programacao_expositor #programacao .programacao-expositor-wrapper .titulo-wrapper .horario-quadrado span {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .conteudo-expositor {
  justify-content: space-between;
  gap: 8px;
  display: flex;
}

@media (max-width: 1192px) {
  .body_programacao_expositor #programacao .programacao-expositor-wrapper .conteudo-expositor {
    flex-wrap: wrap;
  }
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .card {
  border: 1px solid #ffffff1a;
  border-radius: 3px;
  width: 100%;
  max-width: 328px;
  padding: 8px 16px;
}

@media (max-width: 1192px) {
  .body_programacao_expositor #programacao .programacao-expositor-wrapper .card {
    max-width: 100%;
  }
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .card h3 {
  color: #fff9;
  letter-spacing: .84px;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-family: Antonio;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.body_programacao_expositor #programacao .programacao-expositor-wrapper .card p {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

@media (min-width: 1024px) {
  .body_galeria {
    overflow-x: initial;
  }

  .body_galeria main {
    overflow: initial;
  }
}

.body_galeria #galeria-bienal .grid-programacao {
  grid-template-columns: 23.4375rem 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_galeria #galeria-bienal .grid-programacao {
    grid-template-columns: 19rem 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .body_galeria #galeria-bienal .grid-programacao {
    grid-template-columns: 1fr;
  }
}

.body_galeria #galeria-bienal .div-to-sticky {
  position: sticky;
  top: 140px;
}

.body_galeria #galeria-bienal .div-to-sticky .img-menina {
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .body_galeria #galeria-bienal .div-to-sticky .img-menina {
    display: none;
  }
}

.body_galeria #galeria-bienal .div-to-sticky .filtro-title {
  letter-spacing: -.32px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.body_galeria #galeria-bienal .div-to-sticky .accordion {
  margin-bottom: 10px;
  display: block;
}

.body_galeria #galeria-bienal .div-to-sticky .accordion-header {
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 10px;
}

.body_galeria #galeria-bienal .div-to-sticky .accordion-content {
  padding: 10px;
  display: none;
}

.body_galeria #galeria-bienal .div-to-sticky .espacos {
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background: var(--generics-white, #fff);
  border-radius: 3px;
  margin-bottom: 15px;
  display: none;
  transform: translateY(-20px);
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_galeria #galeria-bienal .div-to-sticky .espacos .espaco-item {
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  letter-spacing: -.32px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 12px;
  padding: 9px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  transition: all .3s ease-in-out;
  display: flex;
}

.body_galeria #galeria-bienal .div-to-sticky .espacos .espaco-item svg {
  width: 24px;
  height: 24px;
}

.body_galeria #galeria-bienal .div-to-sticky .espacos .espaco-item:hover {
  transition: all .3s ease-in-out;
  transform: scale(1.01);
}

.body_galeria #galeria-bienal .div-to-sticky .espacos.open {
  display: block;
}

.body_galeria #galeria-bienal .div-to-sticky .custom-select {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  position: relative;
}

.body_galeria #galeria-bienal .div-to-sticky .custom-select select {
  display: none;
}

.body_galeria #galeria-bienal .div-to-sticky .select-selected {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}

.body_galeria #galeria-bienal .div-to-sticky .select-selected .svg-pesquisa {
  width: 32px;
  height: 32px;
  transition: all .2s ease-in-out;
  transform: rotate(180deg);
}

.body_galeria #galeria-bienal .div-to-sticky .select-items {
  z-index: 1;
  border: 1px solid #ccc;
  border: 1px solid var(--l-neutral-10-op, #01071b1a);
  background-color: #f9f9f9;
  background: var(--generics-white, #fff);
  border-radius: 3px;
  max-height: 150px;
  display: none;
  position: absolute;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 11px 15px #01071b14, 0 9px 46px #0000000a, 0 24px 38px #00000005;
}

.body_galeria #galeria-bienal .div-to-sticky .select-items div {
  cursor: pointer;
  border: 1px solid var(--neutral-10-op, #0f0f121a);
  background: var(--white, #fff);
  border-radius: 3px;
  width: 375px;
  margin-top: 8px;
  padding: 8px 10px;
}

.body_galeria #galeria-bienal .div-to-sticky .select-items div:hover {
  background-color: #ddd;
}

.body_galeria #galeria-bienal .sidebar {
  background-color: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_galeria #galeria-bienal .sidebar {
    border-width: 0;
    padding: 0;
    transition: all .2s ease-in;
    overflow: hidden;
  }

  .body_galeria #galeria-bienal .sidebar.open {
    border-width: 1px;
    max-height: 1000px;
  }

  .body_galeria #galeria-bienal .sidebar ul {
    border: 1px solid #0f0f121a;
  }
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li {
  cursor: pointer;
  padding: 12px;
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li .dia-semana {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1280;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li .dia-data {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li.active {
  background-color: var(--bienal-rosa);
  color: #fff;
  border: 4px solid #ffcd29;
  padding: 12px;
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li.active .dia-semana {
  color: #d6df21;
  font-weight: 700;
}

.body_galeria #galeria-bienal .sidebar .dias-wrapper li.active .dia-data {
  color: #fff;
}

.body_galeria #galeria-bienal .image-galeria-wrapper {
  grid-template-columns: repeat(4, 1fr);
  gap: .5rem;
  display: grid;
}

.body_galeria #galeria-bienal .image-galeria-wrapper img {
  object-fit: cover;
  width: 23.75rem;
  height: 19.5rem;
}

@media (max-width: 1400px) {
  .body_galeria #galeria-bienal .image-galeria-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .body_galeria #galeria-bienal .image-galeria-wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media (min-width: 1024px) {
  .body_infos, .body_infos main {
    overflow: unset;
  }

  .body_infos .side-bar {
    position: sticky;
    top: 140px;
  }
}

.body_infos #base-banner .detail-bottom {
  background-color: #a3238e;
}

.body_infos #informacoes {
  padding: 5.375rem 0;
  position: relative;
}

.body_infos #informacoes .all-ornas-wrapper {
  flex-direction: column;
  gap: 12.5rem;
  display: flex;
  position: absolute;
  top: 490px;
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .all-ornas-wrapper {
    display: none;
  }
}

.body_infos #informacoes .all-ornas-wrapper .img-orna-1 {
  max-width: 204px;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .all-ornas-wrapper img {
    transform-origin: 0;
    transform: scale(.8);
  }
}

.body_infos #informacoes .hand-orna-right {
  flex-direction: row-reverse;
  display: flex;
  position: absolute;
  right: 0;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .hand-orna-right {
    transform-origin: 100%;
    right: -30px;
    transform: scale3d(.78, .78, .78);
  }
}

@media (max-width: 1440px) {
  .body_infos #informacoes .hand-orna-right {
    display: none;
  }
}

.body_infos #informacoes .hand-orna-right .hand-orna-img {
  padding-bottom: 3rem;
}

.body_infos #informacoes .hand-orna-right .alien-orna-hand {
  align-self: flex-end;
  max-width: 108px;
}

.body_infos #informacoes .grid-wrapper {
  grid-template-columns: 374px 1fr;
  align-items: flex-start;
  gap: 10rem;
  display: grid;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .grid-wrapper {
    gap: 6rem;
  }
}

@media screen and (max-width: 1440px) {
  .body_infos #informacoes .grid-wrapper {
    grid-template-columns: 274px 1fr;
    gap: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .grid-wrapper {
    display: block;
  }
}

.body_infos #informacoes .side-bar {
  background: #fff;
  border: 1px solid #0f0f120d;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .side-bar {
    display: none;
  }
}

.body_infos #informacoes .side-bar .destaque {
  margin-bottom: 1.5rem;
}

.body_infos #informacoes .side-bar h2 {
  cursor: pointer;
  color: #000000b3;
  margin-bottom: 1.5rem;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.body_infos #informacoes .side-bar h2.active {
  color: #e60043;
}

.body_infos #informacoes .side-bar h2:last-child {
  margin-bottom: 0;
}

.body_infos #informacoes .editor {
  max-width: 784px;
}

.body_infos #informacoes .editor h2 {
  color: #1e1a34;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .body_infos #informacoes .editor h2 {
    font-size: 1.5rem;
  }
}

.body_infos #informacoes .editor p {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.44;
}

.body_infos #informacoes .editor li {
  color: #5e5c66;
  margin-bottom: .75rem;
  margin-left: 2rem;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  list-style: disc;
}

.body_infos #informacoes .editor .li-bold {
  color: #0f0f12;
  font-weight: 700;
}

.body_infos #informacoes .block-item {
  margin-bottom: 2.5rem;
  scroll-margin-top: 140px;
}

.body_infos #informacoes .block-item p {
  color: #5e5c66;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.body_infos #informacoes p.destaque {
  color: #1e1a34;
  letter-spacing: .96px;
  text-transform: uppercase;
  background: #f2a900;
  gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px;
  font-family: Antonio;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  transform: rotate(-2deg);
}

.body_infos #informacoes p.destaque-2 {
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.body_infos #informacoes p.negrito {
  letter-spacing: -.02em;
  color: #0f0f12;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_infos #informacoes .ingresso-wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_infos #informacoes .ingresso-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_infos #informacoes .ingresso-item {
  background-image: url("price-bg.0b43544b.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px 0 0 3px;
  flex-direction: column;
  justify-content: center;
  max-width: 380px;
  min-height: 90px;
  padding: 12px 80px 12px 12px;
  display: flex;
}

.body_infos #informacoes .ingresso-item .info {
  color: #000000b3;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.body_infos #informacoes .ingresso-item .preco {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_infos #informacoes .table-container {
  border-radius: 3px;
  margin-bottom: 1.5rem;
  overflow: auto;
}

.body_infos #informacoes .table-container .table-head {
  background-color: #e60043;
  padding: .875rem 1.5rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_infos #informacoes .table-container .table-head {
    padding: .875rem 1rem;
  }
}

.body_infos #informacoes .table-container .table-head .head-title {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 144%;
}

@media screen and (max-width: 1440px) {
  .body_infos #informacoes .table-container .table-head .head-title {
    font-size: 1rem;
  }
}

.body_infos #informacoes .table-container .table-body {
  background-color: #fff;
  border: 1px solid #0f0f120d;
  border-bottom: none;
}

.body_infos #informacoes .table-container .table-body .row {
  border-bottom: 1px solid #0f0f120d;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 24px;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_infos #informacoes .table-container .table-body .row {
    padding: 1rem;
  }
}

.body_infos #informacoes .table-container .table-body .row p {
  margin-bottom: 0;
}

.body_infos #informacoes .table-container .table-body .row .label {
  color: #4a446a;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.body_infos #informacoes .table-container .table-body .row .preco {
  color: #e60043;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .table-container .table-body, .body_infos #informacoes .table-container .table-head {
    min-width: 800px;
  }
}

.body_contato #contato-bienal {
  background-color: #fff;
}

.body_contato #contato-bienal .section-title {
  margin-bottom: 3.5rem;
}

.body_contato #contato-bienal .section-title .description {
  letter-spacing: -.02em;
  color: #36363b;
  max-width: 54ch;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body_contato #contato-bienal .info-contato-container {
  margin-top: 2rem;
}

.body_contato #contato-bienal .info-contato-container .block-wrapper-contato {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.125rem;
  display: flex;
}

.body_contato #contato-bienal .info-contato-container .block-wrapper-contato .block-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.body_contato #contato-bienal .info-contato-container .block-wrapper-contato .block-info {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

.body_contato #contato-bienal .grid-wrapper {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-bienal .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_contato #contato-bienal .col-1 {
  max-width: 512px;
  margin-right: 2rem;
  padding: 120px 0;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-bienal .col-1 {
    padding: 5rem 0;
  }
}

.body_contato #contato-bienal .col-2 {
  flex-direction: column;
  justify-content: center;
  padding: 120px 0;
  display: flex;
  position: relative;
}

.body_contato #contato-bienal .col-2 .img-funto-contato {
  position: absolute;
  bottom: 320px;
  right: 300px;
}

@media screen and (max-width: 600px) {
  .body_contato #contato-bienal .col-2 {
    padding: 1.5rem 0;
  }
}

.body_contato #contato-bienal .col-2:before {
  content: "";
  background-image: url("contato-new.7c8341c3.webp");
  background-position: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 51vw;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 136px;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-bienal .col-2:before {
    width: 120vw;
    left: -20vw;
  }
}

.body_contato #contato-bienal .col-2 .form-wrapper {
  z-index: 2;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  width: 100%;
  max-width: 470px;
  padding: 4rem 2.5rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body_contato #contato-bienal .col-2 .form-wrapper {
    padding: 2.5rem 2rem;
  }
}

.body_contato #contato-bienal .col-2 .form-wrapper .form-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  margin-bottom: 2.5rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
}

.body_contato #contato-bienal .col-2 .form-wrapper .button-wrapper {
  transition: all .2s ease-in;
}

.body_contato #contato-bienal .col-2 .form-wrapper .button-wrapper:hover {
  transform: scale(1.02);
}

.body_contato #contato-bienal .col-2 .form-wrapper .button-wrapper .button {
  background-color: var(--bienal-amarelo);
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media screen and (max-width: 768px) {
  .body_contato #contato-bienal .col-2 .form-wrapper .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.body_contato #contato-bienal .col-2 .form-wrapper .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_contato #programacao-rolling {
  background-color: #fff;
}

.body_contato #programacao-rolling .swiper-infinite-programacao .swiper-wrapper {
  transition-timing-function: linear;
}

.body_contato #programacao-rolling .swiper-infinite-programacao .swiper-slide {
  width: max-content;
  min-width: max-content;
  max-width: 100%;
}

.body_contato #programacao-rolling .item-infinite {
  align-items: center;
  min-width: max-content;
  display: flex;
}

.body_contato #programacao-rolling .item-infinite h2 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #e3e3e6;
  min-width: max-content;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1440px) {
  .body_contato #programacao-rolling .item-infinite h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_contato #programacao-rolling .item-infinite h2 {
    font-size: 3rem;
  }
}

.body_contato #programacao-rolling .item-infinite .dot-right {
  background-color: #e3e3e6;
  border-radius: 50%;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  margin-left: 64px;
}

@media screen and (max-width: 1024px) {
  .body_contato #programacao-rolling .item-infinite .dot-right {
    margin-left: 20px;
  }
}

.body_credenciamento #banner-credenciamento {
  color: #fff;
  background: #99d6ea;
  padding: 6rem 0 0;
}

.body_credenciamento #banner-credenciamento .linha-amarela {
  background-color: #5cbde5;
  height: 120px;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #banner-credenciamento .linha-amarela {
    height: 60px;
  }
}

.body_credenciamento #banner-credenciamento .container {
  position: relative;
}

.body_credenciamento #banner-credenciamento .grid-wrappper {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 7rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #banner-credenciamento .grid-wrappper {
    grid-template-columns: 1fr;
  }
}

.body_credenciamento #banner-credenciamento .section-title h2 {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_credenciamento #banner-credenciamento .col-2 {
  max-width: 762px;
}

.body_credenciamento #banner-credenciamento .col-2 .text-description {
  color: #000000de;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.body_credenciamento #banner-credenciamento .orna-alien {
  max-width: 233px;
  position: absolute;
  top: -177px;
  right: -30px;
  transform: scaleX(-1);
}

@media screen and (max-width: 1440px) {
  .body_credenciamento #banner-credenciamento .orna-alien {
    max-width: 190px;
  }
}

@media screen and (max-width: 1024px) {
  .body_credenciamento #banner-credenciamento .orna-alien {
    top: -150px;
  }
}

.body_credenciamento #banner-credenciamento .main-img-wrapper {
  max-width: max-content;
  margin: 0 auto;
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
}

.body_credenciamento #banner-credenciamento .main-img-wrapper .orna-luneta {
  position: absolute;
  top: -77px;
  left: 7px;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #banner-credenciamento .main-img-wrapper .orna-luneta {
    top: -2px;
  }
}

.body_credenciamento #banner-credenciamento .main-img-wrapper .main-img {
  margin: 0 auto;
}

.body_credenciamento .grid-cards-wrapper {
  z-index: 3;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  gap: 1rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item {
  background-color: #e60043;
  border: 1px solid #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 480px;
  padding: 1.5rem;
  display: flex;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .body_credenciamento .grid-cards-wrapper .card-item {
    height: 100%;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .title {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 1440px) {
  .body_credenciamento .grid-cards-wrapper .card-item .title {
    font-size: 1.5rem;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .descricao {
  margin-bottom: 1rem;
}

.body_credenciamento .grid-cards-wrapper .card-item .descricao p {
  color: #ffffffb3;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

@media screen and (max-width: 1440px) {
  .body_credenciamento .grid-cards-wrapper .card-item .descricao p {
    font-size: 1rem;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper {
  transition: all .2s ease-in;
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper a {
  max-width: none !important;
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper .button {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  background: #fff;
  border: 1px solid #1e1a34;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  box-shadow: -3px 3px #f2a900;
}

@media screen and (max-width: 768px) {
  .body_credenciamento .grid-cards-wrapper .card-item .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_credenciamento .grid-cards-wrapper .card-item:first-child {
  background-color: #fa4616;
  width: 100%;
  height: 424px;
  margin-left: 7px;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:first-child {
    max-width: 490px;
    height: 100%;
    margin-left: 0;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item:nth-child(2) {
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:nth-child(2) {
    max-width: 490px;
    height: 100%;
    margin-left: 0;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item:nth-child(3) {
  background: #4a446a;
  width: 100%;
  height: 448px;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:nth-child(3) {
    max-width: 490px;
    height: 100%;
    margin-left: 0;
  }
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .gato-modal {
  display: none;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .modal-guts {
  padding-left: 2rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao {
  padding-top: 2rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .title-destaque {
  font-size: 1.3rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao p {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.2;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao strong {
  font-size: 1.2rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao ul, .body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao ul li {
  margin-bottom: 1rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .button-wrapper a {
  max-width: none !important;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .button-wrapper a:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .button-wrapper .button {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  padding: 13px 16px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .descricao .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_credenciamento #modal-atracoes.modal-atracoes.credenciamento .modal-lista li {
  margin-bottom: 1rem;
}

.body_espacos #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_espacos #categorias .container {
  overflow: hidden;
}

.body_espacos #categorias .swiper-slide {
  flex: 25%;
  min-width: max-content;
}

.body_espacos #categorias .tab {
  cursor: pointer;
  text-align: center;
}

.body_espacos #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_espacos #categorias .tab.active span {
  color: #000;
  background: #f3d5ae;
}

.body_espacos #categorias .tab:hover span {
  color: #000;
  background: #f3d5ae;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_espacos #categorias .tab a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_espacos #todos-espacos {
  background-color: #f3f3f5;
  margin-bottom: 7rem;
}

@media screen and (max-width: 1024px) {
  .body_espacos #todos-espacos {
    margin-bottom: 3.75rem;
  }
}

.body_espacos #todos-espacos .espaco {
  display: none;
}

.body_espacos #todos-espacos .espaco .grid-espaco {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_espacos #todos-espacos .espaco .grid-espaco {
    grid-template-columns: 1fr;
  }
}

.body_espacos #todos-espacos .espaco .col-1 {
  width: 100%;
  max-width: calc(528px + 4rem);
  margin: 0 auto;
  padding: 4rem 2rem;
}

@media screen and (max-width: 768px) {
  .body_espacos #todos-espacos .espaco .col-1 {
    padding: 3rem 1.5rem 0;
  }
}

.body_espacos #todos-espacos .espaco .col-1 .espaco-title {
  color: #1e1a34;
  text-transform: uppercase;
  background: #f2a900;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2rem;
  padding: 8px 12px;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (max-width: 1024px) {
  .body_espacos #todos-espacos .espaco .col-1 .espaco-title {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
  }
}

.body_espacos #todos-espacos .espaco .col-1 .content {
  color: #5e5c66;
  margin-bottom: 2rem;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body_espacos #todos-espacos .espaco .col-1 .content p {
  margin-bottom: 1rem;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores, .body_espacos #todos-espacos .espaco .col-1 .curadores .swiper-curadores {
  width: 100%;
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .title-curador {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item {
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .curador-nome {
  letter-spacing: .72px;
  text-transform: uppercase;
  color: #e60043;
  margin-top: .5rem;
  font-family: Antonio;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao {
  margin-top: 1rem;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao .title-curador {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao .curador-item {
  max-width: 150px;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao .curador-item .img-wrapper {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao .curador-item .img-wrapper img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_espacos #todos-espacos .espaco .col-1 .direcao .curador-item .curador-nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 150%;
}

.body_espacos #todos-espacos .espaco .col-1 .button-wrapper {
  margin-top: 32px;
}

.body_espacos #todos-espacos .espaco .col-1 .button-wrapper a:hover {
  transform: perspective(1px)translateY(2.5px);
  box-shadow: 0 0 #f2a900;
}

.body_espacos #todos-espacos .espaco .col-2, .body_espacos #todos-espacos .espaco .col-2 .swiper-espaco {
  width: 100%;
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-wrapper {
  position: unset;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-slide {
  height: auto;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-controls-custom {
  background-color: #e60043;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-controls-custom .pagination {
  color: #fff;
}

.body_espacos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper {
  padding-top: 79.86%;
  position: relative;
  overflow: hidden;
}

.body_espacos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons {
  align-items: center;
  gap: 16px;
  display: flex;
  position: absolute;
  right: 1rem;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button {
  background-color: var(--bienal-amarelo);
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 32px;
  place-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: grid;
  box-shadow: -2px 2px #f2a900;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button svg {
  width: 32px;
  height: 32px;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button svg path {
  stroke: #ffffffb3;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled {
  background-color: unset;
  border: unset;
  border-radius: unset;
  padding: unset;
  box-shadow: unset;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: unset;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled svg {
  color: #ffffffb3;
  width: 32px;
  height: 32px;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled svg path {
  stroke: #ffffffb3;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .swiper-button-disabled:hover {
  transform: unset;
  box-shadow: unset;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .swiper-button-lock {
  display: none;
}

.body_espacos #todos-espacos .espaco.show {
  display: block;
}

.body_espacos #mosaico-info {
  max-width: 1930px;
  margin: 7.5rem auto;
}

@media screen and (max-width: 1024px) {
  .body_espacos #mosaico-info .grid-mosaico {
    margin: 0 1rem;
  }
}

.body_espacos #mosaico-info .grid-mosaico .grid-1 {
  grid-template-columns: 1.2fr 1fr 536px;
  display: grid;
}

@media (max-width: 1440px) {
  .body_espacos #mosaico-info .grid-mosaico .grid-1 {
    grid-template-columns: 1.2fr 1fr 536px;
  }
}

@media screen and (max-width: 1024px) {
  .body_espacos #mosaico-info .grid-mosaico .grid-1 {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .body_espacos #mosaico-info .grid-mosaico .grid-1 .mosaico-item:last-child {
    grid-column: 1 / -1;
  }
}

.body_espacos #mosaico-info .grid-mosaico .grid-2 {
  grid-template-columns: 1fr 1.5fr 436px;
  display: grid;
}

@media (max-width: 1440px) {
  .body_espacos #mosaico-info .grid-mosaico .grid-2 {
    grid-template-columns: 1fr 1fr 236px;
  }
}

@media screen and (max-width: 1024px) {
  .body_espacos #mosaico-info .grid-mosaico .grid-2 {
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
  }

  .body_espacos #mosaico-info .grid-mosaico .grid-2 .mosaico-item:first-child, .body_espacos #mosaico-info .grid-mosaico .grid-2 .mosaico-item:last-child {
    display: none;
  }

  .body_espacos #mosaico-info .grid-mosaico .grid-2 .mosaico-item:nth-child(2) {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 768px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item {
    display: block;
  }
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item .img-wrapper {
  height: 100%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  background-color: #a3238e;
  place-content: center;
  padding: 2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 106%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    min-height: 200px;
  }
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:first-child .img-wrapper {
  flex: 0 0 66%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(3) .img-wrapper {
  flex: 0 0 57%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(3) .text-wrapper {
  background-color: #db761d;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(4) .text-wrapper {
  background-color: #369aa3;
}

.body_servicos #base-banner .detail-bottom {
  background-color: #f58220;
}

.body_servicos #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_servicos #categorias .container {
  overflow: hidden;
}

.body_servicos #categorias .swiper-slide {
  flex: 25%;
  min-width: max-content;
}

.body_servicos #categorias .tab {
  text-align: center;
}

.body_servicos #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_servicos #categorias .tab.active span {
  color: #000;
  background: #d6df2133;
}

.body_servicos #categorias .tab:hover span {
  color: #000;
  background: #d6df2133;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_servicos #categorias .tab a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_servicos #todos-espacos {
  margin-bottom: 7.5rem;
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1024px) {
  .body_servicos #todos-espacos .container .espaco:nth-child(2n) .grid-espaco .col-2 {
    grid-area: 1 / 1;
  }
}

.body_servicos #todos-espacos .espaco {
  margin-top: 6rem;
  scroll-margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos .espaco {
    margin-top: 1.5rem;
  }
}

.body_servicos #todos-espacos .espaco .grid-espaco {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 1.5rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_servicos #todos-espacos .espaco .grid-espaco {
    grid-template-columns: 1fr;
  }
}

.body_servicos #todos-espacos .espaco .col-1 {
  background-color: #f3f3f5;
  align-self: stretch;
  padding: 5rem;
}

@media screen and (max-width: 1440px) {
  .body_servicos #todos-espacos .espaco .col-1 {
    padding: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos .espaco .col-1 {
    padding: 1.5rem;
  }
}

.body_servicos #todos-espacos .espaco .col-1 .espaco-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

@media screen and (max-width: 1024px) {
  .body_servicos #todos-espacos .espaco .col-1 .espaco-title {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
  }
}

.body_servicos #todos-espacos .espaco .col-1 .content {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

.body_servicos #todos-espacos .espaco .col-1 .content p {
  margin-bottom: 1rem;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores, .body_servicos #todos-espacos .espaco .col-1 .curadores .swiper-curadores {
  width: 100%;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .title-curador {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper img {
  position: absolute;
  inset: 0;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .curador-nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 150%;
}

.body_servicos #todos-espacos .espaco .col-2, .body_servicos #todos-espacos .espaco .col-2 .swiper-espaco {
  width: 100%;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-wrapper {
  position: unset;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-slide {
  height: auto;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-controls-custom {
  background-color: #a3238e;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-controls-custom .pagination {
  color: #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper {
  padding-top: 79.86%;
  position: relative;
  overflow: hidden;
}

.body_servicos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons {
  align-items: center;
  gap: 7px;
  display: flex;
  position: absolute;
  right: 1rem;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button svg path {
  stroke: #000;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .swiper-button-lock {
  display: none;
}

.body_expositores #expositores {
  background-color: #4a446a;
  min-height: 760px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_expositores #expositores {
    margin-bottom: 5rem;
  }
}

.body_expositores #expositores .robozin-wrapper {
  position: absolute;
  top: 7.5rem;
}

.body_expositores #expositores .robozin-wrapper img {
  max-width: 132px;
}

.body_expositores #expositores .livro-rosa-wrapper {
  position: absolute;
  top: 2rem;
  right: 0;
  transform: rotate(100deg);
}

.body_expositores #expositores .container-limit {
  max-width: 1528px;
  margin: 0 auto;
  padding: 0 100px 160px;
}

@media screen and (max-width: 1440px) {
  .body_expositores #expositores .container-limit {
    padding: 0 0 50px;
  }
}

.body_expositores #expositores .title-wrapper {
  margin-bottom: 2.5rem;
  padding-top: 7.5rem;
}

@media screen and (max-width: 768px) {
  .body_expositores #expositores .title-wrapper {
    padding-top: 5rem;
    display: block;
  }
}

.body_expositores #expositores .title-wrapper h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  margin-bottom: 58px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 133%;
}

.body_expositores #expositores .title-wrapper .title-for-orna {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.body_expositores #expositores .title-wrapper .title-for-orna h2 {
  color: var(--Generics-White, #fff);
  letter-spacing: .96px;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_expositores #expositores .title-wrapper .title-for-orna .img-wrapper {
  margin-bottom: 2rem;
}

.body_expositores #expositores .title-wrapper .title-for-orna .img-wrapper img {
  max-width: 74px;
}

.body_expositores #expositores .title-wrapper .floating-letters {
  z-index: 10000;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #01071b1a;
  border-radius: 3px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  gap: 8px;
  max-width: 300px;
  margin-right: -1px;
  padding: 1rem;
  display: none;
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  right: 0;
  box-shadow: 0 24px 38px #00000005, 0 9px 46px #0000000a, 0 11px 15px #01071b14;
}

.body_expositores #expositores .title-wrapper .floating-letters a {
  cursor: pointer;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #36363b;
  background-image: url("arrow-right-busca.f63debee.svg");
  background-position: 97%;
  background-repeat: no-repeat;
  border: 1px solid #01071b1a;
  flex: 0 0 calc(33% - 8px);
  padding: .4rem 1rem .4rem .5625rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  display: block;
}

.body_expositores #expositores .title-wrapper .floating-letters a.active {
  background-color: #f582201a;
  border: 1px solid #f582201a;
  border-radius: 3px;
}

.body_expositores #expositores .title-wrapper .filtro-to-click.show .floating-letters {
  pointer-events: all;
  display: grid;
}

.body_expositores #expositores .buscar-filtro {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2.5rem;
  display: flex;
}

.body_expositores #expositores .buscar-filtro form {
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 507px;
  display: flex;
}

.body_expositores #expositores .buscar-filtro label {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #36363b;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.body_expositores #expositores .buscar-filtro .button-wrapper .button {
  box-shadow: -3px 3px #e60043;
}

.body_expositores #expositores .buscar-filtro .button-wrapper .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #e60043;
}

.body_expositores #expositores .buscar-filtro .filtro-to-click {
  cursor: pointer;
  z-index: 3;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 52px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 260px;
  padding: .75rem;
  display: flex;
  position: relative;
}

.body_expositores #expositores .buscar-filtro .filtro-to-click p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1280;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.body_expositores #expositores .buscar-filtro .circle-arrow {
  background-color: #e60043;
  border-radius: 50%;
  place-content: center;
  width: 2rem;
  height: 2rem;
  display: grid;
}

.body_expositores #expositores .expositores-grid {
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 4rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_expositores #expositores .expositores-grid {
    grid-template-columns: 1fr;
  }
}

.body_expositores #expositores .expositores-grid .expositor-item {
  cursor: pointer;
  border: 1px solid #fff9;
  border-radius: 6px;
  grid-template-columns: 1fr 1fr 1fr 40px;
  align-items: center;
  gap: 4px;
  padding: 1.5rem;
  display: grid;
}

.body_expositores #expositores .expositores-grid .expositor-item .seta svg {
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 768px) {
  .body_expositores #expositores .expositores-grid .expositor-item .seta svg {
    display: none;
  }

  .body_expositores #expositores .expositores-grid .expositor-item {
    grid-template-columns: 1fr;
  }

  .body_expositores #expositores .expositores-grid .expositor-item .seta {
    display: none;
  }
}

.body_expositores #expositores .expositores-grid .expositor-item .nome {
  color: #fff;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande {
  color: #fff;
  text-transform: uppercase;
  align-items: center;
  gap: 8px;
  font-family: Antonio;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande .bolinha {
  border: 1px solid #000;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: block;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande .bolinha.Laranja {
  background-color: #fa4616;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande .bolinha.Verde {
  background-color: #00d980;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande .bolinha.Vermelho {
  background-color: #e60043;
}

.body_expositores #expositores .expositores-grid .expositor-item .estande .bolinha.Azul {
  background-color: #56c5d0;
}

.body_expositores .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_expositores .pagination .page-list a {
  padding: .375rem .875rem;
  display: block;
}

.body_expositores .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 32px;
  align-items: center;
  padding: 6px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: block;
  box-shadow: -3px 3px #f2a900;
}

.body_expositores .pagination li.current {
  background-color: var(--bienal-vermelha);
}

.body_expositores .pagination li.current a {
  color: #fff;
}

.body_expositores .pagination li:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_expositores .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: var(--bienal-vermelha);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_expositores .pagination .on-edges.left svg {
  align-self: flex-start;
  margin-right: 8px;
  transform: rotate(180deg);
}

.body_expositores .pagination .on-edges.right svg {
  align-self: flex-start;
  margin-left: 8px;
}

.body_expositores .pagination .on-edges a {
  align-items: center;
  display: flex;
  color: #fff !important;
}

.body_expositores .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

.body_expositores .active-step-1 .step-1-item {
  display: block;
}

.body_expositores .active-step-1 .step-2-item, .body_expositores .active-step-2 .step-1-item {
  display: none;
}

.body_expositores .active-step-2 .step-2-item {
  display: block;
}

.body_expositores .modal-infos .header-expositor {
  background-color: #b3b0c4;
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .header-expositor {
    padding-left: 0;
  }
}

.body_expositores .modal-infos .info-label {
  color: var(--black-70, #000000b3);
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.body_expositores .modal-infos .info-nome {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_expositores .modal-infos .header-1-wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 24px 3rem;
  display: flex;
}

.body_expositores .modal-infos .header-2-wrapper {
  border-top: 1px solid var(--black-70, #000000b3);
  justify-content: space-between;
  align-items: center;
  padding: 8px 3rem 16px;
  display: flex;
}

.body_expositores .modal-infos .header-2-wrapper .grid-wrapper {
  gap: 20px;
  display: flex;
}

.body_expositores .modal-infos .header-2-wrapper .nome-wrapper {
  align-items: center;
  gap: 10px;
  display: flex;
}

.body_expositores .modal-infos .header-2-wrapper .info-nome {
  font-size: 1.125rem;
}

.body_expositores .modal-infos .button-wrapper {
  transition: all .2s ease-in;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .button-wrapper {
    display: none;
  }
}

.body_expositores .modal-infos .button-wrapper .button {
  color: #000;
  border: 1px solid #1e1a34;
  padding: 12px 20px;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.body_expositores .modal-infos .button-wrapper .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_expositores .modal-infos .button-wrapper svg {
  width: 24px;
  height: 24px;
}

.body_expositores .modal-infos .button-wrapper.only-mobile-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .button-wrapper.only-mobile-btn {
    display: block;
  }
}

.body_expositores .modal-infos .informacoes-wrapper {
  padding: 3rem 1.25rem 1.25rem;
}

.body_expositores .modal-infos .informacoes-wrapper .grid-title {
  border-bottom: 1px solid #fff3;
  margin-bottom: 20px;
  display: flex;
}

.body_expositores .modal-infos .informacoes-wrapper .grid-title .section-title {
  border-bottom: unset;
  margin-bottom: unset;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.body_expositores .modal-infos .informacoes-wrapper .grid-title .section-title .badge-wrapper.bg-azul {
  color: #fff;
  text-transform: uppercase;
  background-color: unset;
  transform: unset;
  margin-bottom: unset;
  cursor: pointer;
  padding-bottom: 1rem;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  position: relative;
}

.body_expositores .modal-infos .informacoes-wrapper .grid-title .section-title .badge-wrapper svg {
  width: 37.273px;
  height: 10px;
  display: none;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.body_expositores .modal-infos .informacoes-wrapper .grid-title .section-title .badge-wrapper.active svg {
  width: 37.273px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .informacoes-wrapper {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding-left: 0;
  }
}

.body_expositores .modal-infos .section-title {
  border-bottom: 1px solid #fff3;
  margin-bottom: 1rem;
}

.body_expositores .modal-infos .selos li {
  letter-spacing: -.32px;
  text-transform: uppercase;
  border: 1px solid #fff3;
  margin-bottom: 8px;
  padding: 11px 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
}

.body_expositores .modal-infos .livros {
  display: none;
}

.body_expositores .modal-infos .livros.active {
  display: block;
}

.body_expositores .modal-infos .selos {
  display: none;
}

.body_expositores .modal-infos .selos.active {
  display: block;
}

.body_expositores .modal-infos .livros-wrapper {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
  display: grid;
}

.body_expositores .modal-infos .livros-wrapper .nome-livro {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: #fff;
  letter-spacing: .96px;
  text-transform: uppercase;
  -webkit-box-orient: vertical;
  margin-top: 8px;
  font-family: Antonio;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.body_expositores .modal-infos .livros-wrapper .img-wrapper {
  padding-top: 112.94%;
  position: relative;
}

.body_expositores .modal-infos .livros-wrapper .img-wrapper:after {
  content: "";
  border: 4px solid #d6df21;
  position: absolute;
  inset: 0;
}

.body_expositores .modal-infos .livros-wrapper .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: 3px;
  position: absolute;
  inset: 0;
}

.body_expositores .modal-infos .header-expositor.step-2 {
  padding-top: 1.5rem;
}

.body_expositores .modal-infos .header-expositor.step-2:after {
  display: none;
}

.body_expositores .modal-infos .header-expositor.step-2 .voltar-btn {
  color: #fff;
  letter-spacing: -.24px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  right: 0;
}

.body_expositores .modal-infos .header-expositor.step-2 .info-nome {
  margin-bottom: 4px;
}

.body_expositores .modal-infos .header-expositor.step-2 .confirmada-title {
  color: #d6df21;
  letter-spacing: -.32px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
}

.body_expositores .modal-infos .lista-programacao {
  padding: 3rem 0 0 3rem;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .lista-programacao {
    padding-left: 0;
  }
}

.body_expositores .modal-infos .lista-programacao ul li .informacoes {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  padding-bottom: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_expositores .modal-infos .lista-programacao ul li .informacoes {
    grid-template-columns: 1fr;
  }
}

.body_expositores .modal-infos .lista-programacao ul li .informacoes .group {
  border: 1px solid #ffffff1a;
  border-radius: 1px;
  padding: 8px;
}

.body_expositores .modal-infos .lista-programacao ul li .informacoes .group .label {
  color: #ffffff80;
  letter-spacing: -.28px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.body_expositores .modal-infos .lista-programacao ul li .informacoes .group .info-nome {
  text-transform: uppercase;
  font-size: 1.125rem;
}

.body_visitacao #visitacao .container-vicitacao {
  max-width: 783px;
}

.body_visitacao #visitacao .container-vicitacao .section-title {
  margin-top: 96px;
}

.body_visitacao #visitacao .container-vicitacao .section-title h2 {
  color: #1e1a34;
  letter-spacing: .96px;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: Antonio;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_visitacao #visitacao .container-vicitacao .section-title .sub-title {
  color: #5e5c66;
  margin-bottom: 25px;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.body_visitacao #visitacao .container-vicitacao .badge-for-ornamento .badge-wrapper {
  background: #f2a900;
  align-items: center;
  gap: 8px;
  height: 32px;
  margin-bottom: 18px;
  padding: 26px 8px;
  display: flex;
  transform: rotate(-2deg);
}

.body_visitacao #visitacao .container-vicitacao .badge-for-ornamento .badge-wrapper .badge {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.body_visitacao #visitacao .container-vicitacao .margin-top-24 {
  margin-top: 1.5rem;
}

.body_visitacao #visitacao .container-vicitacao .conteudo-text {
  gap: 32px;
  margin-bottom: 24px;
  display: grid;
}

.body_visitacao #visitacao .container-vicitacao .conteudo-text p {
  color: #1e1a34;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.body_visitacao #visitacao .container-vicitacao .conteudo-text .negrito {
  font-weight: 700;
}

.body_visitacao #visitacao .container-vicitacao .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #fff;
  border: 1px solid #1e1a34;
  border-radius: 24px;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  padding: 12px 24px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: -3px 3px #e60043;
}

.body_visitacao #visitacao .container-vicitacao .button span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_visitacao #visitacao .container-vicitacao .button:active, .body_visitacao #visitacao .container-vicitacao .button:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
  box-shadow: 0 0 #f2a900;
}

.body_visitacao #visitacao .container-vicitacao .button:active span, .body_visitacao #visitacao .container-vicitacao .button:hover span {
  transform: translateZ(0)perspective(1px);
}

.body_visitacao #visitacao .container-vicitacao .button svg, .body_visitacao #visitacao .container-vicitacao .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-right: 6px;
}

.body_visitacao #visitacao .container-vicitacao .contact {
  margin-top: 85px;
  margin-bottom: 85px;
}

.body_visitacao #visitacao .container-vicitacao .contact h3 {
  color: #1e1a34;
  text-transform: uppercase;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.body_visitacao #visitacao .container-vicitacao .contact .contact-container {
  background: #fff;
  border: 1px solid #1e1a341a;
  border-radius: 3px;
  padding: 16px;
}

.body_visitacao #visitacao .container-vicitacao .contact .contact-container h4 {
  letter-spacing: -.36px;
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.body_visitacao #visitacao .container-vicitacao .contact .contact-container .info-wraper {
  gap: 4px;
  margin-top: 6px;
  display: flex;
}

.body_visitacao #visitacao .container-vicitacao .contact .contact-container .info-wraper a {
  color: #36363b;
  letter-spacing: -.36px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.body_visitacao #visitacao .container-vicitacao .contact .contac-1 {
  margin: 16px 0;
}

.body_visitacao #visitacao .container-vicitacao .contact .contac-2 {
  margin-bottom: 40px;
}

.body_visitacao #visitacao .container-vicitacao .contact .visite-mobile {
  width: 100%;
}

@media (max-width: 750px) {
  .body_visitacao #visitacao .container-vicitacao .section-title h2 {
    margin: 64px 0 12px;
    font-size: 36px;
  }

  .body_visitacao #visitacao .container-vicitacao .section-title .sub-title {
    margin-bottom: 25px;
    font-size: 16px;
  }

  .body_visitacao #visitacao .container-vicitacao .conteudo-text p {
    letter-spacing: -.4px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact {
    margin-top: 85px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact h3 {
    color: #000;
    letter-spacing: -.36px;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contact-container {
    border: 1px solid var(--neutral-10-op, #0f0f121a);
    background: var(--white, #fff);
    border-radius: 3px;
    padding: 16px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contact-container h4 {
    letter-spacing: -.36px;
    margin-bottom: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contact-container .info-wraper {
    gap: 4px;
    margin-top: 6px;
    display: flex;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contact-container .info-wraper a {
    color: #36363b;
    letter-spacing: -.36px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contac-1 {
    margin: 16px 0;
  }

  .body_visitacao #visitacao .container-vicitacao .contact .contac-2 {
    margin-bottom: 40px;
  }
}

.body_ao_vivo #ao-vivo .banner-img {
  object-fit: cover;
  width: 100%;
}

.body_ao_vivo #ao-vivo .img-wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 1.625rem;
  margin: 6rem 0 10rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_ao_vivo #ao-vivo .img-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content {
  position: relative;
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content .text {
  padding: 3rem;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 600px) {
  .body_ao_vivo #ao-vivo .img-wrapper .text-content .text {
    padding: 2rem;
  }
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content .text h2 {
  color: var(--white);
  letter-spacing: -.1rem;
  margin-bottom: 2rem;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

@media (max-width: 1600px) {
  .body_ao_vivo #ao-vivo .img-wrapper .text-content .text h2 {
    font-size: 4rem;
  }
}

@media (max-width: 1400px) {
  .body_ao_vivo #ao-vivo .img-wrapper .text-content .text h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .body_ao_vivo #ao-vivo .img-wrapper .text-content .text h2 {
    font-size: 2rem;
  }
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content .text .button-live {
  background: var(--bienal-amarelo);
  color: var(--black);
  letter-spacing: -.0175rem;
  text-transform: uppercase;
  border-radius: .1875rem;
  align-items: center;
  gap: 1.5rem;
  max-width: 8.1875rem;
  padding: .75rem 1rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157%;
  display: flex;
  box-shadow: 2.5px 2.5px #a11c64;
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content .text .button-live:hover {
  transform: perspective(1px)translate3d(0, 2.5px, 0);
}

.body_ao_vivo #ao-vivo .img-wrapper .text-content .text .button-live svg {
  width: 1.5rem;
  height: 1.5rem;
}

.dot-contato {
  background-color: #a3238e;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  margin-right: 2px;
  display: inline-block;
}

.body-coming-soon #footer-bienal {
  display: none;
}

.menu-space-top {
  margin-top: 123px;
}

@media (max-width: 1600px) {
  .menu-space-top {
    margin-top: 125px;
  }
}

@media (max-width: 1024px) {
  .menu-space-top {
    margin-top: 114px;
  }
}

/*# sourceMappingURL=style.css.map */
