#base-banner{

    // margin-top: 129px;

    // @media(max-width:1400px){
    //     margin-top: 123px;
       
    // }
    // @media (max-width:1024px){
    //     // margin-top: 72px;
    //     margin-top: 115px;
    // }


    .banner-img{
        width: 100%;
        object-fit: cover;
    }

    .detail-bottom{
        background: #D6DF21;
        height: 16px;
        margin-top: -16px;
        position: relative;
        z-index: 2;
    }
    // background-image: url('../../src/assets/imagens/blog/background-midia.webp');
    // background-image: url('../../src/assets/imagens/blog/banner-full-blog.webp');
    // background-repeat: repeat;
    // background-size: cover;

    // min-height: 444px;

    // position: relative;

    // margin-top: 160px;
    // display: flex;
    // flex-direction: column;

    // .container{
    //     flex: 1;
    //     height: 100%;
    //     display: grid;
    //     position: relative;
    // }
    // .banner-content{

    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     left: 1.5rem;
    //     right: 1.5rem;

    //     h1{
    //         text-align: center;
    //         display: flex;
    //         align-items: center;
    //     }
    // }
}    

