
#todas-atracoes{
    .ornamento-peixe{
        position: absolute;
        top: -69px;
        left: -51px;
        transform: rotate(130deg);
        img{
            max-width: 120px;
        }
    }

    .title-wrapper{
        margin-bottom: 2rem;
        h2{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.33;
            /* identical to box height, or 133% */
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
            
            /* Neutral/100 */
            
            color: #0C1428;
        }
    }

    .lupa-title-wraper{
        display: flex;
        justify-content: space-between;
        
    }

    .busca{
        border-radius: 52px;
        border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
        background: #FFF;
        width: 352px;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        color: #000000;
        .svg-lupa{
            width: 32px;
            height: 32px;
        }
        ::placeholder{
            color: #000000;
            text-transform: uppercase;
        }
        
    }
}

.pagination{
    margin: 64px 0 180px 0;

    .page-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;

        a{
            padding: .375rem .875rem;
            // border: 1px solid rgba(15, 15, 18, 0.1);
            // border-radius: 3px;
            display: block;
        }
    }

    li{
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 3px;
        /* identical to box height, or 150% */

   
        letter-spacing: -0.02em;
        text-transform: uppercase;

        color: #000000;

        cursor: pointer;
        background-color: #fff;
        border: 1px solid #1e1a34;
        border-radius: 32px;
        align-items: center;
        padding: 6px;
        transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
        display: block;
        box-shadow: -3px 3px #f2a900;
        
        

        
        &:hover {
            transform: perspective(1px)translate3d(0, 2.5px, 0);
            box-shadow: 0 0 #f2a900;
        }

        &.current{
            background-color: $bienal-vermelha;
            a{
                color: #fff;

            }
        }
    }

    .on-edges{
        
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;

  
        background-color: $bienal-vermelha;
        /* Black */

        
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 3px;
        &.left{
                svg{
                    transform: rotate(180deg);
                    margin-right: 8px;
                    align-self: flex-start;
                }
        }
        &.right{
            svg{
                margin-left: 8px;
                align-self: flex-start;
            }

        }
       
        a{
            display: flex;
            align-items: center;
            color: #FFF !important;
        }
        svg{
            width: 20px;
            height: 20px;

        }
    }
}


// #footer-bienal{
//     // DESCONMENTAR ***************************************
//     // margin-bottom: 82px;
//     // @include d(md2){
//     //     margin-bottom: 116px;
//     // }
// }

@media(max-width:875px){
    #todas-atracoes .lupa-title-wraper{
        flex-wrap: wrap;
        justify-content: center;

        .busca{
            margin-top: 24px;
        }
    }
    .pagination{
        margin: 34px 0 60px 0;
    }
}