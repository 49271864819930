#footer-bienal{
   
    background-color: #EEEEF0;



 
    .sup-footer{
        // background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-color: $bienal-vermelha;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }


    .grid-sup-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .logo-wrapper{
            padding: 24px 0;

            @include d(lg){
                max-width: 400px;
            }
            @include d(md2){
                padding: 52px 0;
            }
            
        }

   

        .go-top{
            background-color: $bienal-amarelo;
            display: flex;
            padding: 8px 9px;
            border-radius: 0 0 3px 3px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            p{
        
                font-weight: 700;
                font-size: 14px;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
            }
            svg{
                width: 20px;
                height: 20px;
                transform: rotate(-90deg);
                margin-right: .5rem;
            }
        }


        .right-col{
            position: relative;
            display: flex;
            align-items: center;
            align-self: stretch;

            @include d(md2){
                
            }
            .livro-wrapper{
                // position: absolute;
                align-self: flex-end;
                margin-bottom: -22px;

                @include d(md2){
                    max-width: 93px;
                    margin-bottom: -10px;
                    margin-right: auto;
                }
            }
        }
        .socials{
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;
            justify-self: center;
            justify-content:flex-end;
            h3{
                color: #FFF;

                /* Heading/H4 - 24 */
                font-family: Antonio;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
                text-transform: uppercase;
            }
            @include d(md2){
               
            }
            ul{
                display: flex;
                justify-content: flex-end;
                gap: 9px;
                
                a{
                    padding: 2px;
                    display: block;
                    cursor: pointer;
                    border: 1px solid  #1e1a34;
                    background-color: #fff;
                    border-radius: 32px;
                    align-items: center;
                    padding: 8px;
                    transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
                    box-shadow: -3px 3px 0px 0px #F2A900;

                    img{
                        width: 24px;
                    }    
                }
                 a:hover {
                    transform: perspective(1px)translate3d(0, 2.5px, 0);
                    box-shadow: 0 0 #f2a900;
                    
                }


                svg{
                    width: 3.5rem;
                    height: 3.5rem;
                    color: #D6DF21;
                    @include d(md2){
                        width: 2.5rem;
                        height: 2.5rem;
                        
                    }
                }
            }
        }
    }

    

    .patrocinadores{
        background-color: #fff;
        padding: 73px 0;

        .group-wrapper{
            max-width: 1527px;
            margin: 0 auto;
            
        }
        
        .group-wrapper .group .lei{
            margin-top: 46px;
        }
        .row-1{
            display: flex;
            justify-content: center; 
            margin-bottom: 4rem;
            column-gap: 4.5rem;
            gap: 1.5rem;

            @include d(dt){
                display: grid;
                grid-template-columns: 1fr;
            }
            @include d(md2){
                margin-bottom: 4rem;
            }

            .group-wrapper:nth-child(1){
                flex-shrink: 0;
                align-self: flex-end;
                // .img-wrapper{
                //     // padding-bottom: 1rem;
                // }
                img{
                    margin: 0 auto;

                }
            }

            .img-wrapper img{
                transform: scale(0.93);
                
                
            }
        }

        .row-nova{
            margin-bottom: 4rem;
        }
        .row-2{
            // display: flex;
            // align-items: flex-start;
            // justify-content: space-between; 
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 4.5rem;
            margin-bottom: 4rem;
           
            @include d(md){
                display: grid;
                grid-template-columns: 1fr;
            }
            .group-wrapper.apoio-group{
                // max-width: 385px;
                // width: 100%;
                flex: 0 0 20%;
                width: 100%;
                @include d(md){
                    margin: 0 auto;
                }
            }
            .group-wrapper.hotel-group{
                // max-width: 385px;
                // width: 100%;
                flex: 0 0 20%;
                width: 100%;
                @include d(md){
                    margin: 0 auto; 
                }

            }
            .group-wrapper.realizacao-group{
                    max-width: 1460px;
                    margin: 0 auto;
                    flex: 1;
            }
        }

        

        
        .group-wrapper{

            p{
                color:  #1E1A34;
                text-align: center;
                font-family: Antonio;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 20.8px */
                text-transform: uppercase;
                text-align: center;

                border-bottom: 1px solid var(--grey-200, #CECDD0);
                padding: 8px;
            }

            .group, .group-1, .group-2{
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 2.5rem;
                justify-content: center;
                @include d(lg){
                    gap: 1.5rem;
                }
            }

            .group-1{
                margin-bottom: 2rem;
                
            }

            

            .group-1, .group-2 {
                @include d(md){
                    display: none;
                }
            }

            .group-mobile {
                @media(min-width:1024px){
                    display: none;
                }

                display: flex;
                flex-wrap: wrap;
                place-content: center;
                gap: 1.5rem;
            }
        }


        .apresenta-group{
            .group{
                padding: 0 5rem;

                @include d(lg){
                    padding: 0 2rem;
                }
                
                gap: 3rem;
                justify-content: center;            
                align-items: flex-start;
                

            }
        }

        .group-wrapper.acao-social{
            .img-wrapper{
                img{
                    margin: 0 auto;
                }
                
            }
        }
        .patrocinadores-group{
            .group{
                justify-content: space-between;
            }
            @include d(dt){
                .group{
                    flex-wrap: wrap;
                    place-content: center;
                    
                }
            }
        }
        .realizacao-group{
            @include d(sm){
                .group{
                    flex-wrap: wrap;
                }
            }
        }



        @media (max-width: 1440px){
            .realizacao-group img,
             .apoio-group img,
             .hotel-group img{
                 transform: scale(0.7); 
                transform-origin: top;
            }
        } 
        @media (max-width:1200px){
            .realizacao-group img, .apoio-group img,
            .hotel-group img{ 
                transform: scale(0.8);
                transform-origin: top;
            }
        }
    } 

    .acao-social{
        p{
            padding: 0 20px;
        }
    }

    .bottom-footer{
        padding: 1.2rem 0;
        color: #fff;
        background:  #1E1A34;

        // background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-repeat: no-repeat;
        background-size: cover;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            
            row-gap: 5px;
            column-gap: 1.5rem;
        }


        p{
            color: #FFF;
            text-align: right;
            font-family: Antonio;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 26px */
            text-transform: uppercase;
        }
           
           

            .ndt-wrapper{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.5;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                padding-right: 16px;
                position: relative;
                img{
                    padding-bottom: 7px;
                }
            }
            .ndt-wrapper::after{
                content: "";
                display: block;
                position: absolute;
                right: 0;
                width: 1.5px;
                height: 16px;
                background-color: #fff;
            }
            .road-wrapper{
                padding-left: 16px;

            }
    }
}