#banner-patrocinio{

    padding: 4rem 0;
    background-color:#FA4616;

  

    .container {
        position: relative;
        // overflow: hidden;
        @include d(md2){
            padding-top: 96px;
        }
    }

    .container-banner-patrocinio{
        max-width: 1792px !important;
    }


    .balao-wrapper{
        position: absolute;
        right: 1.5rem;
        top: 0;
        z-index: 2;

        @include d(md2){
            max-width: 72px;
        }
    }
    .robo-wrapper{ 
        position: absolute; 
        top: -48px;
        left: 0;
        z-index: 9;
        @include d(md2){
            width: 130px;
            top: 0;
            left: 1rem;
        }
    }
    .origami{
        position: absolute;
        right: 1.5rem;
        bottom: -10px;
        z-index: 2;

        @include d(md2){
            width: 80px;
        }
    }

    .alienigena-wrapper{
       
        position: absolute;
        top: 55px;
        left: -11px;
        z-index: 2;

        @include d(md2){
            max-width: 106px;
        }
    }

    .img-wrapper-banner{
        height: 100%;
        border-radius: 6px;
        border: 8px solid  #F2A900;
        img{ 
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
        }
    }



    .swiper-banner-patrocinadores{

        .swiper-slide{ height: auto;}
    }
  

    .swiper-pagination-bullet{
        background-color: #BEC5CC;
        opacity: 1;
        width: .75rem;
        height: .75rem;
    }
    .swiper-pagination-bullet-active{
        background: #868E96;
        width: 24px;
        border-radius: 8px;
    }
}


.alienigena-wrapper{
    display: none;
}

body:not(.body_home){
    .robo-wrapper,.origami{

        display: none;
    }
}

.body_single_blog {
    .robo-wrapper,.origami,.balao-wrapper{
        display: none;
    }
}

.body_eventos,
.body_quemsomos,
.body_credenciamento {
    .alienigena-wrapper{
        display: block;
    }
    .balao-wrapper{
        display: none;
    }
}




.body_home{

    #banner-patrocinio{

        .balao-wrapper{
            display: none;
        }
    }
}