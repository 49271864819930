#expositores{
 position: relative;
 background-color: #4A446A;
    // margin-bottom: 7.5rem;
    // padding-bottom: 25rem;
    min-height: 760px;
    @include d(md2){
        margin-bottom: 5rem;
    }

    .robozin-wrapper{
        position: absolute;
        top:  7.5rem;
        img{
            max-width: 132px;
        }
    }
    .livro-rosa-wrapper{
        position: absolute;
        top:  2rem;
        right: 0;
        transform: rotate(100deg);
        
    }

    .container-limit{
        max-width: calc(1328px + 200px);
        margin: 0 auto;
        padding: 0 100px;
        padding-bottom: 160px;
        @include d(lg){
            padding: 0;
            padding-bottom: 50px;

        }
    }

    .title-wrapper{

        // display: grid;
        // align-items: center;
        // justify-content: space-between;
        padding-top: 7.5rem;
        margin-bottom: 2.5rem;
        

        @include d(md2){
            display: block;
            padding-top: 5rem;
        }

        h2{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 133%;
            /* identical to box height, or 133% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0C1428;
            margin-bottom: 58px;
        }

        .title-for-orna{
            display: flex;
            gap: 2rem;
            align-items: center;
            h2{
                color: var(--Generics-White, #FFF);
                font-family: Antonio;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 62.4px */
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }
            
            .img-wrapper{
                margin-bottom: 2rem;
                img{
                    max-width: 74px;
                }
            }
          
        }


        .floating-letters{
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + 1rem);
            z-index: 10000;
            background-color: #fff;
            max-width: 300px;
            border: 1px solid rgba(1, 7, 27, 0.1);
            box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(1, 7, 27, 0.08);
            border-radius: 3px;
            padding: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: flex-start;
            gap: 8px;
            margin-right: -1px;
            pointer-events: none;
            display: none;

            a{
                flex: 0 0 calc(33% - 8px);
                display: block;
                background-repeat: no-repeat;
                border: 1px solid rgba(1, 7, 27, 0.1);
                background-position: 97%;
                padding: .4rem .5625rem;
                cursor: pointer;
                padding-right: 1rem;
                background-image: url('../../src/assets/imagens/icons/arrow-right-busca.svg');
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                /* identical to box height, or 150% */             
                letter-spacing: -0.02em;
                text-transform: uppercase;


                color: #36363B;

                &.active{
                    background-color: rgba(245, 130, 32, 0.1);
                    border: 1px solid rgba(245, 130, 32, 0.1);
                    border-radius: 3px;
                }
            }
        }

        .filtro-to-click.show{

            .floating-letters{
                pointer-events: all;
                display: grid;
            }
           
        }
    }

    .buscar-filtro{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2.5rem;
        form{
            display: flex;
            align-items: center;
            gap: 12px;
            max-width: 507px;
            width: 100%;
        }

        label{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/70 */

            color: #36363B;
        }

        .button-wrapper .button{
            box-shadow: -3px 3px 0px 0px #E60043;
           
        }
        .button-wrapper .button:hover{
                
            transform: perspective(1px) translate3d(0, 2.5px, 0);
            box-shadow: 0 0 #E60043;
        
        }   


        .filtro-to-click{
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            padding: .75rem;
            min-width: 260px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            z-index: 3;
            background: #fff;
            border-radius: 52px;


           
            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */
    
                letter-spacing: -0.02em;
                text-transform: uppercase;
    
                /* Neutral/50% OP */
    
                color: rgba(15, 15, 18, 0.5);
            }
        }
        .circle-arrow{
            background-color: #E60043;
            display: grid;
            place-content: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
        }
    }

    
    .expositores-grid{
        display: grid;
        grid-template-columns: 1fr ;
        gap: 1rem;
        align-items: flex-start;
        margin-bottom: 4rem;
        @include d(md){
            grid-template-columns: 1fr;
        }

        .expositor-item{
            padding: 1.5rem;
            
            /* Primary/Fucsia

            A3238E
            */
            border: 1px solid rgba(255, 255, 255, 0.60);
            border-radius: 6px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 40px;
            gap: 4px;
            align-items: center;
            cursor: pointer;

            .seta svg{
                width: 2.5rem ;
                height: 2.5rem;
                @include d(md2){
                    
                    
                        display: none;
                    
                }
            }

            @include d(md2){
                grid-template-columns: 1fr;
                .seta {
                    display: none;
                }
            }

            .nome{
                color:  #FFF;
                font-family: Antonio;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
                text-transform: uppercase;
            }

            .estande{
                color:  #FFF;
                font-family: Antonio;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
                text-transform: uppercase;
                display: flex;
                align-items: center;
                gap: 8px;
                .bolinha{
                    width: 20px;
                    height: 20px;
                    border: 1px solid #000;
                    display: block;
                    border-radius: 50px;

                    &.Laranja{
                        background-color: #FA4616;
                    }
                    &.Verde{
                        background-color: #00D980;
                    }
                    &.Vermelho{
                        background-color: #E60043;
                    }
                    &.Azul{
                        background-color: #56c5d0;
                    }

                }
                
            }

            
        }
    }
}


.pagination{


    .page-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;

        a{
            padding: .375rem .875rem;
            display: block;
        }
    }

    li{
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 3px;
        /* identical to box height, or 150% */

   
        letter-spacing: -0.02em;
        text-transform: uppercase;

        color: #000000;
        letter-spacing: -.02em;
        text-transform: uppercase;
        color: #000;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #1e1a34;
        border-radius: 32px;
        align-items: center;
        padding: 6px;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
        transition: background-color .2s ease-in-out, transform .2s ease-in-out, box-shadow .2s ease-in-out;
        display: block;
        box-shadow: -3px 3px #f2a900;

        &.current{
            background-color: var(--bienal-vermelha);
            a{
                color: #fff;

            }
        }
        &:hover{
            transform: perspective(1px) translate3d(0, 2.5px, 0);
            box-shadow: 0 0 #f2a900;
        
        }
    }
    

    .on-edges{
        
        text-align: center;
        letter-spacing: -.02em;
        text-transform: uppercase;
        background-color: var(--bienal-vermelha);
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
        &.left{
                svg{
                    transform: rotate(180deg);
                    margin-right: 8px;
                    align-self: flex-start;
                }
        }
        &.right{
            svg{
                margin-left: 8px;
                align-self: flex-start;
            }

        }
       
        a{
            display: flex;
            align-items: center;
            color: #fff !important;
        }
        svg{
            width: 20px;
            height: 20px;

        }
    }
}


.active-step-1{

    .step-1-item{
        display: block;
    }
    .step-2-item{
        display: none;
    }

}
.active-step-2{
    .step-1-item{
        display: none;
    }
    .step-2-item{
        display: block;
    }
    

}

// MODAL 

.modal-infos{

    .header-expositor{
        position: relative;
        background-color: #B3B0C4;
        // padding-left: 3rem;
        @include d(md2){
            padding-left: 0;
        }
        // &::after{
        //     content: '';
        //     height: 4px;
        //     width: calc(100% + 50px);
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     background-color: #D6DF21;
        //     @include d(md2){
        //         width: 100%;
        //         display: none;
        //     }
        // }
        
    }
    .info-label{
        color: var(--black-70, rgba(0, 0, 0, 0.70));
        font-family: Antonio;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
        text-transform: uppercase;
    }
    .info-nome{
        color:  #FFF;
        font-size: 24px;
    
       
        color:  #1E1A34;
        font-family: Antonio;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 41.6px */
        text-transform: uppercase;
    }

    .header-1-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 3rem;
    }
 

    .header-2-wrapper{
        border-top: 1px solid var(--black-70, rgba(0, 0, 0, 0.70));

        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 1.5rem;
        padding: 8px 3rem 16px 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap: 1rem;
        .grid-wrapper{
            display: flex;
            gap: 20px;
        }
       
        .nome-wrapper{
            
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .info-nome{
            font-size: 1.125rem;
        }
        // .nome-wrapper:first-child{
        //     // border-right: 1px solid rgba(255, 255, 255, 0.20);
        // }

    }

    .button-wrapper{
        transition: 0.2s ease-in;
        @include d(md2){
            display: none;
        }
        
        .button{
            // background-color: $bienal-amarelo;
    
            // display: flex;
            // align-items: center;
             padding: 12px 20px;
             border: 1px solid #1E1A34;
            // font-weight: 700;
            // font-size: 14px;
            // line-height: 1.5;
            // border-radius: 3px;
            // /* identical to box height, or 157% */
            // box-shadow:  2.5px 2.5px #EF7D00;
            // letter-spacing: -0.02em;
            // text-transform: uppercase;
    
            /* Black */
    
            color: #000000;
            @include d(md2){
                width: 100%;
                justify-content: center;
            }
            &:hover{
                // transform: scale(1.02);
                transform: perspective(1px) translate3d(0, 2.5px, 0);
                box-shadow: 0 0 #f2a900;
               
            }
        }
        svg{
            width: 24px;
            height: 24px;
            
        }
    }

    .button-wrapper.only-mobile-btn{
        display: none;
        @include d(md2){
            display: block;
        }
    }

    .informacoes-wrapper{
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        
        padding-top: 3rem;
        padding: 3rem 1.25rem 1.25rem 1.25rem;

        .grid-title{
            display: flex;
            border-bottom: 1px solid #fff3;
            
            margin-bottom: 20px;

            .section-title{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-bottom: unset;
                 margin-bottom: unset;

                .badge-wrapper.bg-azul{
                    position: relative;
                    color: #FFF;
                    font-family: Antonio;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%; /* 26px */
                    text-transform: uppercase;
                    background-color: unset;
                    transform: unset;
                    margin-bottom: unset;
                    padding-bottom: 1rem;
                    cursor: pointer;
                }

                .badge-wrapper svg{
                    width: 37.273px;
                    height: 10px;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: none;
                }

                .badge-wrapper.active svg{
                    width: 37.273px;
                    height: 10px;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: block;
                }
            }
        }
        
        @include d(md2){
            padding-left: 0;
            grid-template-columns: 1fr;
            gap: 1.5rem;
        }
    }

    .section-title{
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        margin-bottom: 1rem;
    }

    .selos{
        // padding-left: 3rem;
        


        li{
            font-size: 1rem;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.32px;
            text-transform: uppercase;
            border: 1px solid rgba(255, 255, 255, 0.20);
            margin-bottom: 8px;
            padding: 11px 16px ;
        }
        // li:last-child{
        //     border: none;
        // }
    }

    .livros{
        display: none;
    }
    .livros.active{
        display: block;
    }
    .selos{
        display: none;
    }
    .selos.active{
        display: block;
    }

    .livros-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 12px;

       
        .nome-livro{
            font-size: 14px;

            font-weight: 500;
            line-height: 1.3;
            letter-spacing: -0.28px; 
            text-transform: uppercase;

            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color:  #FFF;
            font-family: Antonio;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 16px */
            letter-spacing: 0.96px;
            text-transform: uppercase;
            margin-top: 8px;
        }

        .img-wrapper{
            position: relative;
            padding-top: 112.94%;
            // border: 4px solid #D6DF21;
            &::after{
                content: '';
                position: absolute;
                inset: 0;
                border: 4px solid #D6DF21;
                
            }
            img{
                padding: 3px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                inset: 0;
            }
        }
    }



    // STEP 2 

    .header-expositor.step-2{
        padding-top: 1.5rem;
        // padding-bottom: 2rem;

        &::after{

            display: none;
        }
        .voltar-btn{
            margin-bottom: 2rem;
            color: #FFF;
            /* Headline / H4 - 12 Bold */
            font-size: 12px;    
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.24px;
            text-transform: uppercase;

            position: absolute;
            right: 0;
        }
        .info-nome{
            margin-bottom: 4px;
        }
        .confirmada-title{
            color:  #D6DF21;
            /* Headline / H2 - 16 Med */
            font-size: 1rem;

            font-weight: 500;
            line-height: 1.4;
            letter-spacing: -0.32px;
            text-transform: uppercase;
        }
    }

    .lista-programacao{
        
        padding: 3rem 0 0 3rem;
        padding-bottom: 0;
        @include d(md2){
            padding-left: 0;
        }

     

        ul{
            li{
                // .section-title{
                //     // border: none;
                //     // display: grid;
                //     // grid-template-columns: 0.7fr 1.3fr;
                //     // align-items: center;
                //     // gap: 4rem;
                //     // margin-bottom: 1.5rem;
                //     // @include d(md){
                //     //     gap: 2rem;
                //     // } 
                //     // @include d(md2){
                //     //     grid-template-columns: 1fr;
                //     //     gap: 1rem;
                //     // }

                //     // .info-nome{
                //     //     font-size: 1.125rem;
                //     // }
                // }

                .informacoes{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 8px;
                    padding-bottom: 1.5rem;
                    @include d(md2){
                        grid-template-columns: 1fr;
                    }
                    .group{
                        border-radius: 1px;
                        padding: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.10);

                        .label{
                            color: rgba(255, 255, 255, 0.50);
                        
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.28px;
                            text-transform: uppercase;
                        }
                        .info-nome{
                            font-size: 1.125rem;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}





