#categorias{
    background: #FFFFFF;
    /* Neutral/10% OP
    
    0F0F12
    */
    border-bottom: 1px solid rgba(15, 15, 18, 0.1);
    .container{
        overflow: hidden;
    }
 
    .tabs-wrapper{
        display: flex;
        justify-content: space-between;
       
    }
    .tab{
        flex-basis: 33%;
      
        span{
            transition: 0.2s ease;
            padding: .4375rem 1.25rem;
            border-radius: 20px;
        }
        &.active{
           
            span{
                background: rgba(214, 223, 33, 0.2);
                
                color: #000000;
               
            }
           
        }

        &:hover{
            span{
                background: rgba(214, 223, 33, 0.2);
                border-radius: 20px;
                color: #000000;
                padding: .4375rem 1.25rem;
            }
           
        }
        a{
            padding: 1.1875rem 1rem;
            display: block;

            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.4;
            /* identical to box height, or 144% */
            
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            
            /* Neutral/40% OP */
            
            color: rgba(15, 15, 18, 0.4);
        }
    }

}

#destaque{
    padding-top: 6rem;



    .title{
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 2.5rem;
    }

    .bottom-info-blog{

        display: flex;
        align-items: center;
        justify-content: space-between;
    
        svg{
            width: 24px;
            height: 24px;
           
            filter: brightness(0) invert(1);
        }
    }

    .swiper-destaque{

        padding-top: 104px;
        margin-top: -104px;
        .gatinho{
            position: absolute;
            right: 140px;
            z-index: 2;
            top: 8px;
            @include d(md2){
                display: none;
            }
        }
        .livro-laranja{
            position: absolute;
            right: 5px;
            top: 48px;
            max-width: 122px;
            @include d(md2){
                display: none;
            }
        }
        .swiper-wrapper{
            position: relative;
            z-index: 2;
        }
        .swiper-slide{
            height: auto;
            // max-height: 447px;
        }

        .blog-card {
            border-radius: 3px;
            height:100%;
            overflow: hidden;
            display: block;
            display: flex;
            flex-direction: column;
        }
        .content-blog{
            background: #9B2743;
            padding: 2rem;
            color: #fff;
    
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            @include d(dt){
                padding: 1rem;
                flex: 1;
            }

            .title-wrapper{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
            }
    
            h2{
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 1.1;
             
                letter-spacing: -0.02em;
                text-transform: uppercase;     
                margin-bottom: 1rem;


                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                @include d(lg){
                    font-size: 1.5rem;
                }
                @include d(dt){
                    font-size: 1.2rem;
                }
            }
          
    
            .info-blog{
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5;
                /* identical to box height, or 150% */
    
                letter-spacing: -0.02em;
                text-transform: uppercase;
    
                color: rgba(255, 255, 255, 0.8);
                display: flex;
                flex-wrap: wrap;
                column-gap: 5px;
            }
        }

        .img-wrapper{
            position: relative;
            padding-top: 52.15%;
            overflow: hidden;
           flex: 1;
            img{
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;

            }
        }


        .swiper-slide:nth-child(1){
            .blog-card{
                display: grid;
                grid-template-columns: 1fr 1fr;
                @include d(md){
                    // grid-template-columns: 1fr;
                    display: flex;
                }

                .content-blog{
                    background-color: #9B2743;
                }

               
            }
        }
        .swiper-slide:nth-child(2){
            .content-blog{
                background-color: #FF6A39;
            }
        }
        // .swiper-slide:nth-child(2){

        //     .blog-card {
        //         display: grid;
        //         // grid-template-rows: 60% 40%  
        //     }
        //     img{
        //         // max-height: 260px;
        //         aspect-ratio: 23 / 9;

        //         @include d(md){
        //             // max-height: 100%;
        //             aspect-ratio: 4/3;
        //         }
        //     }
        //     .content-blog{
        //         background-color: #369AA3;
        //     }
        // }
    }
    
    // .blog-card {
    //     border-radius: 3px;
    //     height:100%;
    //     overflow: hidden;
    //     display: grid;
    //     // max-height: 28rem;

    //     img{
    //         @include d(md){
    //             // max-height: 100%;
    //             aspect-ratio: 4/3;
    //         }
            
    //     }

    //     @include d(dt){
    //         // max-height: 22.25rem;
    //     }
    
    // }

   
    // .blog-card.blog-destaque{
    //     // display: flex;
    
    
     
    
    //     .img-wrapper{

    //         position: relative;
    //         max-height: 449px;
    //         img{
    //             width: 100%;
    //             height: 100%;
    //             object-fit: cover;               
    //         }
    //     }
    
    
        
        
       
    // }

   
}


#todos-posts{

    padding-top: 4.75rem;

    margin-bottom: 7.5rem;

    .header-wrapper{

        // background: url('../../src/assets/imagens/footer/footer-linear.webp');
        // background-repeat: no-repeat;
        // background-size: cover;
        background-color: $bienal-vermelha;
        position: relative; 
        margin-bottom: 4rem;

        padding: 1.5rem 2rem;
        h2{
            color: #fff;
            font-weight: 800;
            font-size: 1.5rem;
            line-height: 1.33;            
            letter-spacing: -0.02em;
            text-transform: uppercase;            
            color: #FFFFFF;
        }
        .ornamente-peixe{
            position: absolute;
            right: 10px;
            top: calc(50% - 20px);
            transform: translateY(-50%); 

            @include d(md2){
                max-width: 120px; 
                top: calc(50% - 10px); 
            }
        }
    }


    .blog-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        margin-bottom: 4rem;

        @include d(md){
            grid-template-columns: 1fr 1fr;
        }
        @include d(md2){
            grid-template-columns: 1fr;
        }
    }

    .card-blog{

        border-radius: 3px;
        overflow: hidden;
        max-width: 600px;
        .cat{
            background-color: $bienal-vermelha;
            color: #fff;
          
            display: inline-block;
            line-height: 1;
            text-transform: uppercase;

            font-weight: 700;
            font-size: .875rem;
            line-height: 22px;
            /* identical to box height, or 157% */

            text-align: center;
            // letter-spacing: -0.02em; 
            text-transform: uppercase;


            border-radius: 16px;
          
            margin-bottom: .75rem;

            padding: 3px 1rem 0;
           
            &.rosa{
                background-color: $bienal-rosa;
            }
            &.roxo{
                background-color: #FF6A39;
            }
        } 

        .img-wrapper{
                position: relative;
                padding-top: 52.15%;
                overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                inset: 0;
                // aspect-ratio: 4/3;
            }
        }
            

        .content-blog{
            background-color: #F3F3F5;
            padding: 2rem;
            @include d(lg){
                padding: 1.5rem;
            }

            h2{
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.3;
                /* or 133% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Neutral/100 */

                color: #0F0F12;

                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                margin-bottom: 1.5rem; 

               
            }
        }
        .bottom-info-blog{ 
 
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #59595C;
        
            span{
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5;
                /* identical to box height, or 150% */

                letter-spacing: -0.02em;
                text-transform: uppercase; 
            }
            svg{
                width: 24px;
                height: 24px;
               
                // filter: brightness(0) invert(1);
            }
        }
    
    }


    .pagination{


        .page-list{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .75rem;

            a{
                padding: .375rem .875rem;
                border: 1px solid rgba(15, 15, 18, 0.1);
                border-radius: 3px;
                display: block;
            }
        }

        li{
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 3px;
            /* identical to box height, or 150% */

       
            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #000000;

            &.current{
                background-color: #000;
                a{
                    color: #fff;

                }
            }
        }

        .on-edges{
            
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5;
            /* identical to box height, or 150% */

            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;

      
            background-color: $bienal-amarelo;
            /* Black */

            color: #000000;
            &.left{
                    svg{
                        transform: rotate(180deg);
                        margin-right: 8px;
                        align-self: flex-start;
                    }
            }
            &.right{
                svg{
                    margin-left: 8px;
                    align-self: flex-start;
                }

            }
           
            a{
                display: flex;
                align-items: center;
            }
            svg{
                width: 20px;
                height: 20px;

            }
        }
    }
} 