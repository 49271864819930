

#banner-credenciamento{
    background: #99D6EA;
    color: #fff;

    padding: 6rem 0 0;

    .linha-amarela{
        height: 120px;
        background-color: #5CBDE5;
        @include d(md2){
            height: 60px;
        }
    }
    
    .container{
        position: relative;
    }
    .grid-wrappper{
     
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        margin-bottom: 7rem;

        @include d(md2){
            grid-template-columns: 1fr;
        }
    }
    .section-title{
        h2{
            color:  #1E1A34;
            font-family: Antonio;
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 72.8px */
            text-transform: uppercase;
        }
    }
    // .button-wrapper{
    
    //     // box-shadow: 6px 6px #1d336f !important;
    //     a{
    //         @include BotaoMain(#D6DF21, #EF7D00);
    
    //         box-shadow: 2.5px 2.5px 0px #EF7D00;
    
    //         // @include BotaoTeste(red, blue);
    //         // 
            
    //     }
    // }

    .col-2{
            max-width: 762px;
        .text-description{
            color:  rgba(0, 0, 0, 0.87);
            text-align: center;
            font-family: "Noto Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 32px */
            margin-bottom: 2rem;
        }
    }


    .orna-alien{
        position: absolute;
        top: -177px;   
        right: -30px;
        transform: scaleX(-1);
        max-width: 233px;

        @include d(lg){
            max-width: 190px;
        }
        @include d(md){
            top: -150px;
        }
  
    }

    .main-img-wrapper{
        position: relative;
        max-width: max-content;
        // margin-left: 40px;
        position: absolute;    
        left: 0;
        right: 0;
        top: 6rem;
        
        margin:  0 auto;

        // @include d(md){
        //     // width: 120%;
        //     // margin-left: -13%;]
        //     display: none;
            
        // }

      
        .orna-luneta{
            position: absolute;       
            top: -77px;
            left: 7px;
            @include d(lg){
                // transform: scale(0.5); 
                // max-width: 100px;
                // top: -50px;
            }
            @include d(md){
               
            }
            @include d(md2){
                top: -2px;
            }
        }
        .main-img{
            // width: 100%;
            margin: 0 auto;
            
        }
    }
}

.grid-cards-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: flex-end;
    position: relative;
    z-index: 3;
    @include d(md){
        grid-template-columns: 1fr;
    }

    .card-item{
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        background-color: #E60043;
        padding: 1.5rem;
        border: 1px solid #FFF;
        height: 480px;
        @include d(md2){
            height: 100%;

        }
        
        // max-width: 525px;
        width: 100%;
        overflow: auto;
       
        .title{

            margin-bottom: 1rem;
            color:  #FFF;
            font-family: Antonio;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 41.6px */
            text-transform: uppercase;
            @include d(lg){
                font-size: 1.5rem;
            }
        }
        .descricao{
            margin-bottom: 1rem;
            p{
                color:  rgba(255, 255, 255, 0.70);

                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 32px */
                @include d(lg){
                    font-size: 1rem;
                }
            }
        }

        .descricao.profissionais{
            // p{ 
            //     font-size: 1rem;
            //     margin-bottom: 1rem;
            // }
            // strong{
            // font-size: 1.2rem;
            // }
            // ul{
            //     margin-bottom: 1rem;
            // }
           
        } 
        .button-wrapper{
            transition: 0.2s ease-in;
            

            a {
                max-width:none !important;
                // box-shadow: none !important;
                &:hover{
                    transform: perspective(1px) translate3d(0, 2.5px, 0);
                    box-shadow:  0px 0px #F2A900;
                   
                }
            }

            .button{
                
                width: 100%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 24px;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;


                /* Black */

                color: #000000;
                border-radius: 24px;
                border: 1px solid  #1E1A34;
                background:  #FFF;
                box-shadow: -3px 3px 0px 0px #F2A900;
                @include d(md2){
                    width: 100%;
                    justify-content: center;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
        }
    }
    .card-item:nth-child(1){
        background-color: #FA4616;
        // max-width: 525px;
        width: 100%;
        height: 424px;
        
        // margin: 0 auto;
        margin-left: 7px;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
            height: 100%;
        }
    }
    .card-item:nth-child(2){
        
        margin: 0 auto;
        height: auto;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
            height: 100%;
        }
    }
    .card-item:nth-child(3){
        background: #4A446A;
        // max-width: 515px;
        width: 100%;
        height: 448px;
        
        // margin: 0 auto;
        margin-left: auto;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
            height: 100%;
        }
    }
}



#modal-atracoes.modal-atracoes.credenciamento{

    .gato-modal{
        display: none;
    }
    .modal-guts{
        padding-left: 2rem;
    }
    .descricao{
        padding-top: 2rem;
        .title-destaque{
            font-size: 1.3rem;
        }
        p{ 
            font-size: 1rem;
            line-height: 1.2;
            margin-bottom: 1rem;
        }
        strong{
        font-size: 1.2rem;
        }
        ul{
            margin-bottom: 1rem;

            li{
                margin-bottom: 1rem;

            }
        }
        


        .button-wrapper{
            // transition: 0.2s ease-in;
            
                // transform: scale(1.02);
                // transform-origin: left;
                a {
                    max-width:none !important;
                    // box-shadow: none !important;
                    &:hover{
                        transform: perspective(1px) translate3d(0, 2.5px, 0);
                        box-shadow:  0px 0px #F2A900;
                       
                    }
                }
            

            // a {
            //     max-width:none !important;
            //     box-shadow: none !important;
            // }

            .button{
                background-color: #fff;
                width: max-content;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 16px;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;


                /* Black */

                color: #000000;
                @include d(md2){
                    width: 100%;
                    justify-content: center;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
        }
    }
    .modal-lista{
        li{
            margin-bottom: 1rem;

        }
    }
}